import { BorderAll } from "@mui/icons-material";



// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//Mui , hooks imports
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container,Box,TextareaAutosize
   } from "@mui/material";

//Icons  
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";

// CSS
import "./rendezvous.css";



//My Components 
// import MyTitle from "./components/MyTitle"
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, getSellPaymentImageURL, saveLogoutInLocalStorage,getCompressedImage } from "../../helpers/constants";
import axios from "axios";

//Modals 
// import ProductSearchModal from "./components/ProductSearchModal"
// import CustomerUpdateModal from "./components/CustomerUpdateModal"
// import InvoiceModal from "../invoice/InvoiceModal";


//Others 
import { useNavigate } from "react-router-dom";
// import TableSelection from "./components/TableSelection";

import UpdateModal from "./UpdateModal";

const RendezVousPage= () =>{

    //Update Modal

    const [updateIsOpen,setUpdateIsOpen]=useState(false)
    const [selectedUpdateRendezVous,setSelectedUpdateRendezVous]=useState({});
    const closeUpdateModal=()=>{
        setUpdateIsOpen(old => false);
     
   }
   const openUpdateModal=(rendezVous) =>{
    setSelectedUpdateRendezVous(rendezVous);
    setUpdateIsOpen(true);
   }

   const handleUpdate= async({idRendezVous,commentaire,localDateTime}) => {

    if(localDateTime>(new Date()))
    {
        const combinedDateTime = new Date(localDateTime);
    const formattedDateTime = combinedDateTime.toISOString();

    const endpoint=getEndPoint("/RendezVous/reschedule");
    const response = await axios.put(endpoint+"?idRendezVous="+idRendezVous+"&commentaire="+commentaire+"&dateTime="+formattedDateTime,{},headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        freeTokenFromSessionStorage();
       
      }
   
 
    });
    if(response!=null && response.data!=null)
    {
        closeUpdateModal();
        loadRendezVous();
    }
    }



   }


    //today / tomorrow and rest 

    const [todayList,setTodayList]=useState([]);
    const [tomorrowList,setTomorrowList]=useState([]);
    const [restList,setRestList]=useState([]);



    const isSameDay = (date1, date2) => {
        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
      };

    //Creation 
    const [erreur,setErreur]=useState(false);
    const [idCustomer,setIdCustomer]=useState(null);
    const [dateTimeRendezVous,setDateTimeRendezVous]=useState(null);

    const [commentaire,setCommentaire]=useState("");




    //
    const [customers,setCustomers]=useState([]);
    const [rendezVousList,setRendezVousList]=useState([]);



    const loadRendezVous=async() => {
        const endpoint=getEndPoint("/RendezVous");
        const response = await axios.get(endpoint,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              freeTokenFromSessionStorage();
             
            }
         
       
          });
          if(response!=null && response.data!=null)
          {
            var rendezVousList=response.data;
            const today = new Date();
            const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
            const todayItems = rendezVousList.filter(item => isSameDay(new Date(item.localDateTime), today));
            const tomorrowItems = rendezVousList.filter(item => isSameDay(new Date(item.localDateTime), tomorrow));
            const restItems = rendezVousList.filter(item => !isSameDay(new Date(item.localDateTime), today) && !isSameDay(new Date(item.localDateTime), tomorrow));
        
            setTodayList(old => todayItems);
            setTomorrowList(old => tomorrowItems);
            setRestList(old => restItems);
 
    
          }

        
    }

    const validerRendezVous=async(idRendezVous) => {

        const endpoint=getEndPoint("/RendezVous/validate");
        const response = await axios.put(endpoint+"?idRendezVous="+idRendezVous,{},headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            freeTokenFromSessionStorage();
           
          }
       
     
        });
        if(response!=null && response.data!=null)
        {
            loadRendezVous();
        }

    }

    const createRendezVous=async() =>{

        if(idCustomer!=null && dateTimeRendezVous!=null && commentaire!="" && dateTimeRendezVous>=(new Date()))
        {
            const combinedDateTime = new Date(dateTimeRendezVous);
            const formattedDateTime = combinedDateTime.toISOString();
            const data={
                idClient:idCustomer,
                localDateTime:formattedDateTime,
                commentaire:commentaire
    
            }
            const endpoint=getEndPoint("/RendezVous");
            const response = await axios.post(endpoint,data,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                freeTokenFromSessionStorage();
               
              }
           
         
            });
           // Load Suppliers for table await loadUsers();
           if(response!=null)
           {

            clearInputs();
            loadRendezVous();
            // loadRendezVous();
           }
        }
        else
        {
            setErreur(old =>true);
        }

        
      

    }

    const getHourMinsOfDate=(date)=>
    {
        const dateObject = new Date(date);
        const formattedTimeString = dateObject.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });


        return formattedTimeString;
    }
    const getDDMMYYOfDate=(date) =>{
        const dateObject = new Date(date);

        const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
        const formattedDateString = dateObject.toLocaleDateString('en-US', options);

        return formattedDateString;
    

    }

    const clearInputs=() =>{
        setIdCustomer(old => null);
        setDateTimeRendezVous(old => null);
        setErreur(old => false);
  
        setCommentaire(old => "");
    };

    const loadCustomers=async()=>{
        const endpoint=getEndPoint("/customers");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
      
        });
        console.log("Customers :");
        console.log(data?.data);
        setCustomers(old => data.data);
      
      };

      // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
      
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };

        //Loads
        useEffect(()=>{
            loadRendezVous();
            loadCustomers();
          },[]);

          const modifierStyle = {
            backgroundColor: '#FFBF00', // Replace 'blue' with the desired color
            color: 'white', // Optionally, set the text color
          };
          const validerStyle = {
            backgroundColor: '#4CAF50', // Replace 'blue' with the desired color
            color: 'white', // Optionally, set the text color
          };

    return <div>
           <UpdateModal  open={updateIsOpen} rendezVous={selectedUpdateRendezVous} handleClose={closeUpdateModal} handleUpdate={handleUpdate}  />
    
 
 <Box style={{border:"2px solid #B6BBC4",padding:"10px"}}>
        <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             Nouveau Rendez-vous
            </Typography>
                <Grid container>
                <Grid item md={6} xs={12}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID Client
            </Typography>
            <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Client</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="ID Client"
                  value={idCustomer}
                  className="selectStyle"
                 onChange={(event) => setIdCustomer(event.target.value)}
                >
            
                  {customers.map(customer => (
                   <MenuItem key={customer.id} value={customer.id}>{customer.id}</MenuItem>
                 ))} 
                </Select>
              </FormControl>
              </DemoContainer>
                    </Grid>
                    <Grid item md={6} xs={12}>
                    <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             Date/Heure
            </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                           <DateTimePicker format="DD-MM-YY hh-mm a" value={dateTimeRendezVous} onChange={(value) => setDateTimeRendezVous(old => value)} />
                        </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
             
                    <Grid item xs={12} md={12}>
                    <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             Commentaire
            </Typography>
                    <TextareaAutosize value={commentaire} onChange={(input) =>{setCommentaire(old => input.target.value)}} style={{width:"100%", height:"120px"}} />
                    </Grid>
                    
                </Grid>
                <br/>
      
                <Button color={"primary"}  variant="contained" onClick={() => {createRendezVous()}} >
              Valider
            </Button>  
            {
                erreur?
                <p style={{color:"red"}}>Erreur !</p>:<></>
            }
       
        </Box>
        <br/>
        {
            (todayList.length===0) ?
            <Box style={{border:"2px solid green",padding:"10px"}}>
        <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
        Les rendez-vous d'aujourd'hui
            </Typography>
         
            </Box>
            :
            <Box style={{border:"2px solid red",padding:"10px"}}>
        <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
        Les rendez-vous d'aujourd'hui
            </Typography>
            <br/>
            <TableContainer   style={{border:"1px solid #B6BBC4"}}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
    <TableCell className="filtertitle" align="left">Time</TableCell>
      <TableCell className="filtertitle" align="left">Nom Prenom</TableCell>
      <TableCell className="filtertitle" align="left">Numero Telephone</TableCell>
      <TableCell className="filtertitle" align="left">Commentaire</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
      <TableCell className="filtertitle" align="left">Valider</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
    {todayList.map(
        element =>  (
            <TableRow
            // key={order.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             <TableCell component="th" scope="row" align="left">{getHourMinsOfDate(element.localDateTime)}</TableCell>
            <TableCell component="th" scope="row" align="left">{element.name}</TableCell>
            <TableCell align="left">{element.phoneNumber}</TableCell>
            <TableCell  align="left">{element.commentaire}</TableCell>
            <TableCell  align="left"><Button style={modifierStyle} onClick={() =>{openUpdateModal(element)}}>modifier</Button></TableCell>
            <TableCell  align="left"><Button style={validerStyle} onClick={() => {validerRendezVous(element.id)}}>valider</Button></TableCell>
          
            </TableRow>
            )
    )}
    </TableBody>
    </Table>
    </TableContainer>
            </Box>
        }
            <br/>
            {
                (tomorrowList.length===0)?
                <Box style={{border:"2px solid green",padding:"10px"}}>
        <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
        Les rendez-vous du lendemain
            </Typography>
        
            </Box>
            :
            <Box style={{border:"2px solid #ffbf00",padding:"10px"}}>
        <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
        Les rendez-vous du lendemain
            </Typography>
            <br/>
            <TableContainer   style={{border:"1px solid #B6BBC4"}}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
    <TableCell className="filtertitle" align="left">Time</TableCell>
      <TableCell className="filtertitle" align="left">Nom Prenom</TableCell>
      <TableCell className="filtertitle" align="left">Numero Telephone</TableCell>
      <TableCell className="filtertitle" align="left">Commentaire</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
 
    </TableRow>
  </TableHead>

  <TableBody>
    {tomorrowList.map(
        element =>  (
            <TableRow
            // key={order.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                   <TableCell component="th" scope="row" align="left">{getHourMinsOfDate(element.localDateTime)}</TableCell>
      
            <TableCell component="th" scope="row" align="left">{element.name}</TableCell>
            <TableCell align="left">{element.phoneNumber}</TableCell>
            <TableCell  align="left">{element.commentaire}</TableCell>
            <TableCell  align="left"><Button style={modifierStyle} onClick={() =>{openUpdateModal(element)}} >modifier</Button></TableCell>
    
          
            </TableRow>
            )
    )}
    </TableBody>
    </Table>
    </TableContainer>
            </Box>
            }
                 <br/>
                 <Box style={{border:"2px solid #B6BBC4",padding:"10px"}}>
        <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
        Rest Des Rendez-Vous
            </Typography>
            <br/>
            <TableContainer   style={{border:"1px solid #B6BBC4"}}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
    <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Nom Prenom</TableCell>
      <TableCell className="filtertitle" align="left">Numero Telephone</TableCell>
      <TableCell className="filtertitle" align="left">Commentaire</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
  
    </TableRow>
  </TableHead>

  <TableBody>
    {restList.map(
        element =>  (
            <TableRow
            // key={order.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                    <TableCell component="th" scope="row" align="left">{getDDMMYYOfDate(element.localDateTime)+" "+getHourMinsOfDate(element.localDateTime)}</TableCell>
          <TableCell component="th" scope="row" align="left">{element.name}</TableCell>
            <TableCell align="left">{element.phoneNumber}</TableCell>
            <TableCell  align="left">{element.commentaire}</TableCell>
            <TableCell  align="left"><Button style={modifierStyle} onClick={() =>{openUpdateModal(element)}} >modifier</Button></TableCell>
    
        
      
          
            </TableRow>
            )
    )}
    </TableBody>
    </Table>
    </TableContainer>
            </Box>


    </div>;
}
export default RendezVousPage;