import {Dialog,DialogTitle,DialogContent,Slide,
    } from "@mui/material";

  import React, { useState,useEffect } from "react";
  import "./index.css";

  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  const InvoiceImageModal=({img,open,handleClose}) => {
  

      return (
          <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogTitle className="cornerText"> Image Facture </DialogTitle>
          <DialogContent id="dialogContent" >
          <img src={img} />
          </DialogContent>
        </Dialog>
      )
  };

  export default InvoiceImageModal;
  