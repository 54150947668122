import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./ChartSelect.css";
const ChartSelectTime=({changeWeek}) =>{

    const [week,setWeek]=useState(0);
      return (
        <FormControl  style={{padding:5}}  >
         
            <Select className="chartSelectPart"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
               value={week}
              onChange={(event) =>{
                changeWeek(event.target.value);
                setWeek(event.target.value);
              }}
            >
              <MenuItem value={0}>Semaine en cours</MenuItem>
              <MenuItem value={1}>Semaine Précédente</MenuItem>
              <MenuItem value={2}>Semaine 3</MenuItem>
              <MenuItem value={3}>Semaine 4</MenuItem>
        
            </Select>
          </FormControl>);

}
export default ChartSelectTime;