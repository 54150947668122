import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
    Card,CardContent, Grid ,Typography,TableContainer,Table
    ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
    Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";


    import MyInput from "../../../../globalComponents/MyInput";
  import React, { useState,useEffect } from "react";
  import "./index.css";
  import { useDispatch, useSelector } from "react-redux";
  import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays} from "../../../../helpers/constants";
  import {logout} from "../../../../redux/auth/authactions";
  import { styled } from '@mui/system';
  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  const UpdateModal=({open,handleClose,supplier,handleUpdate}) => {
  
    const [id,setId] = useState("");
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [address,setAddress] = useState("");
   


   
   useEffect(
     () => {
      setId(supplier?.id)
      setName(supplier?.name);
      setPhone(supplier?.phone);
      setAddress(supplier?.address);
     },
     [supplier]
   );

   
    
      const updatedData=() => {
  
        return {
      
           id:id,
           name:name,
           phone:phone,
           address:address,  
        }
      }

      return (
          <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogTitle className="cornerText"> Modifier Fournisseur : {supplier?.id} </DialogTitle>
          <DialogContent id="dialogContent" >
            <Grid style={{padding:10}} container spacing={1}>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                NomPrenom :
              </Typography>
              <MyInput value={name} onChange={(event) => setName(event.target.value)} 
              placeholder="NomPrenom" 
              components={{ Input: StyledInputElement }}/>
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Numéro de téléphone :
              </Typography>
              <MyInput value={phone} onChange={(event) =>{setPhone(event.target.value)}} 
              placeholder="Numéro de téléphone" 
              components={{ Input: StyledInputElement }}/>
                </Grid>
    
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Adresse :
              </Typography>
              <MyInput value={address} onChange={(event) =>{setAddress(event.target.value)}} 
              placeholder="Adresse" 
              components={{ Input: StyledInputElement }}/>
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
            <Button id="modifierButton" onClick={
             () => {
              handleUpdate({
                idSupplier:supplier.id,
                data:updatedData(),
              });
             }
              }>Modifier</Button>
          </DialogActions>
        </Dialog>
      )
  };
  const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    600: '#0072E5',
  };
  const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
  };
  const StyledInputElement = styled('input')(
    ({ theme }) => `
    width: 320px;
    height:38px;
    font-size: 1rem;
   
    font-weight:  500;
    line-height: 1.5;
    color: #3b3b3b;
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
    border-radius: 5px;
    padding: 8px 8px;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
    }
  `,
  );
  export default UpdateModal;
  