import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel } from "@mui/material";

import React, { useState,useEffect } from "react";
import SmallInput from "../../../../globalComponents/SmallInput";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

//My Costum inputs
import MyInput from "../../../../globalComponents/MyInput";

//Modals
import ProductSearchModal from "../ProductSearchModal";

//Icons 
import SearchIcon from '@mui/icons-material/Search';

import "./notesModal.css";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const NotesModal=({open,handleClose,customerOrder,handleUpdate}) => {




  // rest
  const [order,setOrder]=useState({});
  const [product,setProduct]=useState(null);
  const [quantity,setQuantity]=useState(null);
  const [total,setTotal]=useState(null);
  const [cost,setCost]=useState(null);
    //clear Inputs 
    const clearInputs=()=>{
      setTotal(old => null);
        setCost(old => null);
        setProduct(old => null);
        setQuantity(old => null);
  
    }

  const calculate=({product,quantity})=>{
    if(product!=null && !isNaN(quantity))
    {
      setTotal(old => ((product.sell_price)*quantity));
      setCost(old => ((product.buy_price)*quantity));
      console.log("total :"+total+", cost:"+cost);
    }
    else
    {
      setTotal(old => null);
      setCost(old => null);

    }
  }
 

    //product modal
    const [productModalIsOpen,setProductModalIsOpen]=useState(false);
    const closeProductModal=()=>{
      setProductModalIsOpen(old => false);
    }
    const openProductModal=() =>{
      setProductModalIsOpen(old => true);
    }
    const getProduct=({product})=>{
      setProduct(product);
      calculate({product:product,quantity:quantity});
    }

 useEffect(
   () => {
    setOrder(customerOrder);
   },
   [customerOrder]
 );

 
  
    const generateData=() => {
       return {
        productId:product.id,
        quantity:quantity,
       }
     }

    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Notes Commande </DialogTitle>
        <ProductSearchModal open={productModalIsOpen}   handleClose={closeProductModal} getProduct={getProduct}  />

        <DialogContent className="dialogContent" >
        <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID Produit :
              </Typography>
    <span style={{"display":"flex"}}>
<MyInput found={(product?.id!=null)? true:false}  
         value={product?.id} 
         placeholder="ID Produit" 
 
         />
               <span style={{"width":"2px"}}/>
              <Button variant="contained" onClick={() => openProductModal()} ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
              {total!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                  {isNaN(total)?
                   <Typography className="errorlittleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Quantité invalide !
                   </Typography>
                  :
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                      Cout : {cost} DH  , PV : {total} DH
                  </Typography>
                  }
                  
                  </Grid>
                  </Grid>
           
                  
               }
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Quantity : 
              </Typography>
              <MyInput value={quantity} 
               onChange={(event)=> {
                setQuantity(old => event.target.value)
                calculate({product:product,quantity:event.target.value});
               }}
              placeholder="Quantity" 
             />
            </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={() =>{
            handleClose();
            clearInputs();
          }}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {

            if(product!=null && !isNaN(total) && !isNaN(cost))
            {
              handleUpdate({
                idOrder:customerOrder.id,
                data:generateData(),
              });
              clearInputs();
            }
           }
            }>Modifier</Button>
        </DialogActions>
      </Dialog>
    )
};
export default NotesModal;
