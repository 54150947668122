import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container,TextField
   } from "@mui/material";
import Slider from '@mui/material/Slider';

//Css
import "./gestioncommande.css";




//Icons 
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import NotesIcon from '@mui/icons-material/Notes';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RemoveIcon from '@mui/icons-material/Remove';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
//Modals
import EditOrderModal from "./components/EditOrderModal"
import InvoiceModal from "../invoice/InvoiceModal";
import NotesModal from "./components/NotesModal";
import DeleteModal from "./components/DeleteModal";
import LockModal from "./components/LockModal";
import ValidationModal from "./components/ValidationModal"
import DeleteProductModal from "./components/DeleteProductModal";
//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, getSellPaymentImageURL, saveLogoutInLocalStorage ,getCompressedImage} from "../../helpers/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";




const View= () => {
  //Edit Total

  const [editTotalId,setEditTotalId]=useState(null);
  const [editTotalAmount,setEditTotalAmount]=useState(0);

  const isEditingTotal=(id) =>
  {
    if(editTotalId!=null && editTotalId==id)
    return true;
    else 
    return false;
  }
  const setEditTotal=(id,total)=>
  {
    setEditTotalId(old => id);
    setEditTotalAmount(old => total);
  }
  const freeEditTotal=async() =>
  {
    if(editTotalId!=null)
    {
      const endpoint=getEndPoint("/editOrderTotal?id="+editTotalId+"&montant="+editTotalAmount);
      const response = await axios.put(endpoint,{},headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
       freeTokenFromSessionStorage();
     }
    });
    loadOrders();
    }
    setEditTotalId(old => null);
 

  }


  //Benefice filter 
  const [gainQuotient,setGainQuotient]=useState(2);
  const showGainQuotient=()=>{
    if(gainQuotient==2)
    return "∞"
    else
    return (gainQuotient*100).toFixed(0)+" %";
  }

  //Agent Identification
  const decodedJWT=useSelector(state => state.decodedJWT );
  
  //Upload Order Image
  const uploadOrderImage= async (idOrder,image)=> {
    let formData=new FormData();
    var   compressedImage=await getCompressedImage(image);
    formData.append("orderImage",compressedImage);

    const endpoint=getEndPoint("/uploadOrderImage?orderId="+idOrder);
    const response = await axios.post(endpoint,formData,headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     freeTokenFromSessionStorage();
   }
  });

  if(response!=null && response.data!=null)
  {
    await loadOrders();
  }



  }

  //Show order Image

  const showOrderImage = (image) => {
    const endpoint=getEndPoint("/images/orderInvoice/"+image);
    window.open(endpoint, '_blank', 'noopener,noreferrer');
  };
  const showOrderDeliveryImage= (image) => {
    const endpoint=getEndPoint("/images/orderImage/"+image);
    window.open(endpoint, '_blank', 'noopener,noreferrer');
  };

  //Validate Stock Modal
  const [validationModalIsOpen,setValidationModalIsOpen]=useState(false)
  const [selectedValidationOrder,setSelectedValidationOrder]=useState({});
  const closeValidationModal=()=>{
    setValidationModalIsOpen(old => false);
   
 }
 const openValidationModal=(order) =>{
  setSelectedValidationOrder(order);
  setValidationModalIsOpen(true);
 }
 const [validationOrderClicked,setValidationOrderClicked]=useState(false);
 const handleOrderValidation= async ({idOrder,stateBool})=>{
   if(!validationOrderClicked)
   {
    setValidationOrderClicked(old => true);

   const endpoint=getEndPoint("/ValidateStockOfSellOrder?id="+idOrder+"&state="+stateBool);
   const response = await axios.post(endpoint,{},headers).catch(function (error) {
   if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
    freeTokenFromSessionStorage();
  }
 });
   console.log(response);
   await loadOrders();
   closeValidationModal();
   setValidationOrderClicked(old => false);
   }
  }

   //LockModal
   const [lockModalIsOpen,setLockModalIsOpen]=useState(false)
   const [selectedLockOrder,setSelectedLockOrder]=useState({});
   const closeLockModal=()=>{
    setLockModalIsOpen(old => false);
    
  }
  const openLockModal=(order) =>{
    setSelectedLockOrder(order);
    setLockModalIsOpen(true);
  }
  const [lockOrderClicked,setLockOrderClicked]=useState(false);
  const handleOrderLock= async ({idOrder,state})=>{
    if(!lockOrderClicked)
    {
      setLockOrderClicked(old => true);
    let mystate=null;
    if(state=="UNLOCKED")
    {
      mystate="LOCKED"
    }
    else
    {
      mystate="UNLOCKED"
    }
    const endpoint=getEndPoint("/lockSellOrder?id="+idOrder+"&state="+mystate);
    const response = await axios.post(endpoint,{},headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     freeTokenFromSessionStorage();
   }
  });
    console.log(response);
    await loadOrders();
    closeLockModal();
    setLockOrderClicked(old => false);
    }
   }
  
   //Product DeleteModal
   const [productdeleteModalIsOpen,setProductDeleteModalIsOpen]=useState(false);
   const [selectedDeleteProduct,setSelectedDeleteProduct]=useState({});
   const closeProductDeleteModal=()=>{
    setProductDeleteModalIsOpen(old => false);
     
   }
   const openProductDeleteModal=(order,product) =>{
     setSelectedDeleteOrder(order);
     setSelectedDeleteProduct(product);
     setProductDeleteModalIsOpen(true);
   }

    
   const handleProductDelete= async ({idOrder,product})=>{
    console.log(idOrder);
    console.log(product);
    const endpoint=getEndPoint("/deleteOrderProduct?idOrder="+idOrder);
    const response = await axios.post(endpoint,product,headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     freeTokenFromSessionStorage();
   }
  });
    await loadOrders();
    closeProductDeleteModal();
    console.log("Delete Response");
    console.log(response);

   }
   //DeleteModal 
   const [deleteModalIsOpen,setDeleteModalIsOpen]=useState(false);
   const [selectedDeleteOrder,setSelectedDeleteOrder]=useState({});
   const closeDeleteModal=()=>{
     setDeleteModalIsOpen(old => false);
     
   }
   const openDeleteModal=(order) =>{
     setSelectedDeleteOrder(order);
     setDeleteModalIsOpen(true);
   }

    
   const handleOrderDelete= async ({idOrder})=>{
     const endpoint=getEndPoint("/deleteSellOrder?id="+idOrder);
     const response = await axios.delete(endpoint,headers).catch(function (error) {
     if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      freeTokenFromSessionStorage();
    }
   });
     await loadOrders();
     closeDeleteModal();
     console.log("Delete Response");
     console.log(response);

    }

    //Locals
    const [locals,setLocals]=useState([])
    //Search filters
    const [search,setSearch]=useState("");
    const [statusFilter,setStatusFilter] = useState("");
    const [localIdFilter,setLocalIdFilter] = useState("");
    const [facturedFilter,setFacturedFilter]=useState("");
    const [typeFilter,setTypeFilter]=useState("");
    const changeStatusFilter=(status) => 
    {
          if(statusFilter==status)
          {
            setStatusFilter((old) => "");
   
          }
          else
          {
            setStatusFilter((old) => status); 
       
          }
    }
    //Invoice Part
    const [selectedOrderInvoice,setSelectedOrderInvoice]=useState({}); 
    const [invoiceModalIsOpen,setInvoiceModalIsOpen]=useState(false);
    const closeInvoiceModal=()=>{
      setInvoiceModalIsOpen(old => false);
    }
    const openInvoiceModal=(order)=>{
      setInvoiceModalIsOpen(old => true);
      setSelectedOrderInvoice(old => order);
    }

    //Facturer 
    const facturer=async(id)=>{
      const endpoint=getEndPoint("/facturer");
      const endpointWithParam=endpoint+"?id="+id;
      const data= await axios.get(endpointWithParam,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
      
      }
  
      });
      if(data!=null)
      {
        loadOrders();
      }


    }

    //Table 
    const [orders,setOrders]=useState([]);
    const tableSize=10;
    const [page, setPage] = useState(0);    
    const [ordersCount,setOrdersCount]=useState(0);
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };
    //Order Notes Modal
    const [orderNotesIsOpen,setOrderNotesIsOpen]=useState(false);
    const [selectedOrderNotes,setSelectedOrderNotes]=useState({});
    const closeOrderNotes=()=>{
      setOrderNotesIsOpen(old => false);
      
    }
    const openOrderNotes=(order) =>{
      console.log(order);
      setSelectedOrderNotes(order);
      setOrderNotesIsOpen(true);
    }

    const [addProductClicked,setAddProductClicked]=useState(false);
    const handleNotesUpdate= async ({idOrder,data}) => {
       if(!addProductClicked)
       {
        setAddProductClicked(old => true);
      const endpoint=getEndPoint("/addMarketProduct?idOrder="+idOrder+"&productId="+data.productId+"&quantity="+data.quantity);
      console.log("My Data :");
      
      const myData= await axios.post(endpoint,{},headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
        
        }
    
        });
        if(myData.data!=null)
        {
          closeOrderNotes();
          await loadOrders();
        }
        setAddProductClicked(old => false);
      }
    }
    //Order Edit Modal 
    const [orderModalIsOpen,setOrderModalIsOpen]=useState(false);
    const [selectedOrder,setSelectedOrder]=useState({});
    const closeOrderModal=()=>{
      setOrderModalIsOpen(old => false);
      
    }
    const openOrderModal=(order) =>{
      setSelectedOrder(order);
      setOrderModalIsOpen(true);
    }
    const [orderUpdateClicked,setOrderUpdateClicked]=useState(false);
    const handleOrderUpdate= async ({idOrder,data})=>{
     if(!orderUpdateClicked)
     {
      setOrderUpdateClicked(old =>true);
      const myData=new FormData();
      myData.append("id",idOrder);
      myData.append("montant",data.montant);
      myData.append("type",data.type);
      myData.append("file",data.file);
      myData.append("factured",data.factured);
      
      if((data.montant==null || data.montant=="") || ((parseFloat(data.totalPaid)+parseFloat(data.montant))<=parseFloat(data.total)))
      {
       const endpoint=getEndPoint("/sellsOrders");
       const response = await axios.put(endpoint,myData,headers).catch(function (error) {
       if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
       console.log("You're not allowed buddy");
        freeTokenFromSessionStorage();
           
      }
      console.log("Update Error Response Status :");
       console.log(error.response);
     
     });
     console.log("response :");
     console.log(response);
       await loadOrders();
         
  
        closeOrderModal();
 
      }
     
      setOrderUpdateClicked(old =>false);
    }
     }

    //All Loads
    const loadOrders= async() => {
        const endpoint=getEndPoint("/sellsOrders");
        const endPointWithPaginationProperties=endpoint+"?size="+tableSize+"&page="+page+"&statusfilter="+statusFilter+"&localIdFilter="+localIdFilter+"&facturedFilter="+facturedFilter+"&typeFilter="+typeFilter+"&search="+search+"&benefit="+(gainQuotient+1);
        console.log("End Point : ");
        console.log(endPointWithPaginationProperties);
        const data= await axios.get(endPointWithPaginationProperties,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
        
        }
    
        });
        console.log("Orders:");
        console.log(data?.data);
        if(data!=null)
        {
            console.log("Data Content :");
            console.log(data.data.content);
            setOrders((old) => data.data.content);
            setOrdersCount((old) => data.data.totalElements);

        }
    
    
    };
    const loadLocals= async() => {
      const endpoint=getEndPoint("/Local");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      setLocals(old => data.data);

    }
    useEffect(()=>{
      loadLocals();
    },[]);
    useEffect(()=>{
        loadOrders();
    },[page,statusFilter,localIdFilter,facturedFilter,typeFilter,gainQuotient]);


    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
    

    } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
    };


    return ( <div>
      
      <LockModal open={lockModalIsOpen} sellOrder={selectedLockOrder} handleClose={closeLockModal} handleUpdate={handleOrderLock} />
      <ValidationModal open={validationModalIsOpen} sellOrder={selectedValidationOrder} handleClose={closeValidationModal}  handleUpdate={handleOrderValidation} />
        <DeleteModal open={deleteModalIsOpen} sellOrder={selectedDeleteOrder} handleClose={closeDeleteModal} handleUpdate={handleOrderDelete} />

      <NotesModal open={orderNotesIsOpen} handleClose={closeOrderNotes} customerOrder={selectedOrderNotes} handleUpdate={handleNotesUpdate}/>  
      <DeleteProductModal open={productdeleteModalIsOpen} handleClose={closeProductDeleteModal} sellOrder={selectedDeleteOrder} handleUpdate={handleProductDelete} product={selectedDeleteProduct} />
 <InvoiceModal open={invoiceModalIsOpen} handleClose={closeInvoiceModal} order={selectedOrderInvoice}/>  
<EditOrderModal  open={orderModalIsOpen} customerOrder={selectedOrder} handleClose={closeOrderModal} handleUpdate={handleOrderUpdate}  />
<Card className="searchCard" variant="outlined">
        <CardContent>
    
        <p  className="cornerText">Filtres  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Id Client :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Id Client" 
      /> <Button onClick={loadOrders} variant="contained" className="filtreSearchButton"><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </Grid>
              <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local Id :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Local Id</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  value={localIdFilter}
                  onChange={(event)=> setLocalIdFilter(old => event.target.value)}
                >
             <MenuItem value="">Tous</MenuItem>
             {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Est Facturé :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Est Facturé</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  value={facturedFilter}
                  onChange={(event)=> setFacturedFilter(old => event.target.value)}
                >
             
                 <MenuItem value="">Tous</MenuItem>
                    <MenuItem value={true}>Facturée</MenuItem>
                    <MenuItem value={false}>Non Facturée</MenuItem>
           
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Type Commande :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type Commande</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  value={typeFilter}
                  onChange={(event)=> setTypeFilter(old => event.target.value)}
                >
             
                 <MenuItem value="">Tous</MenuItem>
                    <MenuItem value="productsSell">Produits</MenuItem>
                    <MenuItem value="sectionalSofaSell">Salon</MenuItem>
                    <MenuItem value="MARKET">Marché</MenuItem>
           
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={12}><br/></Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Statut :
              </Typography>
              <Grid  container spacing={5} >
              <Grid item  >
                {statusFilter==="Complet" ? 
              <Button onClick={() => {changeStatusFilter("Complet")}} className="myCompletedButton">Complet</Button>:
              <Button onClick={() => {changeStatusFilter("Complet")}} className="myCompletedButtonDisabled">Complet</Button>  
              }
            
            </Grid>
            <Grid item  >
            {statusFilter==="En Cours" ? 
              <Button onClick={() => changeStatusFilter("En Cours")} className="myProcessingButton">En cours</Button>:
              <Button onClick={() => changeStatusFilter("En Cours")} className="myProcessingButtonDisabled">En cours</Button>  
              }
           
            </Grid>
            <Grid item   >
            {statusFilter==="Rejetée" ? 
              <Button onClick={() => changeStatusFilter("Rejetée")} className="myFailedButton">Rejetée</Button>:
              <Button onClick={() => changeStatusFilter("Rejetée")} className="myFailedButtonDisabled">Rejetée</Button>  
              }
      
            </Grid>
          </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
              <Grid  container spacing={5} >
                <Grid item xs={12} md={12}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Marge Benefice : {showGainQuotient()}
              </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                <Slider 
              size="big"
              step={0.05}
             aria-label="Volume"
              value={gainQuotient}
              min={-0.5}
              max={2}
              onChange={(e,value) => {
                setGainQuotient(old => value);
              }} />
                </Grid>
          
              </Grid>
              </Grid>
          </Grid>
          
      
        </CardContent>
      
    </Card>

    <br/>
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Id Client</TableCell>
      <TableCell className="filtertitle" align="left">Id Local</TableCell>
      <TableCell className="filtertitle" align="left">Produits Acheté</TableCell>
      <TableCell className="filtertitle" align="left">Paiements</TableCell>
      <TableCell className="filtertitle" align="left">Statut</TableCell>
      <TableCell className="filtertitle" align="left">Montant Payé</TableCell>
      <TableCell className="filtertitle" align="left">Montant Total</TableCell>
      <TableCell className="filtertitle" align="left">Coût</TableCell>
      <TableCell className="filtertitle" align="left">Facture</TableCell>
      {typeFilter=="sectionalSofaSell" &&
       <TableCell className="filtertitle" align="left">Date Livraison</TableCell>
      }
      <TableCell className="filtertitle" align="left">Edit</TableCell>
      {( typeFilter=="sectionalSofaSell" || typeFilter=="MARKET") &&
       <TableCell className="filtertitle" align="left">AjoutPrd</TableCell>
      }
              {( typeFilter=="MARKET") &&
       <TableCell className="filtertitle" align="left">Bon</TableCell>
   
       }
  
       {( typeFilter=="sectionalSofaSell" || typeFilter=="MARKET") &&
       <TableCell className="filtertitle" align="left">Lock</TableCell>
   
       }
          {( typeFilter=="sectionalSofaSell" || typeFilter=="MARKET") &&
       <TableCell className="filtertitle" align="left">Validation</TableCell>
   
       }
          {( typeFilter=="sectionalSofaSell" || typeFilter=="MARKET") &&
       <TableCell className="filtertitle" align="left">Image</TableCell>
   
       }

            {decodedJWT.Role=="SuperAdmin" &&
                  <TableCell className="filtertitle" align="left">Delete</TableCell>
                   }
      
    </TableRow>
  </TableHead>

  <TableBody>
  {orders
.map(order => (
<TableRow
 key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{order.created_At}</TableCell>
<TableCell align="left">{order.customerId}</TableCell>
<TableCell  align="left">{order.localId}</TableCell>
<TableCell align="left">{order.purchasedProducts.map((product,index)=>(
    <div key={product.productId+index}>
      {parseFloat(product.quantity).toFixed(2)}x {product.productId},Total:{parseFloat(product.total).toFixed(2)} DH
      {" "}
      {
      decodedJWT.Role=="SuperAdmin" &&
      <RemoveIcon onClick={()=> openProductDeleteModal(order,product)} fontSize="3" className="deleteProduct"/>
      }
      
      <br/>

    </div>
))}</TableCell>
<TableCell align="left">{order.purchasePayments.map((paiement)=>(
    <div key={paiement.index}>
      <span className="blueText">Montant :</span>{paiement.amount} DH, <span className="blueText">Type :</span>{paiement.type}, {(paiement.image!=null && paiement.image!=="" )&& <a href={getSellPaymentImageURL(paiement.image)}  target="_blank" rel="noreferrer" >Image</a> }<br/>

    </div>
))}</TableCell>
{/* getEndPoint("images")+"/"+paiement.image */}
<TableCell align="left">
    {order.status==="En Cours" ? 
    <Button disabled={true} className="myProcessingButton">{order.status}</Button>
    :
    order.status==="Complet" ?
    <Button disabled={true} className="myCompletedButton">{order.status}</Button>
    :
    <Button disabled={true} className="myFailedButton" >{order.status}</Button>
    }
    </TableCell>
<TableCell align="left">{order.paidAmount} DH</TableCell>
{
      decodedJWT.Role!="SuperAdmin" ?

      <TableCell align="left">{order.total} DH </TableCell>
      :
      isEditingTotal(order.id)?
      <TableCell align="left"><TextField value={editTotalAmount} onChange={(e) => setEditTotalAmount(old => e.target.value)} id="outlined-basic" label="Total" variant="outlined" /> <DoneIcon  fontSize="large" className="doneIcon" onClick={() => {freeEditTotal()}}/></TableCell>
      :
      <TableCell align="left">{order.total} DH <EditIcon fontSize="large" className="editTotal" onClick={() => {setEditTotal(order.id,order.total)}}/></TableCell>
    
      
    

}

<TableCell align="left">{order.cost} DH</TableCell>
<TableCell onClick={()=> openInvoiceModal(order)} align="left">
  {order.factured? 
  <Button className={"invoiceButtonDone"}>Facturée<SearchIcon/></Button>
   :
   <Button onClick={() => facturer(order.id)} className={"invoiceButton"}>Facturer</Button>
   }</TableCell>
   {
  typeFilter=="sectionalSofaSell" &&
    <TableCell align="left">{order.deliveryDate}</TableCell>
   }
   
<TableCell align="left"><EditIcon  className="editIconGrey" onClick={() => openOrderModal(order)}/></TableCell>
{( (typeFilter=="sectionalSofaSell" || typeFilter=="MARKET")) && 

 (order.orderState=="UNLOCKED" ?
 <TableCell align="left"><NotesIcon  className="notesIcon" onClick={() => openOrderNotes(order)}/></TableCell>
:
<TableCell align="left"></TableCell>
 )

}
{( typeFilter=="MARKET") &&
       <TableCell className="filtertitle" align="left">
        <ReceiptIcon className="orderImage" onClick={() =>{showOrderImage(order.orderInvoiceImage) }}/>
       </TableCell>
   
       }
{( typeFilter=="sectionalSofaSell" || typeFilter=="MARKET") &&
       <TableCell align="left">
        { (order.orderState=="UNLOCKED") ?
        <LockOpenIcon  className="LockOpen" onClick={() => openLockModal(order)}/>
        :
        (decodedJWT.Role=="SuperAdmin") ?
        <LockIcon  className="LockClosed" 
        onClick={() => openLockModal(order)}/>
        :
        <LockIcon  className="LockClosed" 
        />

       
         }
        
        </TableCell>

   
       }
       
       {( typeFilter=="sectionalSofaSell" || typeFilter=="MARKET") &&
       <TableCell align="left">
        { (order.stockValidation!=null && order.stockValidation) ?
         <DoneIcon  fontSize="large"  className="StockValidationDone" />
        :
        <DoneIcon className="StockValidationNotDone" onClick={() => openValidationModal(order)}/>

       
         }
        
        </TableCell>

   
       }
       {(typeFilter=="sectionalSofaSell" ||  typeFilter=="MARKET") &&
       <TableCell className="filtertitle" align="left">
        {order.orderImage!=null ?
          <ReceiptIcon className="orderImage" onClick={() =>{showOrderDeliveryImage(order.orderImage) }}/>
    
      :
      <Button
      variant="contained"
      component="label" 
     
    
    >
      <CameraAltIcon/>
      <input
      accept="image/*"
        type="file"
        hidden
       onChange={(event) => uploadOrderImage(order.id,event.target.files[0])}
      />
      
    </Button>  }
         </TableCell>
   
       }
{decodedJWT.Role=="SuperAdmin" &&
                  <TableCell align="left">
                    <DeleteIcon  className="deleteIcon" 
                     onClick={() => openDeleteModal(order)}
                    />
                    </TableCell>
                   }

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={ordersCount}
          rowsPerPage={tableSize}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>
</TableContainer>
    <br/>
    </div>);
 
 };
 export default View;