import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container
   } from "@mui/material";



//Css
import "./interfaceComptable.css";

//Icons 
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, getSellPaymentImageURL, saveLogoutInLocalStorage } from "../../helpers/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const View= () => {

    //Local 
    const [locals,setLocals]=useState([]);
    const [localId,setLocalId]=useState("");
    //Table
    const [orders,setOrders]=useState([]);
    const tableSize=10;
    const [page, setPage] = useState(0);    
    const [ordersCount,setOrdersCount]=useState(0);
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };


    //Loads
    const loadOrders= async() => {
        const endpoint=getEndPoint("/facturedOrders");
        const endPointWithPaginationProperties=endpoint+"?size="+tableSize+"&page="+page+"&localId="+localId;
        console.log("End Point : ");
        console.log(endPointWithPaginationProperties);
        const data= await axios.get(endPointWithPaginationProperties,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
        
        }
    
        });
        console.log("Orders:");
        console.log(data?.data);
        if(data!=null)
        {
            setOrders((old) => data.data.content);
            setOrdersCount((old) => data.data.totalElements);

        }
    
    
    };
    const loadLocals= async() => {
        const endpoint=getEndPoint("/Local");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
     
        });
        setLocals(old => data.data);
  
      }
      useEffect(()=>{
        loadLocals();
        loadOrders();
      },[localId,page]);

      
    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
    

    } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
    };


    return ( <div>
        <Card className="searchCard" variant="outlined">
        <CardContent>
    
        <p  className="cornerText">Recherche  </p>
          <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local Id :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Local Id</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  value={localId}
                  onChange={(event)=> setLocalId(old => event.target.value)}
                >
             <MenuItem value="">Tous</MenuItem>
             {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={12}><br/></Grid>
       
          </Grid>
          
      
        </CardContent>
      
    </Card>

    <br/>
    <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Id Client</TableCell>
      <TableCell className="filtertitle" align="left">Id Local</TableCell>
      <TableCell className="filtertitle" align="left">Produits Acheté</TableCell>
      <TableCell className="filtertitle" align="left">Montant Total</TableCell>

    </TableRow>
  </TableHead>

  <TableBody>
  {orders
.map(order => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{order.created_At}</TableCell>
<TableCell align="left">{order.customerId}</TableCell>
<TableCell  align="left">{order.localId}</TableCell>
<TableCell align="left">{order.purchasedProducts.map((product)=>(
    <div key={product.productId}>
      {product.quantity}x {product.productId},Total:{product.total}<br/>

    </div>
))}</TableCell>

{/* getEndPoint("images")+"/"+paiement.image */}


<TableCell align="left">{order.total} DH</TableCell>




</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={ordersCount}
          rowsPerPage={tableSize}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>
</TableContainer>
    <br/>
    </div>);
 
 };
 export default View;