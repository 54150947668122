import React, { useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { getEndPoint, getSellPaymentImageURL, saveLogoutInLocalStorage } from "../../helpers/constants";
import { logout } from "../../redux/auth/authactions";
import axios from "axios";

//Others 
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";

//Icons
import AddIcon from '@mui/icons-material/Add';

//Css
import "./ticket.css";
const View= () => {
    //Date management
    const [date,setDate] = useState(new Date("2022-05-25"));
    const incrementDate=()=>{
        setDate((old)=> {
            var nextDay = new Date(old);
            nextDay.setDate(old.getDate() + 1);
            return nextDay;
        });

    }
    const decrementDate=()=>{
      
        setDate((old)=> {
            var nextDay = new Date(old);
            nextDay.setDate(old.getDate() - 1);
            return nextDay;
        });

    }

 

    //
    const productsList=[
        //Tissu
        {
            productId:"AE664F8",
            sell_price:100,
            buy_price:80,
        },
        {
            productId:"AE674F8",
            sell_price:110,
            buy_price:85,
        },
        {
            productId:"BR664F8",
            sell_price:130,
            buy_price:100,
        },
        //Bois

        {
            productId:"EE664F8",
            sell_price:50,
            buy_price:40,
        },
        {
            productId:"ZA674F8",
            sell_price:60,
            buy_price:45,
        },
        {
            productId:"GR624F8",
            sell_price:80,
            buy_price:50,
        },
        //Eponge
        {
            productId:"FE64RF8",
            sell_price:80,
            buy_price:70,
        },
        {
            productId:"LA4E4F8",
            sell_price:90,
            buy_price:75,
        },
        {
            productId:"KM624F8",
            sell_price:110,
            buy_price:80,
        },

    ];

      // JWT and  freesession
      const history=useNavigate();
      const jwt=useSelector(state => state.jwt);
      const dispatch=useDispatch();
      const headers = { headers: {
          "Authorization" : `Bearer ${jwt}`,
        
    
        } }
      const freeTokenFromSessionStorage = () => {
          dispatch(logout);
          saveLogoutInLocalStorage();
          history("/login");
        };

   //Create Order
    const createSellOrder= async()=>{
         


        //Products Part
         let numberOfProducts=Math.floor(Math.random()*(4-1+1)+1);
         let myPurchasedProducts=[];
         for(var i=1;i<=numberOfProducts;i++)
         {
             let productIndex=Math.floor(Math.random()*(8-0+1)+0);
             let productQuantity=Math.floor(Math.random()*(5-1+1)+1);
            myPurchasedProducts.push(
                {
                productId:productsList[productIndex].productId,
                quantity:productQuantity,
                sell_price:productsList[productIndex].sell_price,
                buy_price:productsList[productIndex].buy_price,
                totalGain:(productsList[productIndex].sell_price*productQuantity)-(productsList[productIndex].buy_price*productQuantity),
                total:productsList[productIndex].sell_price*productQuantity,
                }
              );
         }
         //Order Total
         var myTotal=0;
         myPurchasedProducts.map(
             product => 
             {
                myTotal+=product.total;
             }
         )



         //PaymentsPart

         //myPaidAmount
         const myPaidAmount=myPurchasedProducts[0].total;
         //Status
         let myStatus="";
         if(myPaidAmount<myTotal)
         {
            myStatus="En Cours";
      
         }
         else
         {
            myStatus="Complet";
         }
         //paymentlist
         const myPaymentsList=
            [
            {
              index:0,
              amount:myPurchasedProducts[0].total,
              type:"virement",
              image:"",
            }
          ];

         //
         let formData=new FormData();
         let myDebugMonth=(date.getMonth+1)>=10 ? (date.getMonth()+1) : "0"+(date.getMonth()+1);
         let myDebugDay=(date.getDate())>=10 ? (date.getDate()) : "0"+(date.getDate());
         let data={
            debugDate:date.getFullYear()+"-"+myDebugMonth+"-"+myDebugDay,
            customerId:"AD280915",
            localId:"l"+Math.floor(Math.random()*(3-1+1)+1),
            agentId:"AE164987",
            total:myTotal,
            paidAmount:myPaidAmount,
            status:myStatus,
            factured:false,
            purchasedProducts:myPurchasedProducts,
            purchasePayments:myPaymentsList
         };
     

         formData.append("givendata",JSON.stringify(data));
      
      const endpoint=getEndPoint("/sellProducts");
         const response = await axios.post(endpoint,formData,headers).catch(function (error) {
           if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
             console.log("You're not allowed buddy");
             freeTokenFromSessionStorage();
            
           }
      
         });
        if(response!=null)
       console.log(response.data);
      
    
      
       }

     



    return ( <div>Displaying tickets <br/>
   
    <Button onClick={createSellOrder} className="myNormalButton" variant="contained" endIcon={<AddIcon />}>
               Create Order Debug Button
            </Button> 
            <br/>
            <p>Date : {date.getFullYear()}-{(date.getMonth+1)>=10 ? (date.getMonth()+1) : "0"+(date.getMonth()+1)}-{date.getDate()}</p>
            <br/>
            <Button onClick={incrementDate} className="myMNormalButton" variant="contained" >
               Increment
            </Button> 
           {"                   "} 
           <Button onClick={decrementDate} className="myMNormalButton" variant="contained" >
               Decrement
            </Button> 


         
      
      </div>);
 
 };
 export default View;