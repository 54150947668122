import React from "react"
import "./myStyle.css";

const View=({onChange,value,placeholder,found}) =>{
if(found===true)
return <input className="mySmallInputStyle FoundProductInputBorder" value={value} onChange={onChange} 
placeholder={placeholder}
/>
else if(found===false)
return <input className="mySmallInputStyle" id="notFoundProductInputBorder" value={value} onChange={onChange} 
placeholder={placeholder}
/>
else

return <input className="mySmallInputStyle" value={value} onChange={onChange} 
placeholder={placeholder}
/>

};
export default View;



