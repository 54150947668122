import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
    Card,CardContent, Grid ,Typography,TableContainer,Table
    ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
    Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";

  import React, { useState,useEffect } from "react";
  import "./index.css";
  import { useDispatch, useSelector } from "react-redux";
  import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays} from "../../../../helpers/constants";
  import {logout} from "../../../../redux/auth/authactions";


  //My Costum inputs
import MyInput from "../../../../globalComponents/MyInput";


  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  const UpdateModal=({open,handleClose,local,handleUpdate}) => {
    const [id,setId] = useState("");
    const [address,setAddress] = useState("");
    const [size,setSize] = useState("");
    const [floors,setFloors] = useState("");
    const [rent,setRent] = useState("");
    const [start_date,setStart_date] = useState({
      day:0,
      month:0,
      year:0,
    });
    const [contract,setContract] = useState("");
    const [contract_info,setContract_info] = useState("");
        //Date Methods
        const setYear=(value) =>
        {
          setStart_date((old) => {
                return {
                  ...old,
                  year:value,
                }
          });
    
        };
        const setMonth=(value) =>
        {
          setStart_date((old) => {
                return {
                  ...old,
                  month:value,
                }
          });
    
        };
        const setDay=(value) =>
        {
          setStart_date((old) => {
                return {
                  ...old,
                  day:value,
                }
          });
    
        };
    const mydateToString=()=>{
      return start_date.year+"-"+start_date.month+"-"+start_date.day;

    }

   


   
   useEffect(
     () => {
      setId(local?.id)
      setAddress(local?.address);
      setSize(local?.size);
      setFloors(local?.floors);
      setRent(local?.rent);
      setContract(local?.contract);
      setContract_info(local?.contract_info);
      setStart_date(local?.start_date);

     },
     [local]
   );

   
    
      const updatedData=() => {
        const data={
          id:id,
          address:address,
          size:size,
          floors:floors,
          rent:rent,
          contract:contract,
          contract_info:contract_info,
          start_date:new Date(mydateToString()),
       }
  
       return data;
      }

      return (
          <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogTitle className="cornerText"> Modifier Local : {local?.id} </DialogTitle>
          <DialogContent id="dialogContent" >
            <Grid style={{padding:10}} container spacing={1}>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Adresse :
              </Typography>
              <MyInput value={address} onChange={(event) => setAddress(event.target.value)} 
              placeholder="Adresse" 
            />
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Superficie :
              </Typography>
              <MyInput value={size} onChange={(event) => setSize(event.target.value)} 
              placeholder="Superficie" 
            />
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Étage :
              </Typography>
              <MyInput value={floors} onChange={(event) => setFloors(event.target.value)} 
              placeholder="Étage" 
            />
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Loyer :
              </Typography>
              <MyInput value={rent} onChange={(event) => setRent(event.target.value)} 
              placeholder="Loyer" 
            />
                </Grid>    
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Bail :
              </Typography>
              <MyInput value={contract} onChange={(event) => setContract(event.target.value)} 
              placeholder="Bail" 
            />
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Infos Bail :
              </Typography>
              <MyInput value={contract_info} onChange={(event) => setContract_info(event.target.value)} 
              placeholder="Infos Bail" 
            />
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Date de Début :
              </Typography>
              <Grid style={{width:"16.5rem"}} container spacing={0.5}>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Année</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Année"
                  className="birthdayselectStyle"
          
                  onChange={(event)=> setYear(event.target.value)}
                >
                  {generateArrayOfYears().map(year => (
            
                    <MenuItem key={year} value={year}>{year}</MenuItem>

                  ))}
                 
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Mois</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Mois"
                  className="birthdayselectStyle"
             
                  onChange={(event)=> setMonth(event.target.value)}
                >
                  {generateArrayOfMonths().map((month) => (
        
                    <MenuItem key={month.number} value={month.number}>{month.name}</MenuItem>

                  ))}
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Jour</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Jour"
                  className="birthdayselectStyle"
   
                  onChange={(event)=> setDay(event.target.value)}
                >
                  {generateDays().map((daynNumber) => (
        
        <MenuItem key={daynNumber} value={daynNumber}>{daynNumber}</MenuItem>

      ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
                </Grid>
       
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
            <Button id="modifierButton" onClick={
             () => {
              handleUpdate({
                idLocal:local.id,
                data:updatedData(),
              });
             }
              }>Modifier</Button>
          </DialogActions>
        </Dialog>
      )
  };


  export default UpdateModal;
  