import { Button } from "@mui/material";
import React from "react";
import "./tableselection.css";

const TableSelection=({isSupplierTable,changeDisplayedTable}) =>(
    <div className="TableSelectionPositioning">
        {isSupplierTable ? 
        <div>
        <Button id="leftpart" onClick={changeDisplayedTable} className="clickedBlackOpacity">Produits</Button>
        <Button id="rightpart" onClick={changeDisplayedTable} >Commandes</Button>
        </div>
        :
         <div>
        <Button id="leftpart" onClick={changeDisplayedTable} >Produits</Button>
        <Button id="rightpart" className="clickedBlackOpacity" onClick={changeDisplayedTable} >Commandes</Button> 
         </div>
         }


    </div>

);

export default TableSelection;