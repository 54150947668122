//Mui , hooks imports
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container
   } from "@mui/material";

//Icons  
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";

//Css
import "./vente.css";

//My Components 
import MyTitle from "./components/MyTitle"
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, getSellPaymentImageURL, saveLogoutInLocalStorage,getCompressedImage } from "../../helpers/constants";
import axios from "axios";

//Modals 
import ProductSearchModal from "./components/ProductSearchModal"
import CustomerUpdateModal from "./components/CustomerUpdateModal"
import InvoiceModal from "../invoice/InvoiceModal";


//Others 
import { useNavigate } from "react-router-dom";
import TableSelection from "./components/TableSelection";


const View= () => {
  //Agent Identification
  const decodedJWT=useSelector(state => state.decodedJWT );
  //Locals 
  const [locals,setLocals]=useState([]);
  //New Customer Part
  const [idCustomerCreation,setIdCustomerCreation]=useState("");
  const [nameCustomerCreation,setNameCustomerCreation]=useState("");
  const [phoneCustomerCreation,setPhoneCustomerCreation]=useState("");
  const [addressCustomerCreation,setAddressCustomerCreation]=useState("");


   //Sell 
  //  const [supplierOrders,setSupplierOrders]=useState([]);
   const [stock,setStock]=useState([]);
   const [customers,setCustomers]=useState([]);
   const [idCustomerSell,setIdCustomerSell]=useState("");
   const [idLocalSell,setIdLocalSell]=useState("");
   useEffect(()=>{
    console.log("Decoded JWT :");
    console.log(decodedJWT);
    if(decodedJWT.Role!="SuperAdmin")
    setIdLocalSell(decodedJWT.localId);
 
   },[decodedJWT]);
   const [boughtProducts,setBoughtProducts]=useState(
     [
     {
       found:null,
       index:0,
       productId:"",
       quantity:"",
       sell_price:null,
       buy_price:null,
       total:null,
       totalGain:null,
     }
   ]);
   const [productIndex,setProductIndex]=useState(1);
   //Order Information


   const [totalPayment,setTotalPayment]=useState(null);
   const [paymentsList,setPaymentsList]=useState(
     [
     {
       index:0,
       amount:"",
       type:"virement",
       image:null,
     }
   ]);
   const [paymentIndex,setPaymentIndex]=useState(1);

//Table
const [sellsHistory,setSellsHistory]=useState([]);
const [isCustomerTable,setisCustomerTable]=useState(true);
const [page, setPage] = useState(0);
const [search,setSearch]= useState("");
const handleChangePage = (event,newPage) => 
 {
     setPage(newPage);
 };
 const changeDisplayedTable=() =>{
  setisCustomerTable((old) => !old);
 }
//SupplierOrder edit modal
   const [customerModalIsOpen,setCustomerModalIsOpen]=useState(false);
   const [selectedCustomer,setSelectedCustomer]=useState({});
   const closeCustomerModal=()=>{
    setCustomerModalIsOpen(old => false);
     
   }
   const openCustomerModal=(customer) =>{
      setSelectedCustomer(customer);
      setCustomerModalIsOpen(true);
   }
   const handleCustomerUpdate= async ({idCustomer,data})=>{
     const endpoint=getEndPoint("/customers");
     const response = await axios.put(endpoint,data,headers).catch(function (error) {
     if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     console.log("You're not allowed buddy");
      freeTokenFromSessionStorage();
         
    }
    console.log("Update Error Response Status :");
     console.log(error.response);
   
   });
   console.log("Updated Data :");
   console.log(response.data);
   
     await loadCustomers();  
      closeCustomerModal();
   }




//Axios Calls
  //New Customer Methods 
   const createCustomer=async()=>{
     const data={
       id:idCustomerCreation,
       name:nameCustomerCreation,
       address:addressCustomerCreation,
       phone:phoneCustomerCreation,
       type:"ordinaire",
     }
     const endpoint=getEndPoint("/customers");
     const response = await axios.post(endpoint,data,headers).catch(function (error) {
       if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
         console.log("You're not allowed buddy");
         freeTokenFromSessionStorage();
        
       }
    
  
     });
     console.log("Return :")
     console.log(response.data);
    // Load Suppliers for table await loadUsers();
       if(response!=null)
       {
        clearCustomerInput();
        loadCustomers();
       }
   
    
   };
   const clearCustomerInput=()=>{
    setIdCustomerCreation("");
    setNameCustomerCreation("");
    setPhoneCustomerCreation("");
    setAddressCustomerCreation("");

   }



//Sell Methods
const setBoughtProduct=async ({index,value}) =>{
  const endpoint=getEndPoint("/product?productid="+value);
  const data= await axios.get(endpoint,headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      console.log("You're not allowed buddy");
      freeTokenFromSessionStorage();
     
    }

  });
  const found = (data!=null);
  setBoughtProducts((old) => {
    let mylist=[...old];
     
    mylist[index].productId=value;
    mylist[index].found=found;
    if(found)
    {
      mylist[index].sell_price=data.data.sell_price;
      mylist[index].buy_price=data.data.buy_price;
      if( mylist[index].quantity!="")
      {
      mylist[index].total=mylist[index].sell_price*mylist[index].quantity;
      mylist[index].totalGain=(mylist[index].sell_price*mylist[index].quantity)-(mylist[index].buy_price*mylist[index].quantity);
      }
      else
      {
      mylist[index].total=null;
      mylist[index].totalGain=null;
      }
    }
    else
    {
      mylist[index].total=null;
      mylist[index].totalGain=null;
     
    }
    calculateTotal(mylist);
    return mylist;

  });
  console.log("bought products :");
  console.log(boughtProducts);


}
const setBoughtProductQuantity=({index,value})=>{
  setBoughtProducts((old) => {
    let mylist=[...old];
    mylist[index].quantity=value;
    if(mylist[index].found)
    {
      mylist[index].total=mylist[index].sell_price*mylist[index].quantity;
      mylist[index].totalGain=(mylist[index].sell_price*mylist[index].quantity)-(mylist[index].buy_price*mylist[index].quantity);
     
    }
    else
    {
      mylist[index].total=null;
      mylist[index].totalGain=null;
    }
   
    calculateTotal(mylist);
    return mylist;

  });
  

}
const addProduct= ()=>{
      
  setBoughtProducts( old =>
   {
     let mylist=[...old];
     mylist.push(
      {          
        found:null,
        index:productIndex,
        productId:"",
        quantity:"",
        buy_price:null,
        sell_price:null,
        totalGain:null,
        total:null,
      }
     );
     calculateTotal(mylist);
   return mylist;

  }
  );
  setProductIndex(old => old+1);

  
};
const removeProduct=()=>{
  if(productIndex>1)
  {
  setBoughtProducts(
    (old) =>{
      let mylist=[...old];
      mylist.pop();
      calculateTotal(mylist);
      return mylist;
    }
  );
  setProductIndex(old => old-1);
  }

};


//  Sell Methods /Payment Infos 
const calculateTotal=(list) =>
{
  let total=0;

  for (var i = 0; i < list.length; i++) {
    if(list[i].total==null || isNaN(list[i].total) )
    {
      total=null;
      break;
    }
    else
      total+=list[i].total;
  }
  setTotalPayment(old => total);
  return total;

}
const calculateTotalCost=(list) => {
  let total=0;

  for (var i = 0; i < list.length; i++) {
    if(list[i].total==null || isNaN(list[i].total) )
    {
      total=null;
      break;
    }
    else
      total+=list[i].buy_price*list[i].quantity;
  }
  return total;


}
const calculateTotalPayment=() => {
  let total=0;
  paymentsList.map(payment=> {
    total+=parseFloat(payment.amount);
  });
  return total;
}
const setPaymentAmount=({index,value})=>{
  setPaymentsList((old) => {
    let mylist=[...old];
    mylist[index].amount=value;
    return mylist;

  });

}
const setPaymentType=({index,value})=>{
  setPaymentsList((old) => {
    let mylist=[...old];
    mylist[index].type=value;
    return mylist;

  });
}
const setPaymentImage=({index,value})=>{
  console.log(value);
  setPaymentsList((old) => {
    let mylist=[...old];
    mylist[index].image=value;
    return mylist;

  });

}
const addPayment=()=>{
  setPaymentsList(old =>
    {
      let mylist=[...old];
      mylist.push(
        {
          index:paymentIndex,
          amount:"",
          type:"virement",
          image:null,
        }
      );
    return mylist;

    });
    setPaymentIndex(old => old+1);
    console.log("payment list :");
    console.log(paymentsList);

}
const removePayment=()=>{
  if(paymentIndex>1)
  {
    setPaymentsList(
    (old) =>{
      let mylist=[...old];
      mylist.pop();
      return mylist;
    }
  );
  setPaymentIndex(old => old-1);
  }
  console.log("payment list :");
  console.log(paymentsList);
};
//Sell Methods Valider
const [sellOrderClicked,setSellOrderClicked]=useState(false);
const createSellOrder= async()=>{
  if(!sellOrderClicked && totalPayment!=null && (calculateTotalPayment()<=totalPayment))
  {
    setSellOrderClicked(old => true);
    console.log("total payment :");
    console.log(calculateTotalPayment());
    console.log("total Total :");
    console.log(totalPayment);
   
   let formData=new FormData();
   let data={
      customerId:null,
      localId:null,
      agentId:null,
      total:null,
      cost:null,
      paidAmount:null,
      status:null,
      factured:false,
      purchasedProducts:[],
      purchasePayments:[]
   };
   data.customerId=idCustomerSell;
   data.localId=idLocalSell;
   data.total=totalPayment;
   data.cost=calculateTotalCost(boughtProducts);
   boughtProducts.map((product)=>{

    
     data.purchasedProducts.push(
       {
       productId:product.productId,
       quantity:product.quantity,
       sell_price:product.sell_price,
       buy_price:product.buy_price,
       totalGain:product.totalGain,
       total:product.total,
       }
     );

   });
   let paidamountVariable=0;
   var images = await Promise.all(paymentsList.map(async(payment)=>{
    console.log("Payment Image :");
    console.log(payment.image);
    var compressedImage=null;
    if(payment.image!=null)
    {
      console.log("Compressing :");
       compressedImage=await getCompressedImage(payment.image);
    }
    console.log("Compressed File :");
    console.log(compressedImage);
     formData.append("files",compressedImage);

     paidamountVariable+=parseFloat(payment.amount);

     data.purchasePayments.push(payment);
     return compressedImage;
     
   }));

   
   data.paidAmount=paidamountVariable;

   if(paidamountVariable<data.total)
   {
   data.status="En Cours";

   }
   else
   {
   data.status="Complet";
   }
  data.purchasePayments.map((purchasepayment)=>
        {
     if(purchasepayment.image==null)
     purchasepayment.image="";
     else
     purchasepayment.image="Found";
   } 
     );
   formData.append("givendata",JSON.stringify(data));

const endpoint=getEndPoint("/sellProducts");
   const response = await axios.post(endpoint,formData,headers).catch(function (error) {
     if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
       console.log("You're not allowed buddy");
       freeTokenFromSessionStorage();
      
     }

   });
   if(response!=null)
   {
    clearSellOrder();
    loadOrders();
   }
   else
   clearInputFiles();


 }
 setSellOrderClicked(old => false);

 }
 const clearInputFiles=()=>{
  paymentsList.map((payment)=>{
    payment.image=null;
    
  });

}
const clearSellOrder=() => {
  setIdCustomerSell("");



  setBoughtProducts([
    {
      found:null,
      index:0,
      productId:"",
      quantity:"",
      sell_price:null,
      buy_price:null,
      totalGain:null,
      total:null,
    }
  ]);
  setProductIndex(1);
  setTotalPayment(null);
  setPaymentsList([
    {
      index:0,
      amount:"",
      type:"virement",
      image:null,
    }
  ]);
  setPaymentIndex(1);
}




//Product Search Modal

    const [productInputIndex,setProductInputIndex]=useState(null);
    const [productModalIsOpen,setProductModalIsOpen]=useState(false);
    const closeProductModal=()=>{
      setProductModalIsOpen(old => false);
      
    }
    const openProductModal=(index) =>{
      setProductModalIsOpen(old => true);
      setProductInputIndex(index);  
    }

     const handleProductModalSubmit= async ({indexProduct,productID,product})=>{
      setBoughtProducts((old) => {
        let mylist=[...old];
         
        mylist[indexProduct].productId=productID;
        mylist[indexProduct].found=true;
        mylist[indexProduct].sell_price=product.sell_price;
        mylist[indexProduct].buy_price=product.buy_price;
        if( mylist[indexProduct].quantity!="")
        {
        mylist[indexProduct].total=mylist[indexProduct].sell_price*mylist[indexProduct].quantity;
        mylist[indexProduct].totalGain=(mylist[indexProduct].sell_price*mylist[indexProduct].quantity)-(mylist[indexProduct].buy_price*mylist[indexProduct].quantity);
      }
        else
        {
        mylist[indexProduct].total=null;
        mylist[indexProduct].totalGain=null;
        }
        calculateTotal(mylist);
        return mylist;

      });

      closeProductModal();
      
        
    }



//All Loads 
const loadOrders=async()=>{
  const endpoint=getEndPoint("/sellsHistory");
  const data= await axios.get(endpoint,headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      console.log("You're not allowed buddy");
      freeTokenFromSessionStorage();
     
    }

  });
  console.log("Stock :");
  console.log(data?.data);
  setSellsHistory(old => data.data);
};
const loadStock=async()=>{
  const endpoint=getEndPoint("/stock");
  const data= await axios.get(endpoint,headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      console.log("You're not allowed buddy");
      freeTokenFromSessionStorage();
     
    }

  });
  console.log("Stock :");
  console.log(data?.data);
  setStock(old => data.data);

};
const loadCustomers=async()=>{
  const endpoint=getEndPoint("/customers");
  const data= await axios.get(endpoint,headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      console.log("You're not allowed buddy");
      freeTokenFromSessionStorage();
     
    }

  });
  console.log("Customers :");
  console.log(data?.data);
  setCustomers(old => data.data);

};
const loadLocals= async() => {
  const endpoint=getEndPoint("/Local");
  const data= await axios.get(endpoint,headers).catch(function (error) {
    if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      console.log("You're not allowed buddy");
      freeTokenFromSessionStorage();
     
    }

  });
  setLocals(old => data.data);

}
useEffect(()=>{
  loadCustomers();
  loadStock();
  loadOrders();
  loadLocals();
},[]);


// JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
      
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };



    return ( <div>    
   <ProductSearchModal open={productModalIsOpen} index={productInputIndex} products={stock} handleClose={closeProductModal} handleSubmit={handleProductModalSubmit}  />
   <CustomerUpdateModal  open={customerModalIsOpen} customer={selectedCustomer} handleClose={closeCustomerModal} handleUpdate={handleCustomerUpdate}  />
        <Grid container spacing={1}>
 <Grid item md={6} xs={12}>
    <Card className="creationCard" variant="outlined">
        
       
    <CardContent >
              <MyTitle bgColor={"BLUE"}>Nouveau Client</MyTitle>
              <br/>
          
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                 ID : 
                </Typography>
                <MyInput 
                placeholder="ID"  value={idCustomerCreation} onChange={(event) => {setIdCustomerCreation(old => event.target.value)}}
        />
                  </Grid>
                  <Grid item >
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  NomPrenom :
                </Typography>
                <MyInput 
                placeholder="NomPrenom" value={nameCustomerCreation} onChange={(event) => {setNameCustomerCreation(old => event.target.value)}}
                 />
                  </Grid>
                  </Grid>
         
                  <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
                  <Grid item >
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Numéro de téléphone :
                </Typography>
                <MyInput 
                placeholder="Numéro de téléphone" value={phoneCustomerCreation} onChange={(event) => {setPhoneCustomerCreation(old => event.target.value)}}
             />
                  </Grid>
      
                  <Grid item >
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Adresse :
                </Typography>
                <MyInput 
                placeholder="Adresse" value={addressCustomerCreation} onChange={(event) => {setAddressCustomerCreation(old => event.target.value)}}
            />
                  </Grid>
                  </Grid>
                  <br/>
               
    
            <br/>
            <br/>
              <Button onClick={createCustomer}  className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
                  Créer
                </Button> 
              
            
            </CardContent>
          
        
            </Card>  
            </Grid>
            <Grid item md={6} xs={12}>
            <Card className="creationCard" variant="outlined">
<CardContent>
<MyTitle bgColor={"GREEN"}>Nouvelle Commande</MyTitle>
              <br/>

          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
      
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID Client :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Client</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="ID Client"
                  value={idCustomerSell}
                  className="selectStyle"
                 onChange={(event) => setIdCustomerSell(event.target.value)}
                >
            
                  {customers.map(customer => (
                   <MenuItem key={customer.id} value={customer.id}>{customer.id}</MenuItem>
                 ))} 
                </Select>
              </FormControl>
             </Grid>
             {decodedJWT.Role=="SuperAdmin" ?
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                   onChange={(event)=> {setIdLocalSell((old)=> (event.target.value))}}
                >
             
             {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
             </Grid>
            :
             null
            }
             
          </Grid>
        
          <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
          <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Produits 
            </Typography>
            </Grid>
            </Grid>
      
      {boughtProducts.map((product)=>
            <Grid key={product.index} container direction="row" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID Produit :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={(product.productId==="")? null : product.found}  value={product.productId} onChange={(event) =>  setBoughtProduct({index:product.index,value:event.target.value})} 
              placeholder="ID Produit" />
               <span style={{"width":"2px"}}/>
               <Button  variant="contained" onClick={() => openProductModal(product.index)} ><SearchIcon style={{"fontSize":"28px"}}/></Button> 
              </span>
              {product.total!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                  {isNaN(product.total)?
                   <Typography className="errorlittleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Quantité invalide !
                   </Typography>
                  :
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                    Total : {product.total} DH
                  </Typography>
                  }
                  
                  </Grid>
                  </Grid>
           
                  
               }
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Quantity : 
              </Typography>
              <MyInput value={product.quantity} onChange={(event) => setBoughtProductQuantity({index:product.index,value:event.target.value})} 
              placeholder="Quantity" 
             />
            </Grid>
            </Grid>
          )}
          
          <br/>
            <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addProduct} fontSize="large" className="addProductIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removeProduct} fontSize="large" className="removeProductIconContainer" />
              </Grid>
            </Grid>
            <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Informations de commande 
            </Typography>
            </Grid>
            </Grid>
            {totalPayment!==null && 
              <Container className="orderInfoContainer" >
              <Grid container  spacing={0}>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Total :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                    <Grid item>
                <Typography className="bigTitle" sx={{ fontSize: 14 }} gutterBottom>
                 {totalPayment} DH
                </Typography>
                </Grid>
                </Grid>

                </Grid>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Paiements :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
             
                {paymentsList.map((payment) =>(
                  <Grid style={{"paddingBottom":"5px"}} key={payment.index} container spacing={1}  direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                   <SmallInput found={(payment.amount==="" || !isNaN(payment.amount) )? 
                                         null
                                         :
                                         false} value={payment.amount} onChange={(event) =>{setPaymentAmount({index:payment.index,value:event.target.value})}} 
                          placeholder="Montant" 
                   />
                <span >  </span>
                         <FormControl >
                <InputLabel id="demo-simple-select-label">Type Paiement</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Paiement"
                  className="smallSelectStyle"
                  value={payment.type}
                  onChange={(event)=> {setPaymentType({index:payment.index,value:event.target.value})}}
                >
                 <MenuItem value="virement">virement</MenuItem>
                    <MenuItem value="transfert">transfert</MenuItem>
                    <MenuItem value="espece">espece</MenuItem>
                    <MenuItem value="cheque">cheque</MenuItem>
           
                </Select>
                    </FormControl>
                    <span >  </span>
                    <Button
                        variant="contained"
                        component="label" 
                        className={payment.image!==null? "insertPhotoButton":""    }
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => setPaymentImage({index:payment.index,value:event.target.files[0]})}
                        />
                      </Button>
                   </Grid>
                </Grid>
    ))}
                <br/>
                <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addPayment} fontSize="small" className="addPaymentIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removePayment} fontSize="small" className="removePaymentIconContainer" />
              </Grid>
            </Grid>

                </Grid>

              </Grid>
              
            </Container>
            }
          
             


             <br/>
             <br/>
             {
              sellOrderClicked ?
              <Button color={"warning"} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
              Valider
            </Button>  
            :
            <Button onClick={createSellOrder} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
            Valider
          </Button>  
             }
          
        </CardContent>
        </Card>   
            </Grid>
   
            </Grid>
            <br/>
            <Card className="searchCard" variant="outlined">
<TableSelection  changeDisplayedTable={changeDisplayedTable} isCustomerTable={isCustomerTable}/>
        <CardContent>
    
        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
      />
              </Grid>
          </Grid>
      
        </CardContent>
      
    </Card>


    <br/>
{/* Table Part */}
{isCustomerTable ? 
  <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Id Client</TableCell>
      <TableCell className="filtertitle" align="left">NomPrenom</TableCell>
      <TableCell className="filtertitle" align="left">Numéro de téléphone</TableCell>
      <TableCell className="filtertitle" align="left">Adresse</TableCell>
      <TableCell className="filtertitle" align="left">Type</TableCell>
      <TableCell className="filtertitle" align="left">Date Création</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {customers.filter(customer => (
  (customer.id).toLowerCase()?.includes(search.toLowerCase()) || 
  (customer.name).toLowerCase()?.includes(search.toLowerCase()) || 
 (customer.phone).toLowerCase()?.includes(search.toLowerCase()) ||
  (customer.address).toLowerCase()?.includes(search.toLowerCase()) 

  ))
.slice(page * 10, page * 10 + 10)
.map(customer => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{customer.id}</TableCell>
<TableCell align="left">{customer.name}</TableCell>
<TableCell  align="left">{customer.phone}</TableCell>
<TableCell align="left">{customer.address}</TableCell>
<TableCell align="left">{customer.type}</TableCell>
<TableCell align="left">{customer.created_at}</TableCell>
<TableCell align="left"><EditIcon onClick={() => {openCustomerModal(customer)}}  className="editIcon"/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            customers.filter(customer => (
              customer.id?.includes(search) || 
              customer.name?.includes(search) || 
              customer.phone?.includes(search) ||
              customer.address?.includes(search) 
            
              )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
:
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Id Client</TableCell>
      <TableCell className="filtertitle" align="left">Id Local</TableCell>
      <TableCell className="filtertitle" align="left">Produits Acheté</TableCell>
      <TableCell className="filtertitle" align="left">Paiements</TableCell>
      <TableCell className="filtertitle" align="left">est Facturé</TableCell>
      <TableCell className="filtertitle" align="left">Statut</TableCell>
      <TableCell className="filtertitle" align="left">Montant Payé</TableCell>
      <TableCell className="filtertitle" align="left">Montant Total</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {sellsHistory.filter(order => (
  order.customerId?.includes(search) || 
  order.localId?.includes(search) || 
  (order.total.toString())?.includes(search) || 
  (order.paidAmount.toString())?.includes(search) || 
  order.created_At?.includes(search)
  ))
.slice(page * 10, page * 10 + 10)
.map(order => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{order.created_At}</TableCell>
<TableCell align="left">{order.customerId}</TableCell>
<TableCell  align="left">{order.localId}</TableCell>
<TableCell align="left">{order.purchasedProducts.map((product)=>(
    <div key={product.productId}>
      {product.quantity}x {product.productId},Total:{product.total}<br/>

    </div>
))}</TableCell>
<TableCell align="left">{order.purchasePayments.map((paiement)=>(
    <div key={paiement.index}>
      <span className="blueText">Montant :</span>{paiement.amount} DH, <span className="blueText">Type :</span>{paiement.type}, {(paiement.image!=null && paiement.image!=="" )&& <a href={getSellPaymentImageURL(paiement.image)}  target="_blank" rel="noreferrer" >Image</a> }<br/>

    </div>
))}</TableCell>
{/* getEndPoint("images")+"/"+paiement.image */}
<TableCell align="left">{order.factured? 
<span>Facturé</span> :<span>Non Facturé</span> }</TableCell>
<TableCell align="left">{order.status}</TableCell>
<TableCell align="left">{order.paidAmount} DH</TableCell>
<TableCell align="left">{order.total} DH</TableCell>


</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            sellsHistory.filter(order => (
              order.customerId?.includes(search) || 
              order.localId?.includes(search) || 
              (order.total.toString())?.includes(search) || 
              (order.paidAmount.toString())?.includes(search) || 
              order.created_At?.includes(search)
              )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>
</TableContainer>
}
            
             </div>);
 
 };
 export default View;