import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Card,CardContent, Grid ,Typography,Button,TableContainer,Table
    ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
    Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import "./stock.css"


//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";

//Icons
import EditIcon from '@mui/icons-material/Edit';

//Modals
import EditStockModal from './components/EditStockModal'

import { getEndPoint, saveLogoutInLocalStorage } from "../../helpers/constants";
import { logout } from "../../redux/auth/authactions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const View= () => {
    //
    const [idProduct,setIdProduct]=useState("");

    //Agent Identification
    const decodedJWT=useSelector(state => state.decodedJWT );
    //Locals
    const [locals,setLocals]=useState([]);
    //Stock Table
    const [stock,setStock]=useState([]);
    const [page, setPage] = useState(0);
    const [search,setSearch]= useState("");
    const [stockCount,setStockCount]=useState(0);
    const handleChangePage = (event,newPage) => 
     {
         setPage(newPage);
     };

    // Stock Value 
     const [typeValue,setTypeValue]=useState("ALL");
     const [tissueValue,setTissuValue]=useState(0);
     const [woodValue,setWoodValue]=useState(0);
     const [epongeValue,setEpongeValue]=useState(0);
     const [accessoryValue,setAccessoryValue]=useState(0);
     const [otherValue,serOtherValue]=useState(0);
     const [piedsSalonValue,setPiedsSalonValue]=useState(0);
     const [smatiValue,setSmatiValue]=useState(0);
     const [lawatValue,setLawatValue]=useState(0);
     const [latoileValue,setLatoileValue]=useState(0);
   

  

     const showInvetoryValue=()=>{
        switch(typeValue)
        {
               case "ALL":
               return "Please Select Type";
               break;
               
               case "Tissu" : 
               return formatMoney(tissueValue);
               break;

               case "Bois" : 
               return formatMoney(woodValue);
               break;

               case "Eponge" : 
               return formatMoney(epongeValue);
               break;

               case "Accessoire" : 
               return formatMoney(accessoryValue);
               break;

               case "Autre" : 
               return formatMoney(otherValue);
               break;

               case "PiedsSalon" :
               return formatMoney(piedsSalonValue);
               break;

               case "Smati" :
               return formatMoney(smatiValue);
               break;

               case "Lawat" :
               return formatMoney(lawatValue);
               break;

               case "Latoile" :
               return formatMoney(latoileValue);
               break;
        }
     }

     const formatMoney= (val)=> {
      var parts = (val.toFixed(2)).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".")+" DH";
    
  }



    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };


    //Axios Requests
    const loadStock=async()=>{
        const endpoint=getEndPoint("/stockPageable?page="+page+"&type="+typeValue+"&id="+idProduct);
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
     
        });
        

        if(data!=null)
        {
            setStock((old) => data.data.content);
            setStockCount((old) => data.data.totalElements);

        }

    };
    const loadLocals= async() => {
      const endpoint=getEndPoint("/Local");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      setLocals(old => data.data);

    }
    const loadInventoryValue=async()=>{
      const endpoint=getEndPoint("/inventoryValue");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      setTissuValue(old => data.data.tissu);
      setWoodValue(old => data.data.bois);
      setEpongeValue(old => data.data.eponge);
      setAccessoryValue(old => data.data.accessoire);
      serOtherValue(old => data.data.autre);
      setPiedsSalonValue(old => data.data.piedsSalon);
      setSmatiValue(old => data.data.smati);
      setLawatValue(old => data.data.lawat);
      setLatoileValue(old => data.data.latoile);

    
      

  };

  
    useEffect(()=> {
        loadLocals();
        loadInventoryValue();
      },[]);

      useEffect(()=>{
        loadStock();
      },[page,typeValue,idProduct]);

       //Stock Edit Modal 
    const [stockModalIsOpen,setStockModalIsOpen]=useState(false);
    const [selectedStock,setSelectedStock]=useState({});
    const closeStockModal=()=>{
      setStockModalIsOpen(old => false);
      
    }
    const openStockModal=(stock) =>{
      setSelectedStock(stock);
      setStockModalIsOpen(true);
    }
    const handleStockUpdate= async ({stockId,prixAchat,prixVente})=>{

        const endpoint=getEndPoint("/stockUpdate?id="+stockId+"&prixAchat="+prixAchat+"&prixVente="+prixVente);
        const response = await axios.put(endpoint,{},headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        console.log("You're not allowed buddy");
         freeTokenFromSessionStorage();
           
       }
       console.log("Update Error Response Status :");
        console.log(error.response);
     
      });
      console.log("response :");
      console.log(response);
        await loadStock();
         
  
         closeStockModal();
 
       }
     
       
 


    return ( <div>
<EditStockModal  open={stockModalIsOpen} stock={selectedStock} handleClose={closeStockModal} handleUpdate={handleStockUpdate}  />

<Card className="searchCard" variant="outlined">

<CardContent>

<p  className="cornerText">Inventory Value : {showInvetoryValue()} </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">

<Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Nom Produit :
            </Typography>
            <MyInput value={idProduct} onChange={(event) => setIdProduct(event.target.value)} 
            placeholder="Nom Produit" 
          />
              </Grid>
   
      <Grid item>
      <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
      Type Produit :
    </Typography>
    <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={typeValue}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{
                    setPage(0);
                    setTypeValue(event.target.value);
                  }} 
                >  
                   <MenuItem  value={"ALL"}>ALL</MenuItem>           
                   <MenuItem  value={"Tissu"}>Tissu</MenuItem>
                   <MenuItem  value={"Bois"}>Bois</MenuItem>
                   <MenuItem  value={"Eponge"}>Eponge</MenuItem>
                   <MenuItem  value={"PiedsSalon"}>PiedsSalon</MenuItem>
                   <MenuItem  value={"Smati"}>Smati</MenuItem>
                   <MenuItem  value={"Lawat"}>Lawat</MenuItem>
                   <MenuItem  value={"Latoile"}>Latoile</MenuItem>
                   <MenuItem  value={"Accessoire"}>Accessoire</MenuItem>
                   <MenuItem  value={"Autre"}>Autre</MenuItem>
                   
                   
                </Select>
              </FormControl>
      </Grid>
    
  </Grid>
</CardContent>
</Card>
<br/>
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID Produit</TableCell>
      <TableCell className="filtertitle" align="left">Name</TableCell>
      <TableCell className="filtertitle" align="left">Type</TableCell>
      <TableCell className="filtertitle" align="left">Category</TableCell>
      <TableCell className="filtertitle" align="left">Couleur</TableCell>
      <TableCell className="filtertitle" align="left">Description</TableCell>
      <TableCell className="filtertitle" align="left">Gamme</TableCell>
      <TableCell className="filtertitle" align="left">Quantité</TableCell>
      <TableCell className="filtertitle" align="left">Quantité Max</TableCell>
      <TableCell className="filtertitle" align="left">Quantité Min</TableCell>
      <TableCell className="filtertitle" align="left">Solde</TableCell>
      <TableCell className="filtertitle" align="left">PrixAchat</TableCell>
      <TableCell className="filtertitle" align="left">PrixVente</TableCell>
      {decodedJWT.Role=="SuperAdmin" &&
                  <TableCell className="filtertitle" align="left">Edit</TableCell>
                   }
    </TableRow>
  </TableHead>

  <TableBody>
  {stock
.map(product => (
<TableRow
 key={product.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{product.id}</TableCell>
<TableCell align="left">{product.name}</TableCell>
<TableCell  align="left">{product.type}</TableCell>
<TableCell  align="left">{product.category}</TableCell>
<TableCell  align="left">{product.color}</TableCell>
<TableCell  align="left">{product.description}</TableCell>
<TableCell  align="left">{product.gamme}</TableCell>
<TableCell  align="left">
{locals.map(local =>(
                   <div>{local.id} : {product.quantity==null ? 0 :product.quantity[local.id] ? product.quantity[local.id] : 0} <br/></div>
                  )
                    )}
  {/* local 1 :{product.quantity?.l1}<br/>local 2 :{product.quantity?.l2}<br/>local 3 :{product.quantity?.l3} */}
  
  </TableCell>
<TableCell  align="left">{product.maxQuantity}</TableCell>
<TableCell  align="left">{product.minQuantity}</TableCell>
<TableCell  align="left">{product.discount}</TableCell>
<TableCell  align="left">{product.buy_price} DH</TableCell>
<TableCell  align="left">{product.sell_price} DH</TableCell>
{/* 
<TableCell align="left"><EditIcon onClick={() => {openUpdateModal(supplier)}}  className="editIcon"/></TableCell> */}
{decodedJWT.Role=="SuperAdmin" &&
<TableCell align="left"><EditIcon  className="editIcon" onClick={() => openStockModal(product)}/></TableCell>
}
</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={stockCount}
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
    </div>);
 
 };
 export default View;