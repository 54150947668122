import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel } from "@mui/material";

import React, { useState,useEffect } from "react";
import SmallInput from "../../../../globalComponents/SmallInput";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import "./supplierordermodal.css";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,supplierOrder,handleUpdate}) => {
  const [id,setId]=useState("")
 useEffect(
   () => {
    setId(old => supplierOrder.id);
   },
   [supplierOrder]
 );

    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Commande Fournisseur : {id} </DialogTitle>
        <DialogContent id="dialogContent" >
          <Grid style={{padding:10}} container spacing={1}>
            <Grid container  direction="row" justifyContent="center" alignItems="center">
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Voulez-vous supprimer la commande fournisseur : {id} ?
            </Typography>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {
            handleUpdate({
              idOrder:id,
            });
           }
            }>Supprimer</Button>
        </DialogActions>
      </Dialog>
    )
};
export default UpdateModal;
