import { Button } from "@mui/material";
import React from "react";
import "./tableselection.css";

const TableSelection=({firstSelection,secondSelection,isFirstSelection,change}) =>(
    <div style={{padding:5}}  >
        {isFirstSelection ? 
        <div>
        <Button id="leftpartD" onClick={change} className="clickedBlackOpacity">{firstSelection}</Button>
        <Button id="rightpartD" onClick={change} >{secondSelection}</Button>
        </div>
        :
         <div>
        <Button id="leftpartD" onClick={change} >{firstSelection}</Button>
        <Button id="rightpartD" className="clickedBlackOpacity" onClick={change} >{secondSelection}</Button> 
         </div>
         }


    </div>

);

export default TableSelection;