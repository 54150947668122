import { createStore } from "redux";
import reducer from "./globalReducer";
import authReducer from "./auth/authreducer";

//const store = createStore(authReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const getStore=()=>{
    if(localStorage.getItem("storeState")==null)
return createStore(authReducer,
    {        
        jwt:null,
        decodedJWT:null,
    }
    );
else
return createStore(authReducer,JSON.parse(localStorage.getItem("storeState")));
}
const store=getStore();
export default store;