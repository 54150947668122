
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
 const LoginRoutes =()=>(
  <Routes>
    <Route path="*"  element={<Login/>} />
    <Route path="/login" exact element={<Login/>} />
  </Routes>
);

export default LoginRoutes;
