import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
    Grid ,Typography } from "@mui/material";
  import React, { useState,useEffect } from "react";
  import "./index.css";

  
  import MyInput from "../../../../globalComponents/MyInput";
  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  const UpdateModal=({open,handleClose,customer,handleUpdate}) => {
  
    const [id,setId] = useState("");
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [address,setAddress] = useState("");
    const [type,setType] = useState("");
   


   
   useEffect(
     () => {
      setId(customer?.id)
      setName(customer?.name);
      setPhone(customer?.phone);
      setAddress(customer?.address);
      setType(customer?.type);
     },
     [customer]
   );

   
    
      const updatedData=() => {
  
        return {
        
           id:id,
           name:name,
           phone:phone,
           address:address,  
           type:type
        }
      }

      return (
          <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogTitle className="cornerText"> Modifier Client : {customer?.id} </DialogTitle>
          <DialogContent id="dialogContent" >
            <Grid style={{padding:10}} container spacing={1}>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                NomPrenom :
              </Typography>
              <MyInput value={name} onChange={(event) => setName(event.target.value)} 
              placeholder="NomPrenom" 
              />
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Numéro de téléphone :
              </Typography>
              <MyInput value={phone} onChange={(event) =>{setPhone(event.target.value)}} 
              placeholder="Numéro de téléphone" 
          />
                </Grid>
    
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Adresse :
              </Typography>
              <MyInput value={address} onChange={(event) =>{setAddress(event.target.value)}} 
              placeholder="Adresse" 
            />
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Type :
              </Typography>
              <MyInput value={type} onChange={(event) =>{setType(event.target.value)}} 
              placeholder="Type Client" 
            />
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
            <Button id="modifierButton" onClick={
             () => {
              handleUpdate({
                idCustomer:customer.id,
                data:updatedData(),
              });
             }
              }>Modifier</Button>
          </DialogActions>
        </Dialog>
      )
  };

  export default UpdateModal;
  