import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel, Table, TableContainer,
   TableRow, TableCell, TableBody, TableFooter, TablePagination,Paper,TableHead, Card, CardContent} from "@mui/material";

import React, { useState,useEffect } from "react";
import MyInput from "../../../../globalComponents/MyInput";

import "./productSearchModal.css";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({index,open,handleClose,products,handleSubmit}) => {

  const [page, setPage] = useState(0);
  const [search,setSearch]= useState("");
  const [stock,setStock]=useState([]);
  const handleChangePage = (event,newPage) => 
   {
       setPage(newPage);
   };
   useEffect(()=>{
     console.log("products list:");
     console.log(products);
     setStock(old => products);
   },[products]);

    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Recherche Produit  </DialogTitle>
        <DialogContent id="dialogContent" >
        <Card className="searchCard" variant="outlined">
          

        <CardContent>
    
        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
      />
              </Grid>
          </Grid>
      
        </CardContent>
        </Card>
        <br/> 
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID Produit</TableCell>
      <TableCell className="filtertitle" align="left">Name</TableCell>
      <TableCell className="filtertitle" align="left">Type</TableCell>
      <TableCell className="filtertitle" align="left">Category</TableCell>
      <TableCell className="filtertitle" align="left">Couleur</TableCell>
      <TableCell className="filtertitle" align="left">Description</TableCell>
      <TableCell className="filtertitle" align="left">Gamme</TableCell>
      <TableCell className="filtertitle" align="left">Quantité</TableCell>

    </TableRow>
  </TableHead>

  <TableBody>
  {stock
  .filter(
      
      product => (
        (product.id).toLowerCase()?.includes(search.toLowerCase()) || 
        (product.name).toLowerCase()?.includes(search.toLowerCase()) || 
        (product.type).toLowerCase()?.includes(search.toLowerCase()) ||
        (product.category).toLowerCase()?.includes(search.toLowerCase()) || 
        (product.color).toLowerCase()?.includes(search.toLowerCase()) || 
        (product.description).toLowerCase()?.includes(search.toLowerCase()) ||
        (product.gamme).toLowerCase()?.includes(search.toLowerCase()) 

  ))
.slice(page * 10, page * 10 + 10)
.map(product => (
<TableRow
 key={product.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
onClick={
  () => {
   handleSubmit({
     indexProduct:index,
     productID:product.id,
     product:product,
   });
   handleClose();
  }
   }
>
<TableCell component="th" scope="row" align="left">{product.id}</TableCell>
<TableCell align="left">{product.name}</TableCell>
<TableCell  align="left">{product.type}</TableCell>
<TableCell  align="left">{product.category}</TableCell>
<TableCell  align="left">{product.color}</TableCell>
<TableCell  align="left">{product.description}</TableCell>
<TableCell  align="left">{product.gamme}</TableCell>
<TableCell  align="left">local 1 :{product.quantity?.l1}<br/>local 2 :{product.quantity?.l2}<br/>local 3 :{product.quantity?.l3}</TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            stock.filter( product => (
                product.id?.includes(search) || 
                product.name?.includes(search) || 
                product.type?.includes(search) ||
                product.category?.includes(search) || 
                product.color?.includes(search) || 
                product.description?.includes(search) ||
                product.gamme?.includes(search) 
        
          )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
        </DialogContent>
      </Dialog>
    )
};
export default UpdateModal;
