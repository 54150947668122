import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./ChartSelect.css";
const ChartSelectMonth=({changeMonth,value}) =>{

 
  let [monthsList,setMonthsList]=useState([]);
  useEffect(()=>{
    let thisd = new Date();
    console.log("this Date :");
    console.log(thisd);
    thisd.setDate(1);
    console.log("After set Date:");
    console.log(thisd);
    let myDatesList=[];
    while(thisd.getFullYear()>=2021)
    {
      var myLocalDate=new Date(thisd);
      myDatesList.push(myLocalDate);
      thisd=new Date(thisd.setMonth(thisd.getMonth()-1));
    }
    console.log("Months List :");
    console.log(myDatesList);
    setMonthsList(myDatesList);
  },[]);
  
    //const [year,setYear]=useState(0);
      return (
        <FormControl style={{padding:5}}  >
         
            <Select className="chartSelectPart"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
               value={value}
              onChange={(event) =>{
             
                changeMonth(event.target.value,monthsList[event.target.value]);
               // setYear(event.target.value);
              }}
            >
         
              {
                monthsList.map((date,index) => 
                  <MenuItem value={index}>{date.getFullYear()}-{date.getMonth()+1}</MenuItem>
                  )
              
              }
          
        
            </Select>
          </FormControl>);

}
export default ChartSelectMonth;