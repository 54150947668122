import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
  Card,CardContent, Grid ,Typography,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel,TextareaAutosize } from "@mui/material";



  import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  


import React, { useState,useEffect } from "react";
import "./index.css";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,rendezVous,handleUpdate}) => {


  const [idRendezVous,setIdRendezVous]=useState(null);
  const [commentaire,setCommentaire]=useState("");
  const [dateTimeRendezVous,setDateTimeRendezVous]=useState(null);


 

 useEffect(
   () => {
    setCommentaire(old =>rendezVous.commentaire);
    setIdRendezVous(old =>rendezVous.id);

   },
   [rendezVous]
 );



 

 
  
 
  // const freeTokenFromSessionStorage = () => {
  //   dispatch(logout);
  //   saveLogoutInLocalStorage();
  //   history("/login");
  // };
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Modifier Rendez-Vous du client : {rendezVous.name} </DialogTitle>
        <DialogContent id="dialogContent" >
          <Grid style={{padding:10}} container spacing={1}>
              <Grid item xs={12} md={12}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Date/Heure :
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                           <DateTimePicker format="DD-MM-YY hh-mm a" value={dateTimeRendezVous} onChange={(value) => setDateTimeRendezVous(old => value)} />
                        </DemoContainer>
                        </LocalizationProvider>
              </Grid>
        
              <Grid item xs={12} md={12}>
                    <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             Commentaire
            </Typography>
                    <TextareaAutosize value={commentaire} onChange={(input) =>{setCommentaire(old => input.target.value)}} style={{width:"100%", height:"120px"}} />
                    </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {
            handleUpdate({
              idRendezVous:idRendezVous,
              commentaire:commentaire,
              localDateTime:dateTimeRendezVous,
              
            });
           }
            }>Modifier</Button>
        </DialogActions>
      </Dialog>
    )
};
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};
const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

export default UpdateModal;
