import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./ChartSelect.css";
const ChartSelectYear=({changeYear,value}) =>{

  const thisd = new Date();
  let thisyear = thisd.getFullYear();
  let [yearsList,setYearsList]=useState([]);
  useEffect(()=>{
    let myYearsList=[];
    var myYear,i;
    for(myYear=thisyear-1,i=1;myYear>=2021;myYear--,i++)
    {
      myYearsList.push({
        index:i,
        value:myYear,
      });

    }
    setYearsList(myYearsList);
  },[]);
  
    //const [year,setYear]=useState(0);
      return (
        <FormControl style={{padding:5}}  >
         
            <Select className="chartSelectPart"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
               value={value}
              onChange={(event) =>{
                
                changeYear(event.target.value);
               // setYear(event.target.value);
              }}
            >
              <MenuItem value={0}>Année en cours</MenuItem>
              {
                yearsList.map(year => 
                  <MenuItem value={year.index}>{year.value}</MenuItem>
                  )
              
              }
          
        
            </Select>
          </FormControl>);

}
export default ChartSelectYear;