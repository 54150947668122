import { Button } from "@mui/material";
import React from "react";
import "./tableselection.css";

const TableSelection=({isCustomerTable,changeDisplayedTable}) =>(
    <div className="TableSelectionPositioning">
        {isCustomerTable ? 
        <div>
        <Button id="leftpart" onClick={changeDisplayedTable} className="clickedBlackOpacity"> Clients</Button>
        <Button id="rightpart" onClick={changeDisplayedTable} >Historique</Button>
        </div>
        :
         <div>
        <Button id="leftpart" onClick={changeDisplayedTable} >Clients</Button>
        <Button id="rightpart" className="clickedBlackOpacity" onClick={changeDisplayedTable} >Historique</Button> 
         </div>
         }


    </div>

);

export default TableSelection;