//Mui , hooks imports
import { Button, Card, CardContent, FormControl,
   Grid, InputLabel, MenuItem, Select, Table, TableBody,
    TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
    Paper,TableContainer,Divider,Container, CircularProgress
  } from "@mui/material";
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import Checkbox from '@mui/material/Checkbox';



//Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';


//Css
import "./achat.css";

//Axios Calls
import { getEndPoint, getInvoiceImageURL, getPurchasePaymentImageURL,getSupplierOrderPaymentImageURL, saveLogoutInLocalStorage, getCompressedImage } from "../../helpers/constants";
import axios from "axios";
import { logout } from "../../redux/auth/authactions";

//Modals
import UpdateModal from "./components/UpdatedModal";
import SupplierOrderModal from "./components/UpdateSupplierOrderModal";
import ProductSearchModal from "./components/ProductSearchModal";
import DeleteModal from "./components/DeleteModal";

//My Costum inputs
  import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";

//Others 
import { useNavigate } from "react-router-dom";
import TableSelection from "./components/TableSelection";
import InvoiceImageModal from "./components/InvoiceImageModal";
import { CheckBox } from "@mui/icons-material";
import { color } from "@mui/system";




const View= () => {
   //Dev part

  //  const makeOrderPending= async(id)=>{
  //   var formData=new FormData();
  //   formData.append("id",id);
  //   const endpoint=getEndPoint("/supplierOrderDoPending");
  //   const response = await axios.post(endpoint,formData,headers).catch(function (error) {
  //     if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {

  //       freeTokenFromSessionStorage();
       
  //     }
 
  //   });

  //   if(response.data!=null)
  //   {
  //     loadSupplierOrders();
  //   }

  //  }


   //Agent Identification
   const decodedJWT=useSelector(state => state.decodedJWT );
  //Locals
  const [locals,setLocals]=useState([]);
    //Supplier Table 
   const [isSupplierTable,setisSupplierTable]=useState(true);
   const [suppliers,setSuppliers]=useState([]);
   const [page, setPage] = useState(0);
   const [search,setSearch]= useState("");
   const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };
    const changeDisplayedTable=() =>{
      setisSupplierTable((old) => !old);
    }
    //DeleteModal 
    const [deleteModalIsOpen,setDeleteModalIsOpen]=useState(false);
    const [selectedDeleteOrder,setSelectedDeleteOrder]=useState({});
    const closeDeleteModal=()=>{
      setDeleteModalIsOpen(old => false);
      
    }
    const openDeleteModal=(order) =>{
      setSelectedDeleteOrder(order);
      setDeleteModalIsOpen(true);
    }
 
     
    const handleOrderDelete= async ({idOrder})=>{
      const endpoint=getEndPoint("/deleteSupplierOrder?id="+idOrder);
      const response = await axios.delete(endpoint,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
       freeTokenFromSessionStorage();
     }
    });
      await loadSupplierOrders();
      closeDeleteModal();

     }

    //SupplierOrder edit modal
    const [orderModalIsOpen,setOrderModalIsOpen]=useState(false);
    const [selectedOrder,setSelectedOrder]=useState({});
    const closeOrderModal=()=>{
      setOrderModalIsOpen(old => false);
      
    }
    const openOrderModal=(order) =>{
      setSelectedOrder(order);
      setOrderModalIsOpen(true);
    }
    const openInvoiceModal= async(type,image) =>{
      let endpoint;
      if(type=="bon")
      {
         endpoint=getInvoiceImageURL(image);

      }
      else
      {
        endpoint=getSupplierOrderPaymentImageURL(image);


      }
     
      setInvoiceImage(endpoint);
      setInvoiceModalIsOpen(true);
    }
    const handleOrderUpdate= async ({idOrder,data})=>{
 
     const myData=new FormData();
     myData.append("file",data.file);
     myData.append("notPaid",data.notPaid);
     myData.append("id",idOrder);
     
 
      const endpoint=getEndPoint("/supplierOrders");
      const response = await axios.put(endpoint,myData,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     
       freeTokenFromSessionStorage();
          
     }
 
    
    });
 
      await loadSupplierOrders();
        
 
       closeOrderModal();

     
    
      
        
    }
    //Table Invoice Modal
    const [invoiceModalIsOpen,setInvoiceModalIsOpen]=useState(false);
    const [invoiceImage,setInvoiceImage]=useState("");
    const closeInvoiceModal=()=>{
      setInvoiceModalIsOpen(old => false);
    }
    //Table edit modal
    const [modalIsOpen,setmodalIsOpen]=useState(false);
    const [selectedSupplier,setSelectedSupplier]=useState({});
    const closeUpdateModal=()=>{
      setmodalIsOpen(olde => false);
      
    }
    const openUpdateModal=(supplier) =>{
      setSelectedSupplier(supplier);
      setmodalIsOpen(true);
    }

     const handleUpdate= async ({idSupplier,data})=>{

      
      const endpoint=getEndPoint("/suppliers");
      const response = await axios.put(endpoint+"?id="+idSupplier,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
   
      });
    
      await loadSuppliers();
       

      closeUpdateModal();
      
        
    }
    //Product Search Modal
    const [idFournisseurFilter,setIdFournisseurFilter]=useState("");
    const [statusFilter,setStatusFilter] = useState("");
    const changeStatusFilter=(status) => 
    {
          if(statusFilter==status)
          {
            setStatusFilter((old) => "");
   
          }
          else
          {
            setStatusFilter((old) => status); 
       
          }
    }
    const [productInputIndex,setProductInputIndex]=useState(null);
    const [productModalIsOpen,setProductModalIsOpen]=useState(false);
    const closeProductModal=()=>{
      setProductModalIsOpen(old => false);
      
    }
    const openProductModal=(index) =>{
      setProductModalIsOpen(old => true);
      setProductInputIndex(index);
    }

     const handleProductModalSubmit= async ({indexProduct,productID,product})=>{
      setBoughtProducts((old) => {
        let mylist=[...old];
         
        mylist[indexProduct].productId=productID;
        mylist[indexProduct].found=true;
        mylist[indexProduct].buy_price=product.buy_price;
        if( mylist[indexProduct].quantity!="")
        mylist[indexProduct].total=mylist[indexProduct].buy_price*mylist[indexProduct].quantity;
        else
        mylist[indexProduct].total=null;
       
        calculateTotal(mylist);
        return mylist;

      });

      //closeUpdateModal();
      closeProductModal();
      
        
    }

    //Supplier
    const [id,setId] = useState("");  
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [address,setAddress] = useState("");

    //Product
    const [product,setProduct]=useState({});
    const [idProduct,setIdProduct]=useState("");
    const [nameProduct,setNameProduct]=useState("");
    const [typeProduct,setTypeProduct]=useState("");
    const [categoryProduct,setCategoryProduct]=useState("");
    const [colorProduct,setColorProduct]=useState("");
    const [descriptionProduct,setDescriptionProduct]=useState("");
    const [gammeProduct,setGammeProduct]=useState("");
    const [sellPriceProduct,setSellPriceProduct]=useState("");
    const [buyPriceProduct,setBuyPriceProduct]=useState("");
    //Achat 
    const [supplierOrders,setSupplierOrders]=useState([]);
    const [idFournisseurAchat,setIdFournisseurAchat]=useState("");
    const [idLocalAchat,setIdLocalAchat]=useState("");
    const [boughtProducts,setBoughtProducts]=useState(
      [
      {
        found:null,
        index:0,
        productId:"",
        quantity:"",
        buy_price:null,
        total:null,
      }
    ]);
    const [productIndex,setProductIndex]=useState(1);
    //Order Information
    const [isPaid,setIsPaid]=useState("NotPaid");
    const [totalPayment,setTotalPayment]=useState(null);
    const [factureOrBonImage,setFactureOrBonImage]=useState(null);
    // const [paymentsList,setPaymentsList]=useState(
    //   [
    //   {
    //     index:0,
    //     amount:"",
    //     type:"virement",
    //     image:null,
    //   }
    // ]);
   // const [paymentIndex,setPaymentIndex]=useState(1);

    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
      
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };

    //Methods

    const debugfillingProduct= () => {
        setIdProduct("J35489");
        setNameProduct("Name1");
        setTypeProduct("Type1");
        setCategoryProduct("Cate1");
        setColorProduct("RED");
        setDescriptionProduct("eropgkerg erpogkerg");
        setGammeProduct("Gamme1");
        setSellPriceProduct("10");
        setBuyPriceProduct("20");

    };
    const debugfilling = () => {
        setId("AA892186");
        setName("Hamza Supplier");
        setAddress("Agdal Okba")
        setPhone("+212682767885");
        
  
      };
      const debugfillingAchat = () => {
        setIdFournisseurAchat("AA892186");
     

        
  
      };
    //Achat Methods 
    const setBoughtProduct=async ({index,value}) =>{
      const endpoint=getEndPoint("/product?productid="+value);
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      
          freeTokenFromSessionStorage();
         
        }
   
      });
      const found = (data!=null);
      setBoughtProducts((old) => {
        let mylist=[...old];
         
        mylist[index].productId=value;
        mylist[index].found=found;
        if(found)
        {
          mylist[index].buy_price=data.data.buy_price;
          if( mylist[index].quantity!="")
          mylist[index].total=mylist[index].buy_price*mylist[index].quantity;
          else
          mylist[index].total=null;
        }
        else
        mylist[index].total=null;
        calculateTotal(mylist);
        return mylist;

      });
  
  

    }
    const setBoughtProductQuantity=({index,value})=>{
      setBoughtProducts((old) => {
        let mylist=[...old];
        mylist[index].quantity=value;
        if(mylist[index].found)
        {
          mylist[index].total=mylist[index].buy_price*mylist[index].quantity;
        }
        else
        mylist[index].total=null;
        calculateTotal(mylist);
        return mylist;

      });
      

    }
    const addProduct= ()=>{
      
      setBoughtProducts( old =>
       {
         let mylist=[...old];
    
         mylist.push(
          {          
            found:null,
            index:productIndex,
            productId:"",
            quantity:"",
            buy_price:null,
            total:null,
          }
         );
      
         calculateTotal(mylist);
       return mylist;

      }
      );
      setProductIndex(old => old+1);

      
    };
    const removeProduct=()=>{
      if(productIndex>1)
      {
      setBoughtProducts(
        (old) =>{
          let mylist=[...old];
          mylist.pop();
          calculateTotal(mylist);
          return mylist;
        }
      );
      setProductIndex(old => old-1);
      }

    };
    //Payment Infos
    const calculateTotal=(list) =>
    {
      let total=0;
    
      for (var i = 0; i < list.length; i++) {
        if(list[i].total==null || isNaN(list[i].total) )
        {
          total=null;
          break;
        }
        else
          total+=list[i].total;
      }
      setTotalPayment(old => total);
      return total;

    }
    // const calculateTotalPayment=() => {
    //   let total=0;
    //   paymentsList.map(payment=> {
    //     total+=parseFloat(payment.amount);
    //   });
    //   return total;
    // }
    // const setPaymentAmount=({index,value})=>{
    //   setPaymentsList((old) => {
    //     let mylist=[...old];
    //     mylist[index].amount=value;
    //     return mylist;

    //   });

    // }
    // const setPaymentType=({index,value})=>{
    //   setPaymentsList((old) => {
    //     let mylist=[...old];
    //     mylist[index].type=value;
    //     return mylist;

    //   });
    // }
    // const setPaymentImage=({index,value})=>{
  
    //   setPaymentsList((old) => {
    //     let mylist=[...old];
    //     mylist[index].image=value;
    //     return mylist;

    //   });

    // }
 

    // const addPayment= () => {
    //   setPaymentsList(old =>
    //     {
    //       let mylist=[...old];
    //       mylist.push(
    //         {
    //           index:paymentIndex,
    //           amount:"",
    //           type:"virement",
    //           image:null,
    //         }
    //       );
    //     return mylist;

    //     });
    //     setPaymentIndex(old => old+1);
    

    // }
    // const removePayment=()=>{
    //   if(paymentIndex>1)
    //   {
    //     setPaymentsList(
    //     (old) =>{
    //       let mylist=[...old];
    //       mylist.pop();
    //       return mylist;
    //     }
    //   );
    //   setPaymentIndex(old => old-1);
    //   }
 
    // };

    //Axios Calls 
    const [stock,setStock]=useState([]);
    const loadStock=async()=>{
      const endpoint=getEndPoint("/stock");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      
          freeTokenFromSessionStorage();
         
        }
   
      });
      setStock(old => data.data);

  };
  const formatMoney= (val)=> {
    var parts = (val.toFixed(2)).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".")+" DH";
  
}
    const loadSupplierOrders=async()=>{
      const endpoint=getEndPoint("/supplierOrders");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setSupplierOrders(old => data.data);
    }
    const loadSuppliers= async() => {
      const endpoint=getEndPoint("/suppliers");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setSuppliers(old => data.data);

    }
    const createSupplier= async() => {
        const data={
            id:id,
            name:name,
            phone:phone,
            address:address,
          }
          
          const endpoint=getEndPoint("/suppliers");
          const response = await axios.post(endpoint,data,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              freeTokenFromSessionStorage();
             
            }
         
       
          });
         // Load Suppliers for table await loadUsers();
         if(response!=null)
         {
          clearSupplierInput();
          loadSuppliers();
         }
        
     
         
    };
    const clearSupplierInput=()=>{
      setId("");
      setName("");
      setPhone("");
      setAddress("");
  

    }
    const createProduct= async() => {
      const data={
        id:idProduct,
        name:nameProduct,
        type:typeProduct,
        category:categoryProduct,
        color:colorProduct,
        description:descriptionProduct,
        gamme:gammeProduct,
        sell_price:sellPriceProduct,
        buy_price:buyPriceProduct,

      }
      const endpoint=getEndPoint("/stock");
      const response = await axios.post(endpoint,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     
          freeTokenFromSessionStorage();
         
        }
   
      });
      if(response!=null)
      clearCreateProductInput();
    }
    const clearCreateProductInput=()=>{
      setIdProduct("");
      setNameProduct("");
      setTypeProduct("");
      setCategoryProduct("");
      setColorProduct("");
      setDescriptionProduct("");
      setGammeProduct("");
      setSellPriceProduct("");
      setBuyPriceProduct("");
    }
    const [buyOrderClicked,setBuyOrderClicked]=useState(false);
  
    const createBuyOrder= async()=>{
      console.log("total payment :"+totalPayment);
     if(!buyOrderClicked && totalPayment!=null)
     {
      setBuyOrderClicked(old => true);
      
      let formData=new FormData();
      let data={
         supplierid:idFournisseurAchat,
         localId:idLocalAchat,
         agentId:null,
          total:totalPayment,
         //paidAmount:null,
         status:null,
         factured:false,
         isPaid:isPaid,
      
        purchasedProducts:[],
        //purchasePayments:[]
      };
   
 
   


  
    
      boughtProducts.map((product)=>{

       
        data.purchasedProducts.push(
          {
          productId:product.productId,
          quantity:product.quantity,
          buy_price:product.buy_price,
          total:product.total,
          }
        );

      });
      // let paidamountVariable=0;

      // paymentsList.map((payment)=>{
      //   formData.append("files",payment.image);
   
      //   paidamountVariable+=parseFloat(payment.amount);

      //   data.purchasePayments.push(payment);
        
      // });
      // data.paidAmount=paidamountVariable;

      if(isPaid=="NotPaid")
      {
      data.status="En Cours";

      }
      else
      {
      data.status="Complet";
      }
    //  data.purchasePayments.map((purchasepayment)=>
    //        {
    //     if(purchasepayment.image==null)
    //     purchasepayment.image="";
    //     else
    //     purchasepayment.image="Found";
    //   } 
    //     );
      formData.append("givendata",JSON.stringify(data));
      var compressedImage=null;
      if(factureOrBonImage!=null)
      {
         compressedImage=await getCompressedImage(factureOrBonImage);
      }
      formData.append("factureBonImage",compressedImage);

   const endpoint=getEndPoint("/PurchaseProducts");
      const response = await axios.post(endpoint,formData,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {

          freeTokenFromSessionStorage();
         
        }
   
      });
      if(response!=null)
      clearBuyOrder();
      loadSupplierOrders();
      setBuyOrderClicked(old => false);

    }

    }

    const clearBuyOrder=() => {
      setIdFournisseurAchat("");
      setIsPaid(
        old => "NotPaid"
      );


      setBoughtProducts([
        {
          found:null,
          index:0,
          productId:"",
          quantity:"",
          buy_price:null,
          total:null,
        }
      ]);
      setProductIndex(1);
      // setTotalPayment(null);
      // setPaymentsList([
      //   {
      //     index:0,
      //     amount:"",
      //     type:"virement",
      //     image:null,
      //   }
      // ]);
      // setPaymentIndex(1);
      setFactureOrBonImage(null);
    }
    const [uploadFactureBonClicked,setUploadFactureBonClicked]=useState(false);
    const uploadFactureBon= async({orderId,image}) => {
      if(!uploadFactureBonClicked)
      {
        setUploadFactureBonClicked(old => true);


        let formData=new FormData();
        formData.append("orderId",orderId);
        var compressedImage=null;
        if(image!=null)
        {
          compressedImage=await getCompressedImage(image);
        }
        formData.append("image",compressedImage);
        const endpoint=getEndPoint("/uploadFactureBon");
        const response = await axios.post(endpoint,formData,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            freeTokenFromSessionStorage();
          
          }
    
        });
        loadSupplierOrders();

        setUploadFactureBonClicked(old => false);
      }

      


    }
    //Loads 
    const loadLocals= async() => {
      const endpoint=getEndPoint("/Local");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setLocals(old => data.data);

    }
    useEffect(()=>{
      loadStock();
      loadSuppliers();
      loadSupplierOrders();
      loadLocals();
    },[]);
    useEffect(()=>{
      console.log("Decoded JWT :");
      console.log(decodedJWT);
      if(decodedJWT.Role!="SuperAdmin")
      setIdLocalAchat(decodedJWT.localId);
   
     },[decodedJWT]);



    ///Mass Payment Data
     
    const calculateTotalPayment= ()=>
    {
      var totalPayment=0.0;

      supplierOrders.filter(order => (
        order.status?.includes(statusFilter) &&
        order.supplierid?.includes(idFournisseurFilter)
        )).map(
          (element) =>{
            totalPayment+=element.total;
          }
        )
      

      return totalPayment;
    }


    const [massPaymentClicked,setMassPaymentClicked]=useState(false);
    const [massPaymentImage,setMassPaymentImage]=useState(null);
    const executeMassPayment= async() =>
    {
      var totalPayment=calculateTotalPayment();

     
      if(!massPaymentClicked && totalPayment>0 && massPaymentImage!=null)
      {
        setMassPaymentClicked(old => true);
       
  
       const myData=new FormData();
       var ordersList=supplierOrders.filter(order => (
        order.status?.includes(statusFilter) &&
        order.supplierid?.includes(idFournisseurFilter)
        ));
      
        console.log("orders list before sending");
        console.log(ordersList);
        var compressedImage=null;
        if(massPaymentImage!=null)
        {
           compressedImage=await getCompressedImage(massPaymentImage);
        }
       myData.append("file",compressedImage);
       myData.append("orders",JSON.stringify(ordersList));
        myData.append("total",totalPayment.toFixed(2));

    const endpoint=getEndPoint("/supplierMassPayment");
       const response = await axios.post(endpoint,myData,headers).catch(function (error) {
         if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
 
           freeTokenFromSessionStorage();
          
         }
    
       });
       if(response!=null)
       {
        console.log(response.data);
       }
       loadSupplierOrders();
       setMassPaymentClicked(old => false);

     }
    

    }



    return ( <div>
      <DeleteModal open={deleteModalIsOpen} supplierOrder={selectedDeleteOrder} handleClose={closeDeleteModal} handleUpdate={handleOrderDelete} />
<ProductSearchModal open={productModalIsOpen} index={productInputIndex} products={stock} handleClose={closeProductModal} handleSubmit={handleProductModalSubmit}  />

        <UpdateModal  open={modalIsOpen} supplier={selectedSupplier} handleClose={closeUpdateModal} handleUpdate={handleUpdate}  />
        <InvoiceImageModal  open={invoiceModalIsOpen} img={invoiceImage} handleClose={closeInvoiceModal}   />
      
        <SupplierOrderModal  open={orderModalIsOpen} supplierOrder={selectedOrder} handleClose={closeOrderModal} handleUpdate={handleOrderUpdate}  />
      <Card className="creationCard" variant="outlined">
<CardContent>
          <p onClick={debugfillingProduct} className="cornerText">Nouveau Produit </p>
         
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID : 
            </Typography>
            <MyInput value={idProduct} onChange={(event) => setIdProduct(event.target.value)} 
            placeholder="ID" 
        />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Nom Produit :
            </Typography>
            <MyInput value={nameProduct} onChange={(event) => setNameProduct(event.target.value)} 
            placeholder="Nom Produit" 
          />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Type Produit :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={typeProduct}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setTypeProduct(event.target.value)}} 
                >             
                   <MenuItem  value={"Tissu"}>Tissu</MenuItem>
                   <MenuItem  value={"Bois"}>Bois</MenuItem>
                   <MenuItem  value={"Eponge"}>Eponge</MenuItem>
                   <MenuItem  value={"PiedsSalon"}>PiedsSalon</MenuItem>
                   <MenuItem  value={"Smati"}>Smati</MenuItem>
                   <MenuItem  value={"Lawat"}>Lawat</MenuItem>
                   <MenuItem  value={"Latoile"}>Latoile</MenuItem>
                   <MenuItem  value={"Accessoire"}>Accessoire</MenuItem>
                   <MenuItem  value={"Autre"}>Autre</MenuItem>
                </Select>
              </FormControl>
          
              </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Category Produit :
            </Typography>
            <MyInput value={categoryProduct} onChange={(event) =>{setCategoryProduct(event.target.value)}} 
            placeholder="Category Produit" 
           />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Couleur Produit :
            </Typography>
            <MyInput value={colorProduct} onChange={(event) =>{setColorProduct(event.target.value)}} 
            placeholder="Couleur Produit" 
         />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Description Produit :
            </Typography>
            <MyInput value={descriptionProduct} onChange={(event) =>{setDescriptionProduct(event.target.value)}} 
            placeholder="Description Produit" 
      />
              </Grid>
             </Grid>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Gamme Produit :
            </Typography>
            <MyInput value={gammeProduct} onChange={(event) =>{setGammeProduct(event.target.value)}} 
            placeholder="Gamme Produit" 
          />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Prix Achat:
            </Typography>
            <MyInput value={buyPriceProduct} onChange={(event) =>{setBuyPriceProduct(event.target.value)}} 
            placeholder=" Prix Achat" 
     />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Prix Vente:
            </Typography>
            <MyInput value={sellPriceProduct} onChange={(event) =>{setSellPriceProduct(event.target.value)}} 
            placeholder=" Prix Vente" 
     />
              </Grid>
              </Grid>
           <br/>
           <br/>
              <Button onClick={createProduct} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button>

    
          
          
        </CardContent>
        </Card>
        <br/>
        <Grid container spacing={1}>
            <Grid item md={6}>
            <Card className="creationCard" variant="outlined">
       
<CardContent >
          <p onClick={debugfilling} className="cornerText">Nouveau Fournisseur </p>
      
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID : 
            </Typography>
            <MyInput value={id} onChange={(event) => setId(event.target.value)} 
            placeholder="ID" 
    />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              NomPrenom :
            </Typography>
            <MyInput value={name} onChange={(event) => setName(event.target.value)} 
            placeholder="NomPrenom" 
      />
              </Grid>
              </Grid>
     
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Numéro de téléphone :
            </Typography>
            <MyInput value={phone} onChange={(event) =>{setPhone(event.target.value)}} 
            placeholder="Numéro de téléphone" 
         />
              </Grid>
  
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Adresse :
            </Typography>
            <MyInput value={address} onChange={(event) =>{setAddress(event.target.value)}} 
            placeholder="Adresse" 
        />
              </Grid>
              </Grid>
              <br/>
           

    
       
          <Button onClick={createSupplier} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button> 
        
        </CardContent>
      
    
        </Card>   
 
  
            </Grid>
            <Grid item md={6}>
            <Card className="creationCard" variant="outlined">
<CardContent>
          <p onClick={debugfillingAchat} className="cornerText">Achat Produit</p>

          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
      
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID Fournisseur :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Fournisseur</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="ID Fournisseur"
                  value={idFournisseurAchat}
                  className="selectStyle"
                  onChange={(event) => setIdFournisseurAchat(event.target.value)}
                >
                 {suppliers.map(supplier => (
                   <MenuItem key={supplier.id} value={supplier.id}>{supplier.id}</MenuItem>
                 ))}
                </Select>
              </FormControl>
             </Grid>
             {decodedJWT.Role=="SuperAdmin" ?
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  onChange={(event)=> {setIdLocalAchat((old)=> (event.target.value))}}
                >
             {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
             </Grid>
              :
              null
             }
          </Grid>
        
          <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
          <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Produits 
            </Typography>
            </Grid>
            </Grid>
      
          {boughtProducts.map((product)=>
            <Grid key={product.index} container direction="row" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID Produit :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={(product.productId==="")? null : product.found}  value={product.productId} onChange={(event) =>  setBoughtProduct({index:product.index,value:event.target.value})} 
              placeholder="ID Produit" />
               <span style={{"width":"2px"}}/>
              <Button variant="contained" onClick={() => openProductModal(product.index)} ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
              {product.total!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                  {isNaN(product.total)?
                   <Typography className="errorlittleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Quantité invalide !
                   </Typography>
                  :
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                    Total : {product.total} DH
                  </Typography>
                  }
                  
                  </Grid>
                  </Grid>
           
                  
               }
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Quantity : 
              </Typography>
              <MyInput value={product.quantity} onChange={(event) => setBoughtProductQuantity({index:product.index,value:event.target.value})} 
              placeholder="Quantity" 
             />
            </Grid>
            </Grid>
          )}
          
          <br/>
            <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addProduct} fontSize="large" className="addProductIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removeProduct} fontSize="large" className="removeProductIconContainer" />
              </Grid>
            </Grid>
            <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Informations de commande 
            </Typography>
            </Grid>
            </Grid>
            {(totalPayment!==null &&  idFournisseurAchat!="" && idLocalAchat!="") && 
              <Container className="orderInfoContainer" >
              <Grid container  spacing={0}>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Total :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                    <Grid item>
                <Typography className="bigTitle" sx={{ fontSize: 14 }} gutterBottom>
                 {totalPayment} DH
                </Typography>
                </Grid>
                </Grid>

                </Grid>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Facture/Bon  :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                    <Grid item>
                    <Button
                        variant="contained"
                        component="label" 
                         className={factureOrBonImage!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setFactureOrBonImage(old => event.target.files[0])}
                        />
                      </Button>
              
                </Grid>
                </Grid>

                </Grid>
                <Grid md={12} xs={12} item>
                <br/>
                </Grid>
                
                <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                <Grid item>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Payée  (Caisse) :
                </Typography>
                </Grid>
                <Grid item>
                <Checkbox
                      checked={isPaid=="PaidCashRegister"}
                      
                      onChange={(event)=>{
                        console.log(event.target.checked);
                        if(event.target.checked)
                        {
                          setIsPaid(
                            old => "PaidCashRegister"
                          );
                        }
                        else
                        {
                          setIsPaid(
                            old => "NotPaid"
                          );
                        }
                    
                     

                      }}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />
                    </Grid>
                
                </Grid>
                

    

              </Grid>
              
            </Container>
            }
          
             


             <br/>
             <br/>
             {buyOrderClicked ?
                 <Button color={"warning"} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
                 Valider
               </Button> 
               :
               <Button onClick={createBuyOrder} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
               Valider
             </Button> 
            
            }
                
        </CardContent>
        </Card>   
            </Grid>
        </Grid>
        <br/>
{/* Search Part */}
<Card className="searchCard" variant="outlined">
<TableSelection  changeDisplayedTable={changeDisplayedTable} isSupplierTable={isSupplierTable}/>
  
        {isSupplierTable ?  <CardContent>
          <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
      />
              </Grid>
          </Grid>
        </CardContent> : <CardContent>
        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            
          <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID Fournisseur :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Fournisseur</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="ID Fournisseur"
                  value={idFournisseurFilter}
                  className="selectStyle"
                  onChange={(event) => setIdFournisseurFilter(event.target.value)}
                >
                 {suppliers.map(supplier => (
                   <MenuItem key={supplier.id} value={supplier.id}>{supplier.id}</MenuItem>
                 ))}
                </Select>
              </FormControl>
              </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Statut :
              </Typography>
              <Grid  container spacing={5} >
              <Grid item  >
                {statusFilter==="Complet" ? 
              <Button onClick={() => {changeStatusFilter("Complet")}} className="myCompletedButton">Complet</Button>:
              <Button onClick={() => {changeStatusFilter("Complet")}} className="myCompletedButtonDisabled">Complet</Button>  
              }
            
            </Grid>
            <Grid item  >
            {statusFilter==="En Cours" ? 
              <Button onClick={() => changeStatusFilter("En Cours")} className="myProcessingButton">En cours</Button>:
              <Button onClick={() => changeStatusFilter("En Cours")} className="myProcessingButtonDisabled">En cours</Button>  
              }
           
            </Grid>
            <Grid item   >
            {statusFilter==="Rejetée" ? 
              <Button onClick={() => changeStatusFilter("Rejetée")} className="myFailedButton">Rejetée</Button>:
              <Button onClick={() => changeStatusFilter("Rejetée")} className="myFailedButtonDisabled">Rejetée</Button>  
              }
      
            </Grid>
          </Grid>
              </Grid>
          </CardContent>}

       
      
    </Card>


    <br/>
{/* Table Part */}
{isSupplierTable ? 
  <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">id Fournisseur</TableCell>
      <TableCell className="filtertitle" align="left">NomPrenom</TableCell>
      <TableCell className="filtertitle" align="left">Numéro de téléphone</TableCell>
      <TableCell className="filtertitle" align="left">Adresse</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {suppliers.filter(supplier => (
  supplier.id?.includes(search) || 
  supplier.name?.includes(search) || 
  supplier.phone?.includes(search) ||
  supplier.address?.includes(search) 

  ))
.slice(page * 10, page * 10 + 10)
.map(supplier => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{supplier.id}</TableCell>
<TableCell align="left">{supplier.name}</TableCell>
<TableCell  align="left">{supplier.phone}</TableCell>
<TableCell align="left">{supplier.address}</TableCell>
<TableCell align="left"><EditIcon onClick={() => {openUpdateModal(supplier)}}  className="editIcon"/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            suppliers.filter(supplier => (
              supplier.id?.includes(search) || 
              supplier.name?.includes(search) || 
              supplier.phone?.includes(search) ||
              supplier.address?.includes(search) 
            
              )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
:
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Id Fournisseur</TableCell>
      <TableCell className="filtertitle" align="left">Id Local</TableCell>
      <TableCell className="filtertitle" align="left">Produits Acheté</TableCell>
      <TableCell className="filtertitle" align="left">Statut</TableCell>
      <TableCell className="filtertitle" align="left">Montant Total</TableCell>
      <TableCell className="filtertitle" align="left">Facture/Bon</TableCell>
      <TableCell className="filtertitle" align="left">Payment Img</TableCell>
      {decodedJWT.Role=="SuperAdmin" &&
      <TableCell className="filtertitle" align="left">Edit</TableCell>
        }
      {decodedJWT.Role=="SuperAdmin" &&
                  <TableCell className="filtertitle" align="left">Delete</TableCell>
                   }
                            {/* <TableCell className="filtertitle" align="left">MakePending</TableCell> */}
    </TableRow>
  </TableHead>

  <TableBody>
  {supplierOrders.filter(order => (
  order.status?.includes(statusFilter) &&
  order.supplierid?.includes(idFournisseurFilter)
  ))
.slice(page * 10, page * 10 + 10)
.map(order => (
<TableRow
 key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{order.created_At}</TableCell>
<TableCell align="left">{order.supplierid}</TableCell>
<TableCell  align="left">{order.localId}</TableCell>
<TableCell align="left">{order.purchasedProducts.map((product)=>(
    <div key={product.productId}>
      {product.quantity}x {product.productId},Total:{product.total}<br/>

    </div>
))}</TableCell>
{/* getEndPoint("images")+"/"+paiement.image */}

<TableCell align="left"> {order.status==="En Cours" ? 
    <Button disabled={true} className="myProcessingButton">{order.status}</Button>
    :
    order.status==="Complet" ?
    <Button disabled={true} className="myCompletedButton">{order.status}</Button>
    :
    <Button disabled={true} className="myFailedButton" >{order.status}</Button>
    }</TableCell>
<TableCell align="left">{formatMoney(order.total)}</TableCell>
<TableCell align="left">
  {order.factureBonImage!=null? 
  <Button className={"invoiceButtonDone"} onClick={() => openInvoiceModal("bon",order.factureBonImage)} ><SearchIcon /></Button>
   :
   
    uploadFactureBonClicked? 
    <CircularProgress/>
    :
 
   <Button 
   variant="contained"
   component="label" 
   className={"invoiceButton"}>
    <UploadIcon/>
       <input
      accept="image/*"
        type="file"
        hidden
        onChange={(event) => uploadFactureBon({orderId:order.id,image:event.target.files[0]})}
      />
   
    </Button>
   }</TableCell>
   <TableCell align="left">
  {order.paymentImage!=null? 
  <Button className={"invoiceButtonDone"} onClick={() => openInvoiceModal("payment",order.paymentImage)} ><SearchIcon /></Button>
   :
  <div/>
   }</TableCell>
{decodedJWT.Role=="SuperAdmin" &&
<TableCell align="left"><EditIcon  className="editIcon" onClick={() => openOrderModal(order)}/></TableCell>
}
{decodedJWT.Role=="SuperAdmin" &&
                  <TableCell align="left">
                    <DeleteIcon  className="deleteIcon" 
                     onClick={() => openDeleteModal(order)}
                    />
                    </TableCell>
                   }
                    {/* <TableCell align="left">
                    <RemoveIcon  className="deleteIcon" 
                     onClick={() => makeOrderPending(order.id)}
                    />
                    </TableCell> */}
</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            supplierOrders.filter(order => (
              order.status?.includes(statusFilter) &&
              order.supplierid?.includes(idFournisseurFilter)
              )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>
</TableContainer>
}
<br/>
{
  (idFournisseurFilter!="" && statusFilter=="En Cours" && decodedJWT.Role=="SuperAdmin") &&
  <Card   variant="outlined" className="searchCard">
    <CardContent>
    <p  className="cornerText">Paiement de masse pour le fournisseur : {idFournisseurFilter}</p>
    <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            
          <Typography className="cornerText" sx={{ fontSize: 14 }} gutterBottom>
          montant total à payer :     <span style={{color:"#FFC107",fontSize:30}}>{formatMoney(calculateTotalPayment())}</span>
            </Typography>
           
              </Grid>
              <Grid item xs={6} md={6}>
              <Typography className="cornerText" sx={{ fontSize: 14 }} gutterBottom>
          Justificatif    : <span>   <Button
                        variant="contained"
                        component="label" 
                         className={massPaymentImage!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setMassPaymentImage(old => event.target.files[0])}
                        />
                      </Button></span>
            </Typography>
           
                      </Grid>
              <Grid item xs={12} md={12}>
              {massPaymentClicked ?
                 <Button color={"warning"} className={"normalButton"} variant="contained" >
                 Valider
               </Button> 
               :
               <Button onClick={executeMassPayment} className={"massPaymentButton"} variant="contained" >Payer</Button>
       
            
            }
             
                </Grid>

              </Grid>

    </CardContent>
  </Card>
}
    </div>);
 };
 export default View;