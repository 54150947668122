

import jwt_decode from 'jwt-decode';
import imageCompression from 'browser-image-compression';

//ec2-15-188-50-161.eu-west-3.compute.amazonaws.com
//http://ec2-15-188-50-161.eu-west-3.compute.amazonaws.com
//http://localhost:80
//https://engine.atwabassalam.com

var publicIp="http://35.180.139.72:8080";
var local="http://localhost:80";


var backendDomain="https://engine.atwabassalam.com";
// var backendDNS="ec2-13-37-42-208.eu-west-3.compute.amazonaws.com";


export const  getDomain=() =>
{
     return backendDomain;
}
export const getImageURL=(imageName)=>{
    return getDomain()+"/images/"+imageName;

}
export const getSupplierOrderPaymentImageURL= (imageName) => {
    return getDomain()+"/images/supplierOrderPayments/"+imageName;
}
export const getSellPaymentImageURL= (imageName) => {
    return getDomain()+"/images/sellsPayments/"+imageName;
}
export const getInvoiceImageURL= (imageName) => {
    return getDomain()+"/images/facturesBons/"+imageName+".png";
}
export const getMarketPaymentImageURL= (imageName) => {
    return getDomain()+"/images/marketsPayments/"+imageName;
}
export const getPurchasePaymentImageURL= (imageName) => {
    return getDomain()+"/images/purchasesPayments/"+imageName;
}
export const getEndPoint= (url) =>
{
    return getDomain()+url;
}

export const saveLogoutInLocalStorage = () => {
    const data = JSON.parse(localStorage.getItem("storeState"));
    const updateddata={
        ...data,
        jwt:null,
        decodedJWT:null,
    };
    localStorage.setItem("storeState",JSON.stringify(updateddata));
    console.log("local storage :")
    console.log(JSON.parse(localStorage.getItem("storeState")));
  };
export const saveLoginInLocalStorage = (givenjwt) => {
    const data = JSON.parse(localStorage.getItem("storeState"));
    const updateddata={
        ...data,
        jwt:givenjwt,
        decodedJWT:jwt_decode(givenjwt),
    };
    localStorage.setItem("storeState",JSON.stringify(updateddata));
    console.log("local storage :")
    console.log(JSON.parse(localStorage.getItem("storeState")));
  };
  export const generateArrayOfYears=() =>{
    var max=new Date().getFullYear();
    var min=max-70;
    var years =[]
    for(let i=max;i>=min;i--)
    {
        years.push(i);
    }
    return years;
}
export const generateArrayOfMonths=()=>([
    {number:1,name:"janvier"},
    {number:2,name:"février"},
    {number:3,name:"mars"},
    {number:4,name:"avril"},
    {number:5,name:"mai"},
    {number:6,name:"juin"},
    {number:7,name:"juillet"},
    {number:8,name:"août"},
    {number:9,name:"septembre"},
    {number:10,name:"octobre"},
    {number:11,name:"novembre"},
    {number:12,name:"décembre "},

]);
export const generateDays=()=>
{
    var myarray=[];
    for(let i=1;i<=31;i++)
    {
        myarray.push(i);
    }
    return myarray;

}

//Classes
export class DecodedJwt {
    constructor(Role,exp,iat,iss,localId,sub){
        this.Role=Role;
        this.exp=exp;
        this.iat=iat;
        this.iss=iss;
        this.localId=localId;
        this.sub=sub;
    }

    
}
export const getCompressedImage= async(imageFile) =>
{
    const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(compressedFile.size/1024/1024);
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
}


