import {AUTHENTICATE,LOGOUT} from "./authtypes"
import jwt_decode from 'jwt-decode';
const initialState = {
    jwt :null,
} 

const authReducer= (state=initialState ,action) => {
   switch(action.type)
   {
       case AUTHENTICATE:
           return {
                   jwt:action.payload,
                   decodedJWT:jwt_decode(action.payload),
           };
        case LOGOUT:
            return {        
                    jwt:null,
                    decodedJWT:null,
            };
            default:
                return state;
   }
}
export default authReducer;