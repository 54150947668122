

import MyRoutes from "./routes";
import "./index.css";

function App() {
  
  return (
  <MyRoutes />
  );
}

export default App;
