import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Card ,Typography,CardContent, Grid} from "@mui/material";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, saveLogoutInLocalStorage } from "../../helpers/constants";
import Chart from "react-apexcharts";
import axios from "axios";
import TableSelection from "./TableSelection";
import ChartSelectTime from "./ChartSelectTime";
import ChartSelectLocal from "./ChartSelectLocal";
import ChartSelectMonth from "./ChartSelectMonth";
import {  Button,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper} from "@mui/material";
import ChartSelectYear from "./ChartSelectYear";
import { minHeight } from "@mui/system";
const View= () => {
   //Agent Identification
   const decodedJWT=useSelector(state => state.decodedJWT );


    

    //Ranking products
    const [rankingList,setRankingList]=useState([]);
   
    const tableSize=10;
    const [page, setPage] = useState(0);
        const [productsCount,setProductsCount]=useState(0);
    const handleChangePage = (event,newPage) => 
     {
         setPage(newPage);
     };

    // Revenue 
        const [dayRevenue,setDayRevenue]=useState(0);
        const [dayOrders,setDayOrders]=useState(0);
        const [weekRevenue,setWeekRevenue]=useState(0);
        const [weekOrders,setWeekOrders]=useState(0);
        const [monthRevenue,setMonthRevenue]=useState(0);
        const [monthOrders,setMonthOrders]=useState(0);



        //Products Use
       const [aquiredUsedProductsList,setAquiredUsedProductsList]=useState([]);
       const [boughtUsedProductsList,setBoughtUsedProductsList]=useState([]);
       const [boughtNotUsedProductsList,setBoughtNotUsedProductsList]=useState([]);
       const calculateProductsListTotal=(list)=>
       {
        var total=0.0;
        list.map((element)=>
        {
          total+=element.total;

        });
        console.log("Calculation Result");
        console.log(total);
        return total;

       }

          //Table Filter
            const [tableDataType,setTableDataType]=useState("Acquis");
            const changeTable=(status) => 
            {
              setTableDataType((old) => status);   
            }
            const getTableData=()=>
            {
              "Acquis - Acheté & Utilisé - Acheté & Non Utilisé"
              if(tableDataType=="Acquis")
              {
                return aquiredUsedProductsList;

              }
              else if(tableDataType=="Acheté & Utilisé")
              {
                return boughtUsedProductsList;

              }
              else
              {
                return boughtNotUsedProductsList;

              }

            }

       //BAR CHART 
       const [week,setWeek]=useState(0);
       const [year,setYear]=useState(0);
       const [month,setMonth]=useState({
        index:0,
        value:new Date(),
       });
       const [barChartLocal,setBarChartLocal]=useState("ALL");
       useEffect(()=>{
        if(decodedJWT.Role=="SuperAdmin")
        setBarChartLocal("ALL");
        else
        setBarChartLocal(decodedJWT.localId);
   

       },[decodedJWT])
       const [locals,setLocals]=useState([]);
       const changeWeek=(week)=>
       {
        setWeek(week);
        console.log("Week :");
        console.log(week);

       }
       const changeYear=(year)=>{
        setYear(year)
       }
       const changeMonth=(index,chosenMonth) =>{
        console.log("Chosen Date");
        console.log(chosenMonth);
        setMonth({
          index:index,
          month:chosenMonth 
        });
       }
       const changeLocal=(local)=>{
        setBarChartLocal(local);
       }
       const [weekBarChart,setWeekBarChart]=useState([]);
       const [yearBarChart,setYearBarChart]=useState([]);
       const [ordersCostYearBarChart,setOrdersCostYearBarChart]=useState([]);
       const [ordersGainYearBarChart,setOrdersGainYearBarChart]=useState([]);
       const [ordersGainRatioYearBarChart,setOrdersGainRatioYearBarChart]=useState([]);
       const [isOrderGainRatio,setIsOrderGainRatio]=useState(false);
       const changeIsOrderGainRatio=() =>{
        setIsOrderGainRatio((old) => !old);
       }
       const [costsYearBarChart,setCostsYearBarChart]=useState([]);
       const [pendingYearBarChart,setPendingYearBarChart]=useState([]);
       const [gainBarChart,setGainBarChart]=useState([]);
    
        // JWT and  freesession
        const history=useNavigate();
        const jwt=useSelector(state => state.jwt);
        const dispatch=useDispatch();
        const headers = { headers: {
            "Authorization" : `Bearer ${jwt}`,
          
      
          } }
        const freeTokenFromSessionStorage = () => {
            dispatch(logout);
            saveLogoutInLocalStorage();
            history("/login");
          };
        //Methods 
       const formatMoney= (val)=> {
          var parts = (val.toFixed(0)).toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          return parts.join(".")+" DH";
        
      }
      const formatQuantity=(val)=>{
        return val.toFixed(2);
     

      }
      const [isWeekChart,setIsWeekChart]=useState(true);
      const changeDisplayedChart=() =>{
        setIsWeekChart((old) => !old);
       }
        //Axios Calls


        //Loads
        //Load Products Use
        const loadProductsUseByMonth=async()=>{
          let endpoint=getEndPoint("/productsUseByMonth?year="+month.month.getFullYear()+"&month="+(month.month.getMonth()+1)+"&local="+barChartLocal);    
          let response= await axios.get(endpoint,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              console.log("You're not allowed buddy");
              freeTokenFromSessionStorage();
             
            }
       
          });
          if(response.data!=null)
          {
            setAquiredUsedProductsList(old => response.data.existingUsedProducts);
            setBoughtUsedProductsList(old => response.data.purchasedUsedProducts);
            setBoughtNotUsedProductsList(old => response.data.purchasedNotUsedProducts);
            console.log("Utilisation Produits :");
            console.log(response.data);
          }
    

        }

        //Load Barchart
        const loadWeekBarChart=async()=>{
          let endpoint=getEndPoint("/WeekBarChart?week="+week+"&local="+barChartLocal);    
          let response= await axios.get(endpoint,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              console.log("You're not allowed buddy");
              freeTokenFromSessionStorage();
             
            }
       
          });
          if(response.data!=null)
          setWeekBarChart(response.data)
    

        }
        const loadYearBarChart=async()=>{
          let costs=[];
          let ca=[];
          let ordersCosts=[];
          //CA PART
          let endpoint=getEndPoint("/YearBarChart?year="+year+"&local="+barChartLocal);    
          let response= await axios.get(endpoint,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              console.log("You're not allowed buddy");
              freeTokenFromSessionStorage();
             
            }
       
          });
          if(response.data!=null)
          setYearBarChart(response.data.totalRevs);
          setOrdersCostYearBarChart(response.data.totalCosts);
          ordersCosts=response.data.totalCosts;
          console.log("BarChartYear:");
          console.log(response.data);
          ca=response.data.totalRevs;
          //Costs Part 
           endpoint=getEndPoint("/CostsYearBarChart?year="+year+"&local="+barChartLocal);    
           response= await axios.get(endpoint,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              console.log("You're not allowed buddy");
              freeTokenFromSessionStorage();
             
            }
       
          });
          if(response.data!=null)
          setCostsYearBarChart(response.data);
          console.log("CostsYearBarchart:");
          console.log(response.data);
          costs=response.data;

          //Gain Part
          let ordersGain=[];
          for(let i=0;i<ca.length;i++)
          {
            ordersGain[i]=ca[i]-ordersCosts[i];
            if(ordersGain[i]<0)
            ordersGain[i]=0;

          }
          setOrdersGainYearBarChart(ordersGain);
          //OrdersGain Part
          let d=[];
          for(let i=0;i<ca.length;i++)
          {
            d[i]=ca[i]-costs[i];
            if(d[i]<0)
            d[i]=0;

          }
          setGainBarChart(d);
          //OrdersGain Ratio
          let ordersGainRatio=[];
          for(let i=0;i<ca.length;i++)
          {
            if(ordersCosts[i]!=0)
            {
              ordersGainRatio[i]=((ca[i]/ordersCosts[i])-1)*100;
            }
            else
            {
              ordersGainRatio[i]=0;
            }
           
          }
          setOrdersGainRatioYearBarChart(old => ordersGainRatio);

          //Pending 
           endpoint=getEndPoint("/PendingYearBarChart?year="+year+"&local="+barChartLocal);    
           response= await axios.get(endpoint,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              console.log("You're not allowed buddy");
              freeTokenFromSessionStorage();
             
            }
       
          });
          if(response.data!=null)
          setPendingYearBarChart(response.data);
          console.log("BarChartYear:");
          console.log(response.data);
        

        }
        // const loadCostsYearBarChart=async()=>{
        //   const endpoint=getEndPoint("/CostsYearBarChart?year="+year+"&local="+barChartLocal);    
        //   const response= await axios.get(endpoint,headers).catch(function (error) {
        //     if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        //       console.log("You're not allowed buddy");
        //       freeTokenFromSessionStorage();
             
        //     }
       
        //   });
        //   if(response.data!=null)
        //   setCostsYearBarChart(response.data);
        //   console.log("CostsYearBarchart:");
        //   console.log(response.data);
        //   return response.data;

        // }
        
        //

        const loadRevenues= async() => {
            // Day Rev Request
            let endpoint="";
            if(decodedJWT.Role=="SuperAdmin")
            endpoint=getEndPoint("/revenue?numberOfDays="+1+"&localid=all");    
            else
            endpoint=getEndPoint("/revenue?numberOfDays="+1+"&localid="+decodedJWT.localId);  
            let response= await axios.get(endpoint,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                console.log("You're not allowed buddy");
                freeTokenFromSessionStorage();
               
              }
         
            });
            let data=response.data;
            setDayRevenue(old => data.totalRevenue );
            setDayOrders(old => data.totalOrders);

            //Week Rev Request
            if(decodedJWT.Role=="SuperAdmin")
            endpoint=getEndPoint("/revenue?numberOfDays="+7+"&localid=all");    
            else
            endpoint=getEndPoint("/revenue?numberOfDays="+7+"&localid="+decodedJWT.localId);  
        
             response= await axios.get(endpoint,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                console.log("You're not allowed buddy");
                freeTokenFromSessionStorage();
               
              }
         
            });
             data=response.data;
            setWeekRevenue(old => data.totalRevenue );
            setWeekOrders(old => data.totalOrders);
            // Month rev Request
            if(decodedJWT.Role=="SuperAdmin")
            endpoint=getEndPoint("/revenue?numberOfDays="+30+"&localid=all");    
            else
            endpoint=getEndPoint("/revenue?numberOfDays="+30+"&localid="+decodedJWT.localId);  
        
             response= await axios.get(endpoint,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                console.log("You're not allowed buddy");
                freeTokenFromSessionStorage();
               
              }
         
            });
             data=response.data;
            setMonthRevenue(old => data.totalRevenue);
            setMonthOrders(old => data.totalOrders);
           
          }
          const loadGlobalRankings=async ()=> {
            let endpoint=getEndPoint("/productSellsGlobalRanking?size="+tableSize+"&page="+page);
            let response= await axios.get(endpoint,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                console.log("You're not allowed buddy");
                freeTokenFromSessionStorage();
               
              }
         
            });   
            if(response.data!=null)
            {
            setRankingList(response.data.content);
            setProductsCount((old) => response.data.totalElements);
          }
           
          
          }
          //Load Locals 
          const loadLocals= async() => {
            const endpoint=getEndPoint("/Local");
            const data= await axios.get(endpoint,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                console.log("You're not allowed buddy");
                freeTokenFromSessionStorage();
               
              }
         
            });
            setLocals(old => data.data);
      
          }
          useEffect(()=>{
            loadYearBarChart();
           
           
          },[year,barChartLocal]);
          useEffect(()=>{
            loadWeekBarChart();
          },[week,barChartLocal]);
          useEffect(()=> {
            loadRevenues();
            loadGlobalRankings();
            loadLocals();
          },[decodedJWT]);
          useEffect(()=> {
            loadProductsUseByMonth();
          },[month,barChartLocal]);
          


   
    return ( <div>     <Grid  container spacing={3} >
        <Grid  className="myGrid" item xs={12} md={4}  >
          
        <Card  className="firstCardContent parent" >
        <CardContent  >
              <SellOutlinedIcon  className="icon"></SellOutlinedIcon>
               <div className="topleftalignment" >
                 
               <Typography   sx={{ fontSize: 14 }} color="white" gutterBottom>
               Revenue dernière 24H
               </Typography>
               <Typography variant="h5" color="white" component="div">
                 {formatMoney(dayRevenue)}
          
               </Typography>
               </div>
               
               
                <div className="bottomrightalignment">
               <Typography sx={{fontSize: 11, mb: 1.5 }} color="white">
                 Nombre d'ordre   {dayOrders}
             
               </Typography>
               </div>
              
       </CardContent>
     
         </Card>
       </Grid>
       <Grid  className="myGrid" item  xs={12} md={4}  >
       <Card  className="secondCardContent parent">
        <CardContent>
        <SellOutlinedIcon  className="icon"></SellOutlinedIcon>
        <div className="topleftalignment" >
        <Typography sx={{ fontSize: 14 }} color="white"gutterBottom>
                 Revenue dernière 7 jours
               </Typography>
               <Typography variant="h5" color="white" component="div">
             {formatMoney(weekRevenue)}
            
               </Typography>
               </div>
               <div className="bottomrightalignment">
               <Typography sx={{fontSize: 11, mb: 1.5 }} color="white">
                 Nombre d'ordre   {weekOrders}
               
               </Typography>
             
                 </div>
              
          
             </CardContent>
             
         </Card>
       </Grid>
       <Grid  className="myGrid"item xs={12} md={4}>
       <Card className="thirdCardContent parent" >
        <CardContent>
        <SellOutlinedIcon  className="icon"></SellOutlinedIcon>
        <div className="topleftalignment">
        <Typography sx={{ fontSize: 14 }} color="white" gutterBottom>
        Revenue dernier mois
               </Typography>
               <Typography variant="h5" color="white" component="div">
                 {formatMoney(monthRevenue)}
             
               </Typography>
               </div>
               <div className="bottomrightalignment">
               <Typography sx={{fontSize: 11, mb: 1.5 }} color="white">
                 Nombre d'ordre  {monthOrders}
             
               </Typography>
               </div>
         
        
             </CardContent>
             
         </Card>
       </Grid>
     
         </Grid>
         <br/>
         
         { isWeekChart ? 
         
         <Grid  container className="barChartContainer" >
           {decodedJWT.Role=="SuperAdmin" ?
              <Grid item md={12} xs={12} >
              <Grid container  >
                <Grid item md={4} xs={6}  >
                  <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
                  <ChartSelectTime changeWeek={changeWeek} />
                  </Grid>
                  
              </Grid>
                <Grid item md={4} xs={6} >
                <Grid container direction="row" justifyContent={{md:"center",xs:"right"}} >
                <ChartSelectLocal locals={locals} changeLocal={changeLocal}/>

                  </Grid>
                
                </Grid>
                <Grid item md={4} xs={6} >
                <Grid container direction="row" justifyContent={{md:"right",xs:"left"}} >
                <TableSelection firstSelection={"Semaine"} secondSelection={"Année"} change={changeDisplayedChart} isFirstSelection={isWeekChart}/>
                
                  </Grid>
                
                </Grid>
              </Grid>

              </Grid>
           :
               <Grid item md={12} xs={12} >
              <Grid container  >
                <Grid item md={6} xs={6}  >
                  <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
                  <ChartSelectTime changeWeek={changeWeek} />
                  </Grid>
                  
              </Grid>
             
                <Grid item md={6} xs={6} >
                <Grid container direction="row" justifyContent={{md:"right",xs:"right"}} >
                <TableSelection firstSelection={"Semaine"} secondSelection={"Année"} change={changeDisplayedChart} isFirstSelection={isWeekChart}/>
                
                  </Grid>
                
                </Grid>
              </Grid>

              </Grid>
         

    

            }
            <Grid item md={12} xs={12}>
              <br/>
            </Grid>
           <Grid item md={12} xs={12} >
           <Grid container direction="row" justifyContent="center">
           <Grid item className="normaltext">
            
            Chiffre d'affaires journalier (DH)
     
         </Grid>
           </Grid>
           </Grid>
             <Grid item md={12} xs={12}>
             <Chart
                  
                     options={{  
      
                      chart: {
                        offsetY: 30
                    },
                    dataLabels: {
                      offsetY: -20,
                      style:{
                        colors:["#808080"]
                      },
                      formatter: function (val, opts) {
                        if(val==0)
                        return "";
                        var parts = (val.toFixed(0)).toString().split(".");
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        return parts.join(".")+" DH";
                      
                    },
                    },
                    plotOptions: {
                      bar: {
                        dataLabels: {
                          position: 'top',
                          textAnchor: 'end',
                        },
                     
                       
                      },
    
               
                    },
              
    
                  
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return (value.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" DH";
                        }
                      },
                    },
                    xaxis: {
                      categories: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
                    }}}
                     series={    
                      [ {
                      name: "series-1",
                      data: weekBarChart,
                    }]}
                    
                     type="bar"
                    //  width="100%"
                     height="360px"
                   />
             </Grid>
         
          
               </Grid>
         :
         <Grid  container className="barChartContainer" >
          
            {decodedJWT.Role=="SuperAdmin" ?
            <Grid item md={12} xs={12} >
               <Grid container  >
              <Grid item md={4} xs={6}  >
              <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
              <ChartSelectYear changeYear={changeYear} value={year} />
              </Grid>
              </Grid>
              <Grid item md={4} xs={6}  >
              <Grid container direction="row" justifyContent={{md:"center",xs:"right"}} >
              <ChartSelectLocal locals={locals} changeLocal={changeLocal}/>
              </Grid>
              </Grid>
              <Grid item md={4} xs={6}  >
              <Grid container direction="row" justifyContent={{md:"right",xs:"left"}} >
              <TableSelection firstSelection={"Semaine"} secondSelection={"Année"} change={changeDisplayedChart} isFirstSelection={isWeekChart}/>
               
             
              </Grid>
              </Grid>
               
                </Grid>
             
            </Grid>
          
          :
          <Grid item md={12} xs={12} >
          <Grid container  >
         <Grid item md={6} xs={6}  >
         <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
         <ChartSelectYear changeYear={changeYear} value={year} />
         </Grid>
         </Grid>

         <Grid item md={6} xs={6}  >
         <Grid container direction="row" justifyContent={{md:"right",xs:"right"}} >
         <TableSelection firstSelection={"Semaine"} secondSelection={"Année"} change={changeDisplayedChart} isFirstSelection={isWeekChart}/>
               
         
         </Grid>
         </Grid>
          
           </Grid>
        
       </Grid>
          }
            

            <Grid item md={12} xs={12}>
              <br/>
            </Grid>
           <Grid item md={12} xs={12} >
           <Grid container direction="row" justifyContent="center">
           <Grid item className="normaltext">
            
           Chiffre d'affaires mensuel (DH)
     
         </Grid>
           </Grid>
           </Grid>
       
             <Grid item md={12} xs={12}>
          
             <Chart 
                  
                  options={{  
   
                   chart: {
                     offsetY: 30
                 },
                 dataLabels: {
                   offsetY: -20,
                   style:{
                     colors:["#808080"]
                   },
                   formatter: function (val, opts) {
                    if(val==0)
                    return "";
                     var parts = (val.toFixed(0)).toString().split(".");
                     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                     return parts.join(".")+" DH";
                   
                 },
                 },
                 plotOptions: {
                   bar: {
                     dataLabels: {
                       position: 'top',
                       textAnchor: 'end',
                     },
                  
                    
                   },
 
            
                 },
           
 
               
                 yaxis: {
                   labels: {
                     formatter: function (value) {
                       return (value.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" DH";
                     }
                   },
                 },
                 xaxis: {
                   categories: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
                 }}}
                  series={    
                   [ {
                   name: "C.A",
                   data: yearBarChart,
                 },
                 {
                   name: "Gain",
                   data: gainBarChart,
                 },
                 {
                   name: "Charges",
                   data: costsYearBarChart,
                 },
                 {
                   name: "Non.P",
                   data: pendingYearBarChart,
                 },
                 
               
                 ]}
                 
                  type="bar"
                  // width="100%"
                  height="360px"
                />
          
            
             </Grid>
         
          
               </Grid>
         
         }
         
               <br/>
               <Grid  container className="barChartContainer" >
          
          {decodedJWT.Role=="SuperAdmin" ?
          <Grid item md={12} xs={12} >
             <Grid container  >
            <Grid item md={4} xs={6}  >
            <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
            <ChartSelectYear changeYear={changeYear} value={year} />
            </Grid>
            </Grid>
            <Grid item md={4} xs={6}  >
            <Grid container direction="row" justifyContent={{md:"center",xs:"right"}} >
            <ChartSelectLocal locals={locals} changeLocal={changeLocal}/>
            </Grid>
            </Grid>
            <Grid item md={4} xs={12} >
       <Grid container direction="row" justifyContent={{md:"right",xs:"left"}} >
     
              <TableSelection firstSelection={"Total/Cout"} secondSelection={"Benefice %"} change={changeIsOrderGainRatio} isFirstSelection={!isOrderGainRatio}/>
             </Grid>
              
              </Grid>
      
             
              </Grid>
           
          </Grid>
        
        :
        <Grid item md={12} xs={12} >
        <Grid container  >
       <Grid item md={6} xs={6}  >
       <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
       <ChartSelectYear changeYear={changeYear} value={year} />
       </Grid>
     
       </Grid>
       <Grid item md={6} xs={6} >
       <Grid container direction="row" justifyContent={{md:"right",xs:"right"}} >
     
              <TableSelection firstSelection={"Total/Cout"} secondSelection={"Benefice %"} change={changeIsOrderGainRatio} isFirstSelection={!isOrderGainRatio}/>
             </Grid>
              
              </Grid>

    
        
         </Grid>
      
     </Grid>
        }
          

          <Grid item md={12} xs={12}>
            <br/>
          </Grid>
         <Grid item md={12} xs={12} >
         <Grid container direction="row" justifyContent="center">
         
          {isOrderGainRatio?
          <Grid item className="normaltext">
            Pourcentage moyen des gains des commandes (%)
          </Grid>
          :
          <Grid item className="normaltext">
          Total/Cout commandes mesuel (DH)
        </Grid>

        }
         
   
      
         </Grid>
         </Grid>
     
           <Grid item md={12} xs={12}>
            {isOrderGainRatio? 
             <Chart 
                
             options={{  
              data:{hamza:2},
              chart: {
                offsetY: 30
            },
            dataLabels: {
              offsetY: -20,
              style:{
                colors:["#808080"]
              },
              formatter: function (value) {
         
                return ((value).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" %";
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  position: 'top',
                  textAnchor: 'end',
                },
             
               
              },

       
            },
      

          
            yaxis: {
              labels: {
                formatter: function (value) {
                  return ((value).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" %";
                }
              },
            },
            xaxis: {
              categories: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
            }}}
             series={    
              [ {
              name: "Gain Pourcentage",
              data: ordersGainRatioYearBarChart,
            },      
          
            ]}
            
             type="bar"
            //  width="100%"
             height="360px"
           />
            :
            <Chart 
                
            options={{  
              data:{hamza:1},
             chart: {
               offsetY: 30
           },
           dataLabels: {
             offsetY: -20,
             style:{
               colors:["#808080"]
             },
             formatter: function (val, opts) {
              if(val==0)
              return "";
               var parts = (val.toFixed(0)).toString().split(".");
               parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
               return parts.join(".")+" DH";
             
           },
           },
        
           plotOptions: {
             bar: {
               dataLabels: {
                 position: 'top',
                 textAnchor: 'end',
               },
            
              
             },

      
           },
     

         
           yaxis: {
             labels: {
               formatter: function (value) {
                 return (value.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" DH";
               }
             },
           },
           xaxis: {
             categories: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
           },
      
          }}
            series={    
             [ {
             name: "Total",
             data: yearBarChart,
           },
           {
             name: "Cout",
             data: ordersCostYearBarChart,
           },
           {
             name: "Gain",
             data: ordersGainYearBarChart,
           },
         
           
         
           ]}
           
            type="bar"
            // width="100%"
            height="360px"
          />
            }
       
        
          
           </Grid>
       
        
             </Grid>
               <br/>
            

               <Grid  container className="barChartContainer" >
               {decodedJWT.Role=="SuperAdmin" ?
          <Grid item md={12} xs={12} >
             <Grid container  >
            <Grid item md={6} xs={6}  >
            <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
            <ChartSelectMonth changeMonth={changeMonth} value={month.index} />
            </Grid>
            </Grid>
            <Grid item md={6} xs={6}  >
            <Grid container direction="row" justifyContent={{md:"right",xs:"right"}} >
            <ChartSelectLocal locals={locals} changeLocal={changeLocal}/>
            </Grid>
            </Grid>
     
      
             
              </Grid>
           
          </Grid>
        
        :
        <Grid item md={12} xs={12} >
        <Grid container  >
       <Grid item md={6} xs={6}  >
       <Grid container direction="row" justifyContent={{md:"left",xs:"left"}} >
       <ChartSelectMonth changeMonth={changeMonth} value={month.index} />
       </Grid>
     
       </Grid>


    
        
         </Grid>
      
     </Grid>
        }
            <Grid item md={12} xs={12}>
            <br/>
          </Grid>
          <Grid item md={12} xs={12} >
         <Grid container direction="row" justifyContent="center">
         
         <Grid item className="normaltext">
            Utilisation Des Produits 
          </Grid>
         
   
      
         </Grid>
         </Grid>
          <Grid item md={12} xs={12}>
            <br/>
          </Grid>
          <Grid item md={6} xs={12} style={{padding:20}} >

          <Chart  style={{padding:18}}
         
         options={{  

          chart: {
            offsetY: 30
        },
        dataLabels: {
          offsetY: -20,
          style:{
            colors:["#808080"]
          },
          formatter: function (val, opts) {
            if(val==0)
            return "";
            var parts = (val.toFixed(0)).toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(".")+" DH";
          
        },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
              textAnchor: 'end',
            },
         
           
          },

   
        },
  

      
        yaxis: {
          labels: {
            formatter: function (value) {
              return (value.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" DH";
            }
          },
        },
        xaxis: {
          categories: ["Acquis - Acheté & Utilisé - Acheté & Non Utilisé"]
        }
      }
    }

         series={    
          [ {
           name: "Acquis",
           data: [calculateProductsListTotal(aquiredUsedProductsList)],
           color: "#7EE4AA"
         },
         {
           name: "Acheté & Utilisé",
           data: [calculateProductsListTotal(boughtUsedProductsList)],
           color:"#F4BF5F"
         },
         {
           name: "Acheté & Non Utilisé",
           data: [calculateProductsListTotal(boughtNotUsedProductsList)],
           color:"#EE6D7A"
         },]}
        
         type="bar"
          // width="100%"
         height="360px"
       />


   
 </Grid>
 <Grid item md={6} xs={12} style={{padding:20}} >

 <Chart

options={{
  colors:["#7EE4AA","#F4BF5F"],
  yaxis: {
    labels: {
      formatter: function (value) {
        return (value.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" DH";
      }
    },
  },
  chart: {
    id: "donut"

  },

  labels: ['Acquis & Utilisé (%)', 'Acheté & Utilisé (%)'],


}}
series={[

calculateProductsListTotal(aquiredUsedProductsList),
 calculateProductsListTotal(boughtUsedProductsList)
 ]}
width={400}
type="donut"


/>

    
 </Grid>
 <Grid item md={12} xs={12}>
            <br/>
          </Grid>
          <Grid item md={12} xs={12}>
          <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              List Produit :
              </Typography>
              <Grid  container spacing={5} >
              <Grid item  >
                {tableDataType==="Acquis" ? 
              <Button style={{width:220}} onClick={() => {changeTable("Acquis")}} className="myCompletedButton">Acquis</Button>:
              <Button style={{width:220}} onClick={() => {changeTable("Acquis")}} className="myCompletedButtonDisabled">Acquis</Button>  
              }
            
            </Grid>
            <Grid item  >
            {tableDataType==="Acheté & Utilisé" ? 
              <Button style={{width:220}} onClick={() => changeTable("Acheté & Utilisé")} className="myProcessingButton">Acheté & Utilisé</Button>:
              <Button style={{width:220}} onClick={() => changeTable("Acheté & Utilisé")} className="myProcessingButtonDisabled">Acheté & Utilisé</Button>  
              }
           
            </Grid>
            <Grid item   >
            {tableDataType==="Acheté & Non Utilisé" ? 
              <Button style={{width:220}} onClick={() => changeTable("Acheté & Non Utilisé")} className="myFailedButton">Acheté & Non Utilisé</Button>:
              <Button style={{width:220}} onClick={() => changeTable("Acheté & Non Utilisé")} className="myFailedButtonDisabled">Acheté & Non Utilisé</Button>  
              }
      
            </Grid>
          </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <br/>
          </Grid>
          <Grid item md={12} xs={12}>
          <TableContainer className="tableContainerStyle"  component={Paper}>
<Table  aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Id Produit</TableCell>
      <TableCell className="filtertitle" align="left">Quantité</TableCell>
      <TableCell className="filtertitle" align="left">Prix Achat Moyen</TableCell>
      <TableCell className="filtertitle" align="left">Cout Total</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {getTableData()
.map((product) => (
<TableRow
 key={product.productId}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{product.productId}</TableCell>

<TableCell  align="left">{formatQuantity(product.quantity)}</TableCell>
<TableCell  align="left">{formatMoney(product.buy_price)}</TableCell>
<TableCell  align="left">{formatMoney(product.total)}</TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          // rowsPerPageOptions={[10]}
          colSpan={3}
          count={getTableData().count}
          // rowsPerPage={10}
          // page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
          </Grid>

 
 
     
         
               </Grid>
               <br/>
               <p className="bigText">Ranking des produits</p>
               <br/>
               <TableContainer className="tableContainerStyle"  component={Paper}>
<Table  aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Ranking</TableCell>
      <TableCell className="filtertitle" align="left">ID Produit</TableCell>
      <TableCell className="filtertitle" align="left">Quantité Vendu</TableCell>
      <TableCell className="filtertitle" align="left">Revenue Total</TableCell>
      <TableCell className="filtertitle" align="left">Benefice Total</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {rankingList
.map((product,index) => (
<TableRow
 key={product.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{index+1}</TableCell>
<TableCell component="th" scope="row" align="left">{product.productId}</TableCell>

<TableCell  align="left">{formatQuantity(product.globalQuantity)}</TableCell>
<TableCell  align="left">{formatMoney(product.globalRevenue)}</TableCell>
<TableCell  align="left">{formatMoney(product.globalGain)}</TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={productsCount}
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>

         </div>
             
         );
 
 };
 export default View;