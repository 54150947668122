import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel,CircularProgress } from "@mui/material";
import {getCompressedImage} from "../../../../helpers/constants";
import React, { useState,useEffect } from "react";
import SmallInput from "../../../../globalComponents/SmallInput";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import "./supplierordermodal.css";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,customerOrder,handleUpdate}) => {

  const [montant,setMontant] = useState("");
  const [type,setType] = useState("virement");
  const [file,setFile] = useState(null);
  const [total,setTotal]=useState(0);
  const [totalPaid,setTotalPaid]=useState(0);
  const [id,setId]=useState("")
  const [isFactured,setIsFactured]=useState(false);
  const [customerId,setCustomerId]=useState("");

 

 useEffect(
   () => {
    setMontant(old => "");
    setType(old => "virement");
    setFile(old => null);
    setId(old => customerOrder.id);
    setTotal(old => customerOrder.total);
    setTotalPaid(old => customerOrder.paidAmount);
    setIsFactured(old => customerOrder.factured);
    setCustomerId(old => customerOrder.customerId );
    setUpdateClicked(old => false);
   },
   [customerOrder]
 );

 

    var [updateClicked,setUpdateClicked]=useState(false);
    const updatedData=async() => {
      var compressedImage=null;
      if(file!=null)
      {
         compressedImage=await getCompressedImage(file);
      }

      return {
      
        montant:montant,
        type:type,
        file:compressedImage,
        factured:isFactured,
        total:total,
        totalPaid:totalPaid,
        

      }
    }
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Commande Client : {customerId} </DialogTitle>
        <DialogContent id="dialogContent" >
          <Grid style={{padding:10}} container spacing={1}>

              <Grid item xs={12} md={12}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Facture :
            </Typography>
              <Grid id="isActiveContainer" container spacing={0} direction="row" justifyContent="space-around" alignItems="baseline">
                <Grid item >
                  {isFactured ? 
                  <Button onClick={() => {setIsFactured(true)}} className="McompletedButton">Facturée</Button>
                  :
                  <Button onClick={() => {setIsFactured(true)}} className="McompletedButtonDisabled">Facturée</Button>
                }
                </Grid>
                <Grid item >
                {!isFactured ? 
                  <Button onClick={() => {setIsFactured(false)}} className="MfailedButton">Non Facturée</Button>
                  :
                  <Button onClick={() => {setIsFactured(false)}} className="MfailedButtonDisabled">Non Facturée</Button>
                }
               
                </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Paiement :
            </Typography>
              <Grid style={{"paddingBottom":"5px"}}  container spacing={1}  direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                   <SmallInput found={(montant==="" || !isNaN(montant) )? 
                                         null
                                         :
                                         false} value={montant} onChange={(event) =>{setMontant(old => event.target.value)}} 
                          placeholder="Montant" 
                   />
                <span >  </span>
                         <FormControl >
                <InputLabel id="demo-simple-select-label">Type Paiement</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Paiement"
                  className="smallSelectStyle"
                  value={type}
                  onChange={(event)=> {setType((old) => event.target.value)}}
                >
                 <MenuItem value="virement">virement</MenuItem>
                    <MenuItem value="transfert">transfert</MenuItem>
                    <MenuItem value="espece">espece</MenuItem>
                    <MenuItem value="cheque">cheque</MenuItem>
           
                </Select>
                    </FormControl>
                    <span >  </span>
                    <Button
                        variant="contained"
                        component="label" 
                        className={file!==null? "insertPhotoButton":""    }
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => setFile(event.target.files[0])}
                        />
                      </Button>
                   </Grid>
                </Grid>
              </Grid>
              

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          {
            updateClicked?
            <CircularProgress/>
            :
            <Button id="modifierButton" onClick={
              async() => {
               if(!updateClicked)
               {
                 setUpdateClicked(old => true);
                 var myUpdatedData=await updatedData();
                 await handleUpdate({
                   idOrder:id,
                   data:myUpdatedData,
                 });
   
                 setUpdateClicked(old => false);
   
               }
             
              }
               }>Modifier</Button>
          }
          
        </DialogActions>
      </Dialog>
    )
};
export default UpdateModal;
