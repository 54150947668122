import { Route, Routes, Navigate } from "react-router-dom";
import Agents from "../pages/agents/Agents";

import Layout from "../layout";
import Login from "../pages/Login";
import Dashboard from "../pages/dashboard";
import Achat from "../pages/gestionachat";
import Caisse from "../pages/gestioncaisse";
import Commande from "../pages/gestioncommande";
import Local from "../pages/gestionlocal";
import Personnel from "../pages/gestionpersonnel";
import Stock from "../pages/gestionstock";
import Ticket from "../pages/gestionticket";
import Vente from "../pages/gestionvente";
import Comptable from "../pages/interfacecomptable";
import Market from "../pages/market";
import { useSelector } from "react-redux";
import RendezVousPage from "../pages/rendezVous";
const MainRoutes = ()=>{

  const decodedJWT=useSelector(state => state.decodedJWT );
  if(decodedJWT?.Role=="SuperAdmin")
 return (<Routes>
    <Route path="/tableaudebord" exact element={<Layout title="Tableau de bord" ><Dashboard/> </Layout>} />
    <Route path="/gestionAchat" exact element={<Layout title="gestion achats" ><Achat/> </Layout>} />
    <Route path="/gestionCaisse" exact element={<Layout title="gestion caisse" ><Caisse/> </Layout>} />
    <Route path="/gestionCommande" exact element={<Layout title="gestion commandes" ><Commande/> </Layout>} />
    <Route path="/gestionLocal" exact element={<Layout title="gestion local" ><Local/> </Layout>} />
    <Route path="/gestionPersonnel" exact element={<Layout title="gestion personnel" ><Personnel/> </Layout>} />
    <Route path="/gestionStock" exact element={<Layout title="gestion stock" ><Stock/> </Layout>} />
    <Route path="/gestionTicket" exact element={<Layout title="gestion ticket" ><Ticket/> </Layout>} />
    <Route path="/gestionVente" exact element={<Layout title="gestion ventes" ><Vente/> </Layout>} />
    <Route path="/interfaceComptable" exact element={<Layout title="interface comptable" ><Comptable/> </Layout>} />
    <Route path="/market" exact element={<Layout title="Marché" ><Market/> </Layout>} />
    <Route path="/rendezVous" exact element={<Layout title="Rendez Vous" ><RendezVousPage/> </Layout>} />
    <Route path="*" exact element={<Layout title="Tableau de bord" ><Dashboard/> </Layout>} />
    
  </Routes>)
  else if(decodedJWT?.Role=="Admin")
  return (<Routes>
    <Route path="/tableaudebord" exact element={<Layout title="Tableau de bord" ><Dashboard/> </Layout>} />
    <Route path="/gestionAchat" exact element={<Layout title="gestion achats" ><Achat/> </Layout>} />
    <Route path="/gestionCaisse" exact element={<Layout title="gestion caisse" ><Caisse/> </Layout>} />
    <Route path="/gestionCommande" exact element={<Layout title="gestion commandes" ><Commande/> </Layout>} />
    <Route path="/gestionPersonnel" exact element={<Layout title="gestion personnel" ><Personnel/> </Layout>} />
    <Route path="/gestionStock" exact element={<Layout title="gestion stock" ><Stock/> </Layout>} />
    <Route path="/gestionTicket" exact element={<Layout title="gestion ticket" ><Ticket/> </Layout>} />
    <Route path="/gestionVente" exact element={<Layout title="gestion ventes" ><Vente/> </Layout>} />
    <Route path="/market" exact element={<Layout title="Marché" ><Market/> </Layout>} />
    <Route path="/rendezVous" exact element={<Layout title="Rendez Vous" ><RendezVousPage/> </Layout>} />
    <Route path="*" exact element={<Layout title="Tableau de bord" ><Dashboard/> </Layout>} />
    
  </Routes>)
  else if(decodedJWT?.Role=="Agent")
  return (<Routes>
    <Route path="/gestionAchat" exact element={<Layout title="gestion achats" ><Achat/> </Layout>} />
    <Route path="/gestionCaisse" exact element={<Layout title="gestion caisse" ><Caisse/> </Layout>} />
    <Route path="/gestionCommande" exact element={<Layout title="gestion commandes" ><Commande/> </Layout>} />
    <Route path="/gestionStock" exact element={<Layout title="gestion stock" ><Stock/> </Layout>} />
    <Route path="/gestionTicket" exact element={<Layout title="gestion ticket" ><Ticket/> </Layout>} />
    <Route path="/gestionVente" exact element={<Layout title="gestion ventes" ><Vente/> </Layout>} />
    <Route path="/market" exact element={<Layout title="Marché" ><Market/> </Layout>} />
    <Route path="/rendezVous" exact element={<Layout title="Rendez Vous" ><RendezVousPage/> </Layout>} />
    <Route path="*" exact element={<Layout title="gestion ventes" ><Vente/> </Layout>} />
  </Routes>)
  else if(decodedJWT?.Role=="Comptable")
  return (<Routes>
   <Route path="/interfaceComptable" exact element={<Layout title="interface comptable" ><Comptable/> </Layout>} />
   <Route path="*" exact element={<Layout title="interface comptable" ><Comptable/> </Layout>} />
  </Routes>)
  else
  return (<Routes>
     <Route path="/login" exact element={<Login/>} />
     <Route path="*"  element={<Login/>} />
  </Routes>)
};
//  <Route element={<Layout/>}>
export default MainRoutes;
