import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./ChartSelect.css";
const ChartSelectLocal=({locals,changeLocal})=>{

   const [localid,setLocalId]=useState("ALL");
    return (
    <FormControl style={{padding:5}}   >
     
        <Select className="chartSelectPart"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={localid}
          onChange={(event) =>{
            changeLocal(event.target.value);
            setLocalId(event.target.value);
          
          }}
        >
          <MenuItem value={"ALL"}>Chiffre d'affaires global</MenuItem>
          {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
    
        </Select>
      </FormControl>);
    

}
export default ChartSelectLocal;