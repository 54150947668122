import React from "react"
import "./MyTextArea.css";

const View=({onChange,value,placeholder,found}) =>{
if(found===true)
return <input className="myTextAreaStyle FoundProductInputBorder" value={value} onChange={onChange} 
placeholder={placeholder}
/>
else if(found===false)
return <input className="myTextAreaStyle notFoundProductInputBorder" value={value} onChange={onChange} 
placeholder={placeholder}
/>
else

return <textarea className="myTextAreaStyle" value={value} onChange={onChange} 
placeholder={placeholder}
/>

};
export default View;



