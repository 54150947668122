import {AUTHENTICATE,LOGOUT} from "./authtypes"

export const authenticate= (token) =>
(
    {
        type:AUTHENTICATE,
        payload:token,
    }
)
export const logout= (
  {
      type:LOGOUT,
  }
);