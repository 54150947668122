import React from "react";
import SideNavBar from "./SideNavbarMenu/SideNavbarMenu";
import { Grid } from "@mui/material";
import "./layout.css";
const Layout=({title,children}) => 
{
    return <div className="Dashboard">
         <SideNavBar />
         <div className="main">
         <div className="DashboardName">
          <h1 style={{margin: "0",fontWeight:600}}>{title}</h1>
        </div>
        {children}
        </div>
       </div>
}
export default Layout;