import React, { useState ,useEffect} from "react";
import {useSelector, useDispatch } from "react-redux";
import { Card,CardContent, Grid ,Typography,Button,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import {getEndPoint} from "../../helpers/constants";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import "./personnel.css"
import {logout} from "../../redux/auth/authactions"
import { useNavigate } from "react-router-dom";
import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays} from "../../helpers/constants";
import { ImportantDevices } from "@mui/icons-material";
import Online from "./components/Online";
import Offline from "./components/Offline";
import UpdateModal from "./components/UpdateModal"


//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";


const View= () => {  
    //Agent Identification
    const decodedJWT=useSelector(state => state.decodedJWT );


    const [locals,setLocals]=useState([]);
    const [agents,setAgents]=useState([]);
    // User Data
    const [cin,setCin] = useState("");  
    const [localid,setLocalId] = useState("");
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [address,setAddress] = useState("");
    const [birthday,setBirthday] = useState({
      day:0,
      month:0,
      year:0,
    });
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [role,setRole] = useState("");

    const clearInputs= ()=> {
      setCin("");
      setLocalId("");
      setName("");
      setPhone("");
      setAddress("");
      setBirthday(
        {
          day:0,
          month:0,
          year:0,
        }
      );
      setEmail("");
      setPassword("");
      setRole("");

    }

    //
    const [page, setPage] = useState(0);
    const [search,setSearch]= useState("");
    const [searchLocal,setSearchLocal]=useState("");
    const dispatch=useDispatch();
    const jwt=useSelector(state => state.jwt);
    const history=useNavigate();
    const headers = { headers: {
      "Authorization" : `Bearer ${jwt}`,

    } }
    const [modalIsOpen,setmodalIsOpen]=useState(false);
    const [selectedAgent,setSelectedAgent]=useState({});
 
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };
    const debugfilling = () => {
      setCin("AA892186");
      setName("Hamza elmhoujab");
      setAddress("Agdal Okba")
      setPhone("+212682767885");
      setEmail("testing1@gmail.com");
      setPassword("jig123");
      setLocalId("2");
      

    }

    const createAgent=async() => {
      const data={
        cin:cin,
        localid:localid,
        name:name,
        phone:phone,
        address:address,
        birthday: new Date(mydateToString()),
        email:email,
        isEnabled:true,
        password:password,
        role:role,
        
      }
      
      console.log("Created Data :");
      console.log(data);
      const endpoint=getEndPoint("/agents");
      const response = await axios.post(endpoint,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
      
         
        }
   
      });
      if(response!=null && response.data!=null)
      {
        clearInputs();

      }
      await loadUsers();

    }
    const freeTokenFromSessionStorage = () => {
      dispatch(logout);
      saveLogoutInLocalStorage();
      history("/login");
    };
    const loadUsers= async() => {
      console.log("decodedJWT");
      console.log(decodedJWT);
      const endpoint=getEndPoint("/agents?Role="+decodedJWT.Role+"&localid="+decodedJWT.localId);
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      setAgents(old => data.data);
    }
    const loadLocals= async() => {
      const endpoint=getEndPoint("/Local");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      setLocals(old => data.data);

    }

    const setYear=(value) =>
    {
      setBirthday((old) => {
            return {
              ...old,
              year:value,
            }
      });

    };
    const setMonth=(value) =>
    {
      setBirthday((old) => {
            return {
              ...old,
              month:value,
            }
      });

    };
    const setDay=(value) =>
    {
      setBirthday((old) => {
            return {
              ...old,
              day:value,
            }
      });

    };
    const mydateToString=()=>{
      return birthday.year+"-"+birthday.month+"-"+birthday.day;

    }
    const closeUpdateModal=()=>{
      setmodalIsOpen(olde => false);
      
    }
    const handleUpdate= async ({idAgent,data})=>{
      console.log("idAgent :"+idAgent);
      console.log("Data :");
      console.log(data);

      
      const endpoint=getEndPoint("/agents");
      const response = await axios.put(endpoint+"?id="+idAgent,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
        console.log("Update Error Response Status :");
        console.log(error.response);
   
      });
      console.log("Update Data Response :");
      console.log(response.data);
      await loadUsers();
       

      closeUpdateModal();
      
        
    }
    const openUpdateModal=(agent) =>{
      setSelectedAgent(agent);
      setmodalIsOpen(true);
    }

    useEffect(()=> {
      loadUsers();
      loadLocals();
    },[]);
    return (
      <div>
        <UpdateModal  open={modalIsOpen} decodedJWT={decodedJWT} agent={selectedAgent} handleClose={closeUpdateModal} handleUpdate={handleUpdate} locals={locals} />
      <Card className="creationCard" variant="outlined">
        <CardContent>
          <p onClick={debugfilling} className="cornerText">Nouveau Agent </p>
     
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             Cin : 
            </Typography>
            <MyInput value={cin} onChange={(event) => setCin(event.target.value)} 
            placeholder="Cin" 
           />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              NomPrenom :
            </Typography>
            <MyInput value={name} onChange={(event) => setName(event.target.value)} 
            placeholder="NomPrenom" 
           />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Email :
            </Typography>
            <MyInput value={email} onChange={(event) =>{setEmail(event.target.value)}} 
            placeholder="Email" 
       />
              </Grid>
             </Grid> 
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography  className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Mot de passe :
            </Typography>
            <MyInput value={password} onChange={(event) =>{setPassword(event.target.value)}} 
            placeholder="Mot de passe" 
           />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Numéro de téléphone :
            </Typography>
            <MyInput value={phone} onChange={(event) =>{setPhone(event.target.value)}} 
            placeholder="Numéro de téléphone" 
            />
              </Grid>
              <Grid item >
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Date d'anniversaire :
            </Typography>
            <Grid style={{width:"16.5rem"}} container spacing={0.5}>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Année</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Année"
                  className="birthdayselectStyle"
                  onChange={(event)=> setYear(event.target.value)}
                >
                  {generateArrayOfYears().map(year => (
            
                    <MenuItem key={year} value={year}>{year}</MenuItem>

                  ))}
                 
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Mois</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Mois"
                  className="birthdayselectStyle"
                
                  onChange={(event)=> setMonth(event.target.value)}
                >
                  {generateArrayOfMonths().map((month) => (
        
                    <MenuItem key={month.number} value={month.number}>{month.name}</MenuItem>

                  ))}
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Jour</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Jour"
                  className="birthdayselectStyle"
                  onChange={(event)=> setDay(event.target.value)}
                >
                  {generateDays().map((daynNumber) => (
        
        <MenuItem key={daynNumber} value={daynNumber}>{daynNumber}</MenuItem>

      ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
       
              </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Adresse :
            </Typography>
            <MyInput value={address} onChange={(event) =>{setAddress(event.target.value)}} 
            placeholder="Adresse" 
            />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              ID local :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="MySelectStyle"
                  onChange={(event) =>{setLocalId(event.target.value)}}
                >
              
                  {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
    
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Role :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  className="MySelectStyle"
                  onChange={(event)=> setRole(old => event.target.value)}
                >
                  {
                    decodedJWT.Role=="SuperAdmin" &&
                    <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                  }
                   {
                    decodedJWT.Role=="SuperAdmin" &&
                    <MenuItem value="Admin">Admin</MenuItem>
                  }
                
                    <MenuItem value="Agent">Agent</MenuItem>
                    <MenuItem value="Comptable">Comptable</MenuItem>
                </Select>
              </FormControl>
              </Grid>
            </Grid>
                <br/>
                <br/>
              <Button onClick={createAgent} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button>
     
           

      
          
          
        </CardContent>
        </Card>
    <br/>
  {/* search Part */}
    <Card className="searchCard" variant="outlined">
        <CardContent>

        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
            />
              </Grid>
              <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="MySelectStyle"
                  onChange={(event)=> {setSearchLocal((old)=> (event.target.value))}}
                >
                  <MenuItem value="">Tous</MenuItem>
                  {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
              </Grid>
          </Grid>
        </CardContent>
    </Card>


    <br/>

    {/* Table Part !  */}
    <TableContainer className="tableContainerStyle"  component={Paper}>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            
          <TableHead>
            <TableRow>
              <TableCell className="filtertitle" align="left">Cin</TableCell>
              <TableCell className="filtertitle" align="left">NomPrenom</TableCell>
              <TableCell className="filtertitle" align="left">Email</TableCell>
             
              <TableCell className="filtertitle" align="left">Numéro de téléphone</TableCell>
              <TableCell className="filtertitle" align="left">Date d'anniversaire</TableCell>
              <TableCell className="filtertitle" align="left">Adresse</TableCell>
              <TableCell className="filtertitle" align="left">ID local</TableCell>
              <TableCell  className="filtertitle" align="left">Role</TableCell>
              <TableCell  className="filtertitle" align="left">est activé</TableCell>
              <TableCell  className="filtertitle" align="left">Modifier</TableCell>
            </TableRow>
          </TableHead>
     
          <TableBody>
          {agents.filter(agent => ((searchLocal=== "" ||agent.localid === searchLocal) && ( 
          agent.cin?.includes(search) || 
          agent.name?.includes(search) || 
          agent.email?.includes(search) ||
          agent.phone?.includes(search) ||
          agent.birthday?.includes(search) ||
          agent.address?.includes(search)||
          agent.role?.includes(search)
          )))
        .slice(page * 10, page * 10 + 10)
        .map(agent => (
 <TableRow
 // key={order.id}
  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
  <TableCell component="th" scope="row" align="left">{agent.cin}</TableCell>
  <TableCell align="left">{agent.name}</TableCell>
  <TableCell  align="left">{agent.email}</TableCell>
  <TableCell align="left">{agent.phone}</TableCell>
  <TableCell align="left">{agent.birthday}</TableCell>
    <TableCell align="left">{agent.address}</TableCell>
    <TableCell align="left">{agent.localid}</TableCell>
    <TableCell align="left">{agent.role}</TableCell>

    <TableCell align="left">
      {agent.enabled ? <Online/> : <Offline/>}

        </TableCell>
  <TableCell align="left"><EditIcon onClick={() => {openUpdateModal(agent)}}  className="editIcon"/></TableCell>
  
</TableRow>
    ))}
             
          </TableBody>
          <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={3}
                  count={
                    agents.filter(agent => ((searchLocal=== "" ||agent.localid === searchLocal) && ( 
                      agent.cin?.includes(search) || 
                      agent.name?.includes(search) || 
                      agent.email?.includes(search) ||
                      agent.phone?.includes(search) ||
                      agent.birthday?.includes(search) ||
                      agent.address?.includes(search)||
                      agent.role?.includes(search)
                      ))).length
                    }
                  rowsPerPage={10}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
        </Table>
       
      </TableContainer>

      </div>
        
        );
 };

 export default View;