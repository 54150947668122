import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
    Grid ,Typography,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import { useReactToPrint } from 'react-to-print';
import React, { useState,useEffect,useRef  } from "react";
import logo from "../../../assets/logo-invoice.svg";

  // import SmallInput from "../../../../globalComponents/SmallInput";
  // import CameraAltIcon from '@mui/icons-material/CameraAlt';
  // import "./supplierordermodal.css";

  //Invoice Design
import Invoice from "../Invoice";

//Icons 
import PrintIcon from '@mui/icons-material/Print';

//Css 
import "./Invoice.css";


  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  const InvoiceModal=({open,handleClose,order}) => {
    const invoiceNumber=1;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
      return (
          <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogTitle className="cornerText"> Invoice  </DialogTitle>
          <DialogContent id="dialogContent" >
          <div ref={componentRef} className="content">
          <div >ATWAB ASSALAM</div>
        <div >INPE.165017179</div>
        <br/>
        <Grid container spacing={4}>
          <Grid xs={9} md={9}>
          <img
            className="imageGrand-responsive"
            src={logo}
            style={{ width: "250px", marginTop: "50px",marginLeft:"30px" }}
          />
          </Grid>
          <Grid xs={3} md={3}>
            <Grid container direction="column" justifyContent="right" alignItems="baseline" >
           
    
        {/* <img style="width: 8cm"  src="{{url('logobro.jpg')}}"> */}
        <Grid item><p >Date :</p>{order?.created_At}</Grid>
        <Grid item><p >Facture N° :</p>{invoiceNumber}</Grid>
       <Grid  item><p >Nom du client :</p>{order?.customerId}</Grid>
    <br/>
 
            </Grid>
          </Grid>
        </Grid>
   
  

    <table >
        <thead>
        <tr>
            <th width="100"  scope="col">Qté</th>
            <th   scope="col">Désignation</th>
            <th width="100"   scope="col">Prix unitaire DH/TTC</th>
            <th width="100"   scope="col">Prix total DH/TTC</th>
            <td width="100" scope="col"></td>
        </tr>
        </thead>
        <tbody>
          {order.purchasedProducts!=null ?
          order.purchasedProducts?.map(product => 
              (
                <tr>
                <th width="100" scope="col" >{product.quantity}</th>
                <td width="100"  scope="col" >Pièce : {product.productId}</td>
                <td width="100"   scope="col">{parseInt(product.sell_price)}.00DH</td>
                <td width="100"  scope="col">{parseInt(product.total)}.00DH</td>
                <td width="100" scope="col"></td>
   
               </tr>
             )
            )
        :
        <tr/>}
         
        


        </tbody>
    </table>
    <br/>
    <br/><div >
     <div >
     Arrêter la présente facture à la somme de :.................................................................................
        <div ></div><div><p >Total DH/TTC :{parseInt(order.total)}.00DH</p></div>
     </div>

    </div>
    <div ><p >............................................................................................................</p></div>








    <br/><hr/>
    <div  >	7 RUE TRONCHET 75008 PARIS Tél:06 82 76 78 85
        E-mail:Elmhoujabhamzab2@gmail.com</div>

    <div >
        <a href=""  target="_blank" ><i class="fa fa-print"></i> </a>
    </div>


    </div>
    <br/>
    <br/>
            {/* <Invoice order={order} invoiceNumber={1} ref={componentRef}/> */}
            <Button className="printButton" onClick={()=> handlePrint()}><PrintIcon/> </Button>
  
     
          </DialogContent>
          <DialogActions>
            <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      )
  };
  export default InvoiceModal;
  