import { combineReducers } from 'redux';
import authReducer from "./auth/authreducer";


const reducer=combineReducers({
        auth:authReducer,
    
    });
export default reducer;

