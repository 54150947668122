import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import "./SideNavbarMenuStyle.css";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/mainlogo.svg";
import logo_without_typo from "../../assets/mainlogo.svg";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {authenticate,logout}from "../../redux/auth/authactions";
import {saveLogoutInLocalStorage} from "../../helpers/constants";

import BookmarkIcon from '@mui/icons-material/Bookmark';

const SideNavbarMenu = () => {
  const dispatch=useDispatch();
  const history = useNavigate();
  const decodedJWT=useSelector(state => state.decodedJWT );
  const freeTokenFromSessionStorage = () => {
    dispatch(logout);
    saveLogoutInLocalStorage();
    history("/login");
  };

  var [show, setShow] = useState(false);

  const showMenu = () => {
    setShow((show = !show));
  };

  const closeMenu = () => {
    setShow((show = !show));
  };

  const menuEtat = show ? "show" : "hide";
  return (
    <div>
      <MenuIcon onClick={showMenu} className="menu-icon-responsive" />
      <div className={`sidebar-responsive ${menuEtat}`}>
        <span onClick={closeMenu} className="close-btn">
          &#10005;
        </span>
        <div className="imageLogo">
          <img
            className="imageGrand-responsive"
            src={logo}
            style={{ width: "200px", marginTop: "20px" }}
          />
        </div>
        { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" ) &&
        <Link to="/">
          <div style={{ display: "flex" }}>
            <DashboardOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Tableau de bord</p>
          </div>
        </Link>
        }
        { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionVente">
            <div style={{display: "flex"}}>
                <StoreOutlinedIcon className='menu-all-icons-responsive'/>
                <p className='menu-text-responsive'>Vente</p>
            </div>
        </Link>
        }
         { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionCommande" >
          <div style={{ display: "flex" }}>
            <RestoreOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Commandes</p>
          </div>
        </Link>
        }
         { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/market">
          <div style={{ display: "flex" }}>
            <StoreOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Market</p>
          </div>
        </Link>
        }
       { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionStock">
          <div style={{ display: "flex" }}>
            <WarehouseOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Stock</p>
          </div>
        </Link>
       }
        { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionAchat">
          <div style={{ display: "flex" }}>
            <ShoppingBagOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Achats</p>
          </div>
        </Link>
       }
       { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionCaisse">
          <div style={{ display: "flex" }}>
            <AttachMoneyOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Caisse</p>
          </div>
        </Link>
       }
           { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/rendezVous">
            <div style={{display: "flex"}}>
                <BookmarkIcon className='menu-all-icons-responsive'/>
                <p className='menu-text-responsive'>Rendez Vous</p>
            </div>
        </Link>
        }
        { decodedJWT.Role=="SuperAdmin"  &&
        <Link to="/gestionLocal">
          <div style={{ display: "flex" }}>
            <StorefrontOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Locaux</p>
          </div>
        </Link> 
       }
       { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin"  ) &&
        <Link to="/gestionPersonnel">
          <div style={{ display: "flex" }}>
            <GroupOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Personnel</p>
          </div>
        </Link>
       }
        { (decodedJWT.Role=="Comptable" || decodedJWT.Role=="SuperAdmin" ) &&
        <Link to="/interfaceComptable">
          <div style={{ display: "flex" }}>
            <ArticleOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Interface Comptable</p>
          </div>
        </Link>
       }
       { ( decodedJWT.Role=="SuperAdmin"  ) &&
        <Link to="/gestionTicket">
          <div style={{ display: "flex" }}>
            <ReportOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Ticket</p>
          </div>
        </Link>
}
        <Link to="/" onClick={freeTokenFromSessionStorage}>
          <div style={{ display: "flex" }}>
            <LogoutOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Déconnexion</p>
          </div>
        </Link>
      </div>
      <div className="sidebar">
        <div className="imageLogo">
          <img className="imageGrand" src={logo} style={{ width: "70%" }} />
          <img
            className="imageSmall"
            src={logo_without_typo}
            style={{ width: "25px" }}
          />
        </div>
        { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin"  ) &&
        <Link to="/tableaudebord">
          <div style={{ display: "flex", alignItems: "center"}}>
            <DashboardOutlinedIcon className="menu-icon" />
            <p className="menu-text">Tableau de bord</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionVente">
          <div style={{ display: "flex", alignItems: "center" }}>
            <StoreOutlinedIcon className="menu-icon" />
            <p className="menu-text">Vente</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionCommande" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <RestoreOutlinedIcon className="menu-icon" />
            <p className="menu-text">Commandes</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/market">
          <div style={{ display: "flex", alignItems: "center" }}>
            <StoreOutlinedIcon className="menu-icon" />
            <p className="menu-text">Market</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionStock">
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarehouseOutlinedIcon className="menu-icon" />
            <p className="menu-text">Stock</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent"  ) &&
        <Link to="/gestionAchat">
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShoppingBagOutlinedIcon className="menu-icon" />
            <p className="menu-text">Achat</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/gestionCaisse" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <AttachMoneyOutlinedIcon className="menu-icon" />
            <p className="menu-text">Caisse</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Agent" ) &&
        <Link to="/rendezVous" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <BookmarkIcon className="menu-icon" />
            <p className="menu-text">Rendez Vous</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="SuperAdmin" ) &&
        <Link to="/gestionLocal" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <StorefrontOutlinedIcon className="menu-icon" />
            <p className="menu-text">Locaux</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" ) &&
        <Link to="/gestionPersonnel" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <GroupOutlinedIcon className="menu-icon" />
            <p className="menu-text">Personnel</p>
          </div>
        </Link>
}
{ (decodedJWT.Role=="Comptable" || decodedJWT.Role=="SuperAdmin"  ) &&
        <Link to="/interfaceComptable" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArticleOutlinedIcon className="menu-icon" />
            <p className="menu-text">Interface Comptable</p>
          </div>
        </Link>
}
{ ( decodedJWT.Role=="SuperAdmin"  ) &&
        <Link to="/gestionTicket" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ReportOutlinedIcon className="menu-icon" />
            <p className="menu-text">Ticket</p>
          </div>
        </Link>
}
        <Link to="/" onClick={freeTokenFromSessionStorage}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LogoutOutlinedIcon className="menu-icon" />
            <p className="menu-text">Déconnexion</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default SideNavbarMenu;
