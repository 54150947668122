import React, { useEffect, useState } from "react";
import "./market.css";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container
   } from "@mui/material";
import {useSelector, useDispatch } from "react-redux";

//Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';


//Table
import TableSelection from "./components/TableSelection";


//Modals
import ProductSearchModal from "./components/ProductSearchModal"
import MarketProductEditModal from "./components/MarketProductUpdateModal"
import EditOrderModal from "./components/EditOrderModal"

//Axios Calls
import { getEndPoint, getMarketPaymentImageURL, saveLogoutInLocalStorage, getCompressedImage } from "../../helpers/constants";
import axios from "axios";
import { logout } from "../../redux/auth/authactions";

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";

//Others 
import { useNavigate } from "react-router-dom";


const Market=()=>{
    //Agent Identification
    const decodedJWT=useSelector(state => state.decodedJWT );
      //Locals 
    const [locals,setLocals]=useState([]);

     //Product
     const [product,setProduct]=useState({});
     const [idProduct,setIdProduct]=useState("");
     const [nameProduct,setNameProduct]=useState("");
     const [typeProduct,setTypeProduct]=useState("");
     const [categoryProduct,setCategoryProduct]=useState("");
     const [colorProduct,setColorProduct]=useState("");
     const [gammeProduct,setGammeProduct]=useState("");
     const [sellPriceProduct,setSellPriceProduct]=useState("");
     const [buyPriceProduct,setBuyPriceProduct]=useState("");


     //Achat 
     const [customers,setCustomers]=useState([]);
     const [idCustomerSell,setIdCustomerSell]=useState("");
     const [idLocalSell,setIdLocalSell]=useState("");
     useEffect(()=>{
      console.log("Decoded JWT :");
      console.log(decodedJWT);
      if(decodedJWT.Role!="SuperAdmin")
      setIdLocalSell(decodedJWT.localId);
   
     },[decodedJWT]);
    //  const [supplierOrders,setSupplierOrders]=useState([]);
    //  const [idFournisseurAchat,setIdFournisseurAchat]=useState("");
    //  const [idLocalAchat,setIdLocalAchat]=useState("");
     const [boughtProducts,setBoughtProducts]=useState(
       [
       {
         found:null,
         index:0,
         productId:"",
         quantity:"",
         buy_price:null,
         sell_price:null,
         total:null,
         totalsellprice:null,
       }
     ]);
     const [productIndex,setProductIndex]=useState(1);
     //Order Information
     const [marketImage,setMarketImage]=useState(null);
     const [lockedMarketImage,setLockedMarketImage]=useState(null);
     const [marketPrice,setMarketPrice]=useState(null);
     const [serviceTotalSellPrice,setServiceTotalSellPrice]=useState(null);
     const [totalPayment,setTotalPayment]=useState(null);
     const [paymentsList,setPaymentsList]=useState(
       [
       {
         index:0,
         amount:"",
         type:"virement",
         image:null,
       }
     ]);
     const [paymentIndex,setPaymentIndex]=useState(1);

   //Table 


   //Table Commandes 
   const [orders,setOrders]=useState([]);
   const [statusFilter,setStatusFilter] = useState("");
   const [facturedFilter,setFacturedFilter]=useState("");
   const changeStatusFilter=(status) => 
    {
          if(statusFilter==status)
          {
            setStatusFilter((old) => "");
   
          }
          else
          {
            setStatusFilter((old) => status); 
       
          }
    }

     //Order Edit Modal 
     const [orderModalIsOpen,setOrderModalIsOpen]=useState(false);
     const [selectedOrder,setSelectedOrder]=useState({});
     const closeOrderModal=()=>{
       setOrderModalIsOpen(old => false);
       
     }
     const openOrderModal=(order) =>{
       setSelectedOrder(order);
       setOrderModalIsOpen(true);
     }
     const handleOrderUpdate= async ({idOrder,data})=>{
  
       const myData=new FormData();
       myData.append("id",idOrder);
       myData.append("montant",data.montant);
       myData.append("type",data.type);
       myData.append("file",data.file);
       myData.append("factured",data.factured);
       
       if((data.montant==null || data.montant=="") || ((parseFloat(data.totalPaid)+parseFloat(data.montant))<=parseFloat(data.total)))
       {
        const endpoint=getEndPoint("/marketOrders");
        const response = await axios.put(endpoint,myData,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        console.log("You're not allowed buddy");
         freeTokenFromSessionStorage();
            
       }
       console.log("Update Error Response Status :");
        console.log(error.response);
      
      });
      console.log("response :");
      console.log(response);
        await loadOrders();
          
   
         closeOrderModal();
  
       }
      
        
          
      }
 

   //Table Products
   const [products,setProducts]=useState([]);
   const [search,setSearch]= useState("");
   const tableSize=10;
   const [page, setPage] = useState(0);    
   const [ordersCount,setOrdersCount]=useState(0);
   const [productsCount,setProductsCount]=useState(0);
   const [isMarketProductTable,setIsMarketProductTable]=useState(true);
   const handleChangePage = (event,newPage) => 
   {
       setPage(newPage);
   };
   const changeDisplayedTable=() =>{
    setIsMarketProductTable((old) => !old);
   }


  //Edit Modal 
  const [productEditModalIsOpen,setProductEditModalIsOpen]=useState(false);
  const [selectedProduct,setSelectedProduct]=useState({});
  const closeProductEditModal=()=>{
    setProductEditModalIsOpen(old => false);
  }
  const openProductEditModal=(product) =>{
    setSelectedProduct(product);
    setProductEditModalIsOpen(true);
 }
//  const handleProductEditUpdate= async ({idProduct,data})=>{
//   const endpoint=getEndPoint("/marketProduct");
//   const response = await axios.put(endpoint,data,headers).catch(function (error) {
//   if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
//   console.log("You're not allowed buddy");
//    freeTokenFromSessionStorage();
      
//  }
//  console.log("Update Error Response Status :");
//   console.log(error.response);

// });
// console.log("Updated Data :");
// console.log(response.data);
//   await loadProducts();  
//   await loadMarketProducts();
//   closeProductEditModal();
// }


   //Product Search Modal
 
   //const [marketproducts,setMarketproducts]=useState([]);
   const [stock,setStock]=useState([]);
    const [productInputIndex,setProductInputIndex]=useState(null);
    const [productModalIsOpen,setProductModalIsOpen]=useState(false);
    const closeProductModal=()=>{
      setProductModalIsOpen(old => false);
      
    }
    const openProductModal=(index) =>{
      setProductModalIsOpen(old => true);
      setProductInputIndex(index);
    }

     const handleProductModalSubmit= async ({indexProduct,productID,product})=>{
      setBoughtProducts((old) => {
        let mylist=[...old];
         
        mylist[indexProduct].productId=productID;
        mylist[indexProduct].found=true;
        mylist[indexProduct].buy_price=product.buy_price;
        mylist[indexProduct].sell_price=product.sell_price;
        if( mylist[indexProduct].quantity!="")
        {
          mylist[indexProduct].total=mylist[indexProduct].buy_price*mylist[indexProduct].quantity;
          mylist[indexProduct].sellPriceProduct=mylist[indexProduct].sell_price*mylist[indexProduct].quantity;
        }
      
        else
        {
          mylist[indexProduct].total=null;
          mylist[indexProduct].sellPriceProduct=null;
        }
  
       
        calculateTotal(mylist);
        return mylist;

      });

      //closeUpdateModal();
      closeProductModal();
      
        
    }
 


    //Methods 

     //Achat Methods 
     const setBoughtProduct=async ({index,value}) =>{
        const endpoint=getEndPoint("/marketProduct?productid="+value);
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
     
        });
        const found = (data!=null);
        setBoughtProducts((old) => {
          let mylist=[...old];
           
          mylist[index].productId=value;
          mylist[index].found=found;
          if(found)
          {
            mylist[index].buy_price=data.data.buy_price;
            mylist[index].sell_price=data.data.sell_price;
            if( mylist[index].quantity!="")
            {
                mylist[index].total=mylist[index].buy_price*mylist[index].quantity;
                mylist[index].totalsellprice=mylist[index].sell_price*mylist[index].quantity;
                mylist[index].totalGain=(mylist[index].sell_price*mylist[index].quantity)-(mylist[index].buy_price*mylist[index].quantity);
     
            }
            
            else
            {
                mylist[index].total=null;
                mylist[index].totalsellprice=null;
                mylist[index].totalGain=null;
            }
            
          }
          else
          {
            mylist[index].total=null;
            mylist[index].totalsellprice=null;
          }
         
          calculateTotal(mylist);
          return mylist;
  
        });
        console.log("bought products :");
        console.log(boughtProducts);
    
  
      }
      const setBoughtProductQuantity=({index,value})=>{
        setBoughtProducts((old) => {
          let mylist=[...old];
          mylist[index].quantity=value;
          if(mylist[index].found)
          {
            mylist[index].total=mylist[index].buy_price*mylist[index].quantity;
            mylist[index].totalsellprice=mylist[index].sell_price*mylist[index].quantity;
            mylist[index].totalGain=(mylist[index].sell_price*mylist[index].quantity)-(mylist[index].buy_price*mylist[index].quantity);
     
          }
          else
          {
            mylist[index].total=null;
            mylist[index].totalsellprice=null;
            mylist[index].totalGain=null;
          }
       
          calculateTotal(mylist);
          return mylist;
  
        });
        
  
      }
      const addProduct= ()=>{
        
        setBoughtProducts( old =>
         {
           let mylist=[...old];
           console.log("before add mylist : ");
           console.log(mylist);
           mylist.push(
            {          
              found:null,
              index:productIndex,
              productId:"",
              quantity:"",
              buy_price:null,
              sell_price:null,
              totalGain:null,
              total:null,
              totalsellprice:null,
            }
           );
           console.log("after add mylist : ");
           console.log(mylist);
           calculateTotal(mylist);
         return mylist;
  
        }
        );
        setProductIndex(old => old+1);
  
        
      };
      const removeProduct=()=>{
        if(productIndex>1)
        {
        setBoughtProducts(
          (old) =>{
            let mylist=[...old];
            mylist.pop();
            calculateTotal(mylist);
            return mylist;
          }
        );
        setProductIndex(old => old-1);
        }
  
      };
      //Payment Infos
      const calculateTotal=(list) =>
      {
        let total=0;
        let servicetotalsellprice=0;
      
        for (var i = 0; i < list.length; i++) {
          if(list[i].total==null || isNaN(list[i].total) )
          {
            total=null;
            servicetotalsellprice=null;
            break;
          }
          else
          {
            total+=list[i].total;
            servicetotalsellprice+=list[i].totalsellprice;
          }
           
        }
        setTotalPayment(old => total);
        setServiceTotalSellPrice(old => servicetotalsellprice);
        return total;
  
      }
      const setPaymentAmount=({index,value})=>{
        setPaymentsList((old) => {
          let mylist=[...old];
          mylist[index].amount=value;
          return mylist;
  
        });
  
      }
      const setPaymentType=({index,value})=>{
        setPaymentsList((old) => {
          let mylist=[...old];
          mylist[index].type=value;
          return mylist;
  
        });
      }
      const setPaymentImage=({index,value})=>{
        console.log(value);
        setPaymentsList((old) => {
          let mylist=[...old];
          mylist[index].image=value;
          return mylist;
  
        });
  
      }
  
      const addPayment= () => {
        setPaymentsList(old =>
          {
            let mylist=[...old];
            mylist.push(
              {
                index:paymentIndex,
                amount:"",
                type:"virement",
                image:null,
              }
            );
          return mylist;
  
          });
          setPaymentIndex(old => old+1);
          console.log("payment list :");
          console.log(paymentsList);
  
      }
      const removePayment=()=>{
        if(paymentIndex>1)
        {
          setPaymentsList(
          (old) =>{
            let mylist=[...old];
            mylist.pop();
            return mylist;
          }
        );
        setPaymentIndex(old => old-1);
        }
        console.log("payment list :");
        console.log(paymentsList);
      };
  

    //Axios Calls
    // const createProduct= async() => {
    //     const data={
    //       id:idProduct,
    //       name:nameProduct,
    //       type:typeProduct,
    //       category:categoryProduct,
    //       color:colorProduct,
    //       gamme:gammeProduct,
    //       sell_price:sellPriceProduct,
    //       buy_price:buyPriceProduct,
  
    //     }
    //     const endpoint=getEndPoint("/marketProduct");
    //     const response = await axios.post(endpoint,data,headers).catch(function (error) {
    //       if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
    //         console.log("You're not allowed buddy");
    //         freeTokenFromSessionStorage();
           
    //       }
     
    //     });
    //     if(response!=null)
    //     {
    //         console.log(response);
    //         clearCreateProductInput();
    //         loadMarketProducts();
    //         loadProducts();
    //     }
       
    //   }
      // const clearCreateProductInput=()=>{
      //   setIdProduct("");
      //   setNameProduct("");
      //   setTypeProduct("");
      //   setCategoryProduct("");
      //   setColorProduct("");
      //   setGammeProduct("");
      //   setSellPriceProduct("");
      //   setBuyPriceProduct("");
      // }

    //Create Market Service Order
    const calculatePaidAmount=()=>
    {
      let paidamountVariable=0;
 
      paymentsList.map((payment)=>{
        paidamountVariable+=parseFloat(payment.amount);     
      });
      return paidamountVariable;

    }
    const [marketOrderClicked,setMarketOrderClicked]=useState(false);
    const createMarketOrder= async({productsFreeMarket})=>{
      console.log("Market Triggered !");
      if(((marketImage!=null && productsFreeMarket)|| (lockedMarketImage!=null && !productsFreeMarket) )  && !marketOrderClicked && marketPrice!=null && (marketPrice>=serviceTotalSellPrice || productsFreeMarket) && (calculatePaidAmount()<=marketPrice))
      {
        console.log("Market Working !");
        setMarketOrderClicked(old => true);
       let formData=new FormData();
       let data={
        customerId:null,
          localId:null,
          agentId:null,
          total:null,
          cost:null,
          paidAmount:null,
          status:null,
          factured:false,
          type:"MARKET",
         purchasedProducts:[],
         purchasePayments:[]
       };
       data.customerId=idCustomerSell;
       data.localId=idLocalSell;
       data.total=marketPrice;
       if(productsFreeMarket)
       {
        data.cost=0.0;
       }
       else
       data.cost=totalPayment;
   
     if(productsFreeMarket!=null && productsFreeMarket==false)
     {
      boughtProducts.map((product)=>{
        if(product.productId!=null && product.productId!="")
         data.purchasedProducts.push(
           {
           productId:product.productId,
           quantity:product.quantity,
           sell_price:product.sell_price,
           buy_price:product.buy_price,
           totalGain:product.totalGain,
           total:product.total,
           }
         );
 
       });
     }
     
       let paidamountVariable=0;

       var images = await Promise.all( paymentsList.map(async(payment)=>{
        var compressedImage=null;
        if(payment.image!=null)
        {
           compressedImage=await getCompressedImage(payment.image);
        }
        
         formData.append("files",compressedImage);
    
         paidamountVariable+=parseFloat(payment.amount);
 
         data.purchasePayments.push(payment);
         return compressedImage;
         
       }));
 
       
       data.paidAmount=paidamountVariable;
 
       if(paidamountVariable<data.total)
       {
       data.status="En Cours";
 
       }
       else
       {
       data.status="Complet";
       }
      data.purchasePayments.map((purchasepayment)=>
            {
         if(purchasepayment.image==null)
         purchasepayment.image="";
         else
         purchasepayment.image="Found";
       } 
         );
       formData.append("givendata",JSON.stringify(data));
       if(productsFreeMarket)
       {
        var compressedImage=null;
        if(marketImage!=null)
        {
           compressedImage=await getCompressedImage(marketImage);
        }

        formData.append("marketImage",compressedImage);
       }
       else
       {
        var compressedImage=null;
        if(lockedMarketImage!=null)
        {
           compressedImage=await getCompressedImage(lockedMarketImage);
        }


        formData.append("marketImage",compressedImage);
       }
       
 
    const endpoint=getEndPoint("/sellProducts");
       const response = await axios.post(endpoint,formData,headers).catch(function (error) {
         if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
           console.log("You're not allowed buddy");
           freeTokenFromSessionStorage();
          
         }
    
       });
       console.log("response :");
       console.log(response);
       if(response!=null)
       {
        console.log("Response:");
        console.log(response.data);
        clearMarketOrder();
       }
      
       else
       clearInputFiles();
    //   loadSupplierOrders();
    setMarketOrderClicked(old => false);
 
     }
  
     }
     const clearInputFiles=()=>{
       paymentsList.map((payment)=>{
         payment.image=null;
         
       });
 
     }
     const clearMarketOrder=() => { 
       setBoughtProducts([
         {
           found:null,
           index:0,
           productId:"",
           quantity:"",
           buy_price:null,
           total:null,
         }
       ]);
       setProductIndex(1);
       setTotalPayment(null);
       setPaymentsList([
         {
           index:0,
           amount:"",
           type:"virement",
           image:null,
         }
       ]);
       setMarketPrice(old => null);
       setMarketImage(old => null);
       setLockedMarketImage(old => null);
       setPaymentIndex(1);
     }

    // JWT and  freesession
        const history=useNavigate();
        const jwt=useSelector(state => state.jwt);
        const dispatch=useDispatch();
        const headers = { headers: {
            "Authorization" : `Bearer ${jwt}`,
          
      
          } }
        const freeTokenFromSessionStorage = () => {
            dispatch(logout);
            saveLogoutInLocalStorage();
            history("/login");
          };


    //Loads 
    const loadOrders=async() => {
      const endpoint=getEndPoint("/marketOrders");
      const endPointWithPaginationProperties=endpoint+"?size="+tableSize+"&page="+page+"&statusfilter="+statusFilter+"&facturedFilter="+facturedFilter+"&search="+search;
      console.log("End Point : ");
      console.log(endPointWithPaginationProperties);
      const data= await axios.get(endPointWithPaginationProperties,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
      
      }
  
      });
      console.log("Orders:");
      console.log(data?.data);
      if(data!=null)
      {
          console.log("orders :");
          console.log(data.data);
          setOrders((old) => data.data.content);
          setOrdersCount((old) => data.data.totalElements);

      }
  

    }
    // const loadProducts=async()=>{
    //   const endpoint=getEndPoint("/marketProductsPaginated");
    //   const endPointWithPaginationProperties=endpoint+"?size="+tableSize+"&page="+page+"&search="+search;
    //   const data= await axios.get(endPointWithPaginationProperties,headers).catch(function (error) {
    //     if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
    //         console.log("You're not allowed buddy");
    //         freeTokenFromSessionStorage();
        
    //     }
    
    //     });
    //     if(data!=null)
    //     {
    //         setProducts((old) => data.data.content);
    //         setProductsCount((old) => data.data.totalElements);
  
    //     }
    // }

  //   const loadMarketProducts=async()=>{
  //     const endpoint=getEndPoint("/marketProducts");
  //     const data= await axios.get(endpoint,headers).catch(function (error) {
  //       if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
  //         console.log("You're not allowed buddy");
  //         freeTokenFromSessionStorage();
         
  //       }
   
  //     });
  //     console.log("Market Products :");
  //     console.log(data?.data);
  //     setMarketproducts(old => data.data);

  // };
  const loadStock=async()=>{
    const endpoint=getEndPoint("/stock");
    const data= await axios.get(endpoint,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        console.log("You're not allowed buddy");
        freeTokenFromSessionStorage();
       
      }
  
    });
    console.log("Stock :");
    console.log(data?.data);
    setStock(old => data.data);
  
  };
    const loadCustomers=async()=>{
        const endpoint=getEndPoint("/customers");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
      
        });
        console.log("Customers :");
        console.log(data?.data);
        setCustomers(old => data.data);
      
      };
      const loadLocals= async() => {
        const endpoint=getEndPoint("/Local");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
      
        });
        setLocals(old => data.data);
      
      }
      useEffect(()=>{
        //loadProducts();
        loadCustomers();
       // loadMarketProducts();
       loadStock();
        loadLocals();
      },[]);
      useEffect(()=>{
      loadOrders();
      },[page,statusFilter,facturedFilter]);

   return (<div>
     <EditOrderModal  open={orderModalIsOpen} customerOrder={selectedOrder} handleClose={closeOrderModal} handleUpdate={handleOrderUpdate}  />
     <ProductSearchModal open={productModalIsOpen} index={productInputIndex} products={stock} handleClose={closeProductModal} handleSubmit={handleProductModalSubmit}  />
     {/* <MarketProductEditModal  open={productEditModalIsOpen} product={selectedProduct} handleClose={closeProductEditModal} handleUpdate={handleProductEditUpdate}  />
     */}
            <Grid container spacing={1}>
        
            <Grid item md={6}>
            <Card className="creationCard" variant="outlined">
<CardContent>
          <p  className="cornerText">Nouveau Marché </p>

          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
      
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID Client :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Client</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="ID Fournisseur"
                  value={idCustomerSell}
                  className="selectStyle"
                  onChange={(event) => setIdCustomerSell(event.target.value)}
                >
                 {customers.map(supplier => (
                   <MenuItem key={supplier.id} value={supplier.id}>{supplier.id}</MenuItem>
                 ))}
                </Select>
              </FormControl>
             </Grid>
             {decodedJWT.Role=="SuperAdmin" ?
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  value={idLocalSell}
                  className="selectStyle"
                   onChange={(event)=> {setIdLocalSell((old)=> (event.target.value))}}
                >
             
             {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
             </Grid>
            :
             null
            }
           
          </Grid>
        
          <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
          <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Bon 
            </Typography>
            </Grid>
         
          
            </Grid>
            <br/>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
          
            <Grid item>
            <Button
                        variant="contained"
                        component="label" 
                        className={marketImage!==null? "insertPhotoButton":""    }
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => setMarketImage(event.target.files[0])}
                        />
                      </Button>
            </Grid>
            </Grid>
      
          
          
          <br/>
       
            <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Informations de commande 
            </Typography>
            </Grid>
            </Grid>
            {marketImage!==null && 
              <Container className="orderInfoContainer" >
              <Grid container  spacing={0}>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Coût : {totalPayment} DH
                </Typography>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                PrixV : {serviceTotalSellPrice} DH
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                    <Grid item>
                    <SmallInput found={(marketPrice==="" || !isNaN(marketPrice) )? 
                                         null
                                         :
                                         false} value={marketPrice} onChange={(event) =>{setMarketPrice(old => event.target.value)}} 
                          placeholder="Prix Marché" 
                   />
                </Grid>
                </Grid>

                </Grid>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Paiements :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
             
                {paymentsList.map((payment) =>(
                  <Grid style={{"paddingBottom":"5px"}} key={payment.index} container spacing={1}  direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                   <SmallInput found={(payment.amount==="" || !isNaN(payment.amount) )? 
                                         null
                                         :
                                         false} value={payment.amount} onChange={(event) =>{setPaymentAmount({index:payment.index,value:event.target.value})}} 
                          placeholder="Montant" 
                   />
                <span >  </span>
                         <FormControl >
                <InputLabel id="demo-simple-select-label">Type Paiement</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Paiement"
                  className="smallSelectStyle"
                  value={payment.type}
                  onChange={(event)=> {setPaymentType({index:payment.index,value:event.target.value})}}
                >
                 <MenuItem value="virement">virement</MenuItem>
                    <MenuItem value="transfert">transfert</MenuItem>
                    <MenuItem value="espece">espece</MenuItem>
                    <MenuItem value="cheque">cheque</MenuItem>
           
                </Select>
                    </FormControl>
                    <span >  </span>
                    <Button
                        variant="contained"
                        component="label" 
                        className={payment.image!==null? "insertPhotoButton":""    }
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => setPaymentImage({index:payment.index,value:event.target.files[0]})}
                        />
                      </Button>
                   </Grid>
                </Grid>
    ))}
                <br/>
                <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addPayment} fontSize="small" className="addPaymentIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removePayment} fontSize="small" className="removePaymentIconContainer" />
              </Grid>
            </Grid>

                </Grid>

              </Grid>
              
            </Container>
            }
          
             


             <br/>
             <br/>
             {marketOrderClicked ?
              <Button color={"warning"}   className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
              Valider
            </Button> 
            :
            <Button onClick={() =>createMarketOrder({productsFreeMarket:true})}  className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
            Valider
          </Button> 
            }
              
        </CardContent>
        </Card>   
            </Grid>
                <Grid item md={6}>
            <Card className="creationCard" variant="outlined">
<CardContent>
          <p  className="cornerText">Nouveau Marché verouiller</p>

          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
      
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID Client :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Client</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="ID Fournisseur"
                  value={idCustomerSell}
                  className="selectStyle"
                  onChange={(event) => setIdCustomerSell(event.target.value)}
                >
                 {customers.map(supplier => (
                   <MenuItem key={supplier.id} value={supplier.id}>{supplier.id}</MenuItem>
                 ))}
                </Select>
              </FormControl>
             </Grid>
             {decodedJWT.Role=="SuperAdmin" ?
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  value={idLocalSell}
                   onChange={(event)=> {setIdLocalSell((old)=> (event.target.value))}}
                >
             
             {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
             </Grid>
            :
             null
            }
           
          </Grid>
        
          <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
          <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Produits 
            </Typography>
            </Grid>
            </Grid>
      
          {boughtProducts.map((product)=>
            <Grid key={product.index} container direction="row" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID Produit :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={(product.productId==="")? null : product.found}  value={product.productId} onChange={(event) =>  setBoughtProduct({index:product.index,value:event.target.value})} 
              placeholder="ID Produit" />
               <span style={{"width":"2px"}}/>
              <Button variant="contained"
               onClick={() => openProductModal(product.index)} 
               ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
              {product.total!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                  {isNaN(product.total)?
                   <Typography className="errorlittleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Quantité invalide !
                   </Typography>
                  :
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                    Coût: {product.total}DH PrixV: {product.totalsellprice}DH
                  </Typography>
                  }
                  
                  </Grid>
                  </Grid>
           
                  
               }
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Quantity : 
              </Typography>
              <MyInput value={product.quantity} onChange={(event) => setBoughtProductQuantity({index:product.index,value:event.target.value})} 
              placeholder="Quantity" 
             />
            </Grid>
            </Grid>
          )}
          
          <br/>
            <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addProduct} fontSize="large" className="addProductIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removeProduct} fontSize="large" className="removeProductIconContainer" />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Bon 
            </Typography>
            </Grid>
         
          
            </Grid>
            <br/>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
          
            <Grid item>
            <Button
                        variant="contained"
                        component="label" 
                        className={lockedMarketImage!==null? "insertPhotoButton":""    }
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => setLockedMarketImage(event.target.files[0])}
                        />
                      </Button>
            </Grid>
            </Grid>
            <br/>
            <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Informations de commande 
            </Typography>
            </Grid>
            </Grid>
            {(totalPayment!==null && lockedMarketImage!==null) && 
              <Container className="orderInfoContainer" >
              <Grid container  spacing={0}>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Coût : {totalPayment} DH
                </Typography>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                PrixV : {serviceTotalSellPrice} DH
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                    <Grid item>
                    <SmallInput found={(marketPrice==="" || !isNaN(marketPrice) )? 
                                         null
                                         :
                                         false} value={marketPrice} onChange={(event) =>{setMarketPrice(old => event.target.value)}} 
                          placeholder="Prix Marché" 
                   />
                </Grid>
                </Grid>

                </Grid>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                  Paiements :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
             
                {paymentsList.map((payment) =>(
                  <Grid style={{"paddingBottom":"5px"}} key={payment.index} container spacing={1}  direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                   <SmallInput found={(payment.amount==="" || !isNaN(payment.amount) )? 
                                         null
                                         :
                                         false} value={payment.amount} onChange={(event) =>{setPaymentAmount({index:payment.index,value:event.target.value})}} 
                          placeholder="Montant" 
                   />
                <span >  </span>
                         <FormControl >
                <InputLabel id="demo-simple-select-label">Type Paiement</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Paiement"
                  className="smallSelectStyle"
                  value={payment.type}
                  onChange={(event)=> {setPaymentType({index:payment.index,value:event.target.value})}}
                >
                 <MenuItem value="virement">virement</MenuItem>
                    <MenuItem value="transfert">transfert</MenuItem>
                    <MenuItem value="espece">espece</MenuItem>
                    <MenuItem value="cheque">cheque</MenuItem>
           
                </Select>
                    </FormControl>
                    <span >  </span>
                    <Button
                        variant="contained"
                        component="label" 
                        className={payment.image!==null? "insertPhotoButton":""    }
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => setPaymentImage({index:payment.index,value:event.target.files[0]})}
                        />
                      </Button>
                   </Grid>
                </Grid>
    ))}
                <br/>
                <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addPayment} fontSize="small" className="addPaymentIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removePayment} fontSize="small" className="removePaymentIconContainer" />
              </Grid>
            </Grid>

                </Grid>

              </Grid>
              
            </Container>
            }
          
             


             <br/>
             <br/>
             {marketOrderClicked ?
              <Button color={"warning"}   className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
              Valider
            </Button> 
            :
            <Button onClick={() =>createMarketOrder({productsFreeMarket:false})}  className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
            Valider
          </Button> 
            }
              
        </CardContent>
        </Card>   
            </Grid>
            </Grid>
            <br/>

            {/* Table Part */}

            <Card className="searchCard" variant="outlined">
<TableSelection  changeDisplayedTable={changeDisplayedTable} isSupplierTable={isMarketProductTable}/>
       
        {isMarketProductTable ? 
         <CardContent>
    
         <p  className="cornerText">Recherche Global  </p>
           <Grid container spacing={1}>
           <Grid item xs={12} md={4}>
               <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Recherche :
             </Typography>
             <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
             placeholder="Recherche" 
       /> 
       {/* <Button 
        onClick={loadProducts}
         variant="contained" className="filtreSearchButton">
          <SearchIcon style={{"fontSize":"28px"}}/>
          </Button> */}
               </Grid>
           </Grid>
       
         </CardContent>
        :
        <CardContent>
    
        <p  className="cornerText">Filtres  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Id Client :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Id Client" 
      /> <Button onClick={loadOrders} variant="contained" className="filtreSearchButton"><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </Grid>
        
              <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Est Facturé :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Est Facturé</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  value={facturedFilter}
                  onChange={(event)=> setFacturedFilter(old => event.target.value)}
                >
             
                 <MenuItem value="">Tous</MenuItem>
                    <MenuItem value={true}>Facturée</MenuItem>
                    <MenuItem value={false}>Non Facturée</MenuItem>
           
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={12}><br/></Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Statut :
              </Typography>
              <Grid  container spacing={5} >
              <Grid item  >
                {statusFilter==="Complet" ? 
              <Button onClick={() => {changeStatusFilter("Complet")}} className="myCompletedButton">Complet</Button>:
              <Button onClick={() => {changeStatusFilter("Complet")}} className="myCompletedButtonDisabled">Complet</Button>  
              }
            
            </Grid>
            <Grid item  >
            {statusFilter==="En Cours" ? 
              <Button onClick={() => changeStatusFilter("En Cours")} className="myProcessingButton">En cours</Button>:
              <Button onClick={() => changeStatusFilter("En Cours")} className="myProcessingButtonDisabled">En cours</Button>  
              }
           
            </Grid>
            <Grid item   >
            {statusFilter==="Rejetée" ? 
              <Button onClick={() => changeStatusFilter("Rejetée")} className="myFailedButton">Rejetée</Button>:
              <Button onClick={() => changeStatusFilter("Rejetée")} className="myFailedButtonDisabled">Rejetée</Button>  
              }
      
            </Grid>
          </Grid>
              </Grid>
          </Grid>
          
      
        </CardContent>}
      
    </Card>
    <br/>
    {isMarketProductTable ? 
  <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID produit</TableCell>
      <TableCell className="filtertitle" align="left">Nom Produit</TableCell>
      <TableCell className="filtertitle" align="left">Type Produit</TableCell>
      <TableCell className="filtertitle" align="left">Category Produit</TableCell>
      <TableCell className="filtertitle" align="left">Couleur</TableCell>
      <TableCell className="filtertitle" align="left">Gamme</TableCell>
      <TableCell className="filtertitle" align="left">PrixA</TableCell>
      <TableCell className="filtertitle" align="left">PrixV</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {products
.map(product => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{product.id}</TableCell>
<TableCell align="left">{product.name}</TableCell>
<TableCell  align="left">{product.type}</TableCell>
<TableCell align="left">{product.category}</TableCell>
<TableCell align="left">{product.color}</TableCell>
<TableCell align="left">{product.gamme}</TableCell>
<TableCell align="left">{product.buy_price} DH</TableCell>
<TableCell align="left">{product.sell_price} DH</TableCell>
<TableCell align="left"><EditIcon onClick={() => {openProductEditModal(product)}}   className="editIcon"/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            productsCount
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
:
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Id Client</TableCell>
      <TableCell className="filtertitle" align="left">Id Local</TableCell>
      <TableCell className="filtertitle" align="left">Produits Acheté</TableCell>
      <TableCell className="filtertitle" align="left">Paiements</TableCell>
      <TableCell className="filtertitle" align="left">Statut</TableCell>
      <TableCell className="filtertitle" align="left">Montant Payé</TableCell>
      <TableCell className="filtertitle" align="left">Montant Total</TableCell>
      <TableCell className="filtertitle" align="left">Facture</TableCell>
      <TableCell className="filtertitle" align="left">Edit</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {orders
.map(order => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{order.created_At}</TableCell>
<TableCell align="left">{order.customerid}</TableCell>
<TableCell  align="left">{order.localId}</TableCell>
<TableCell align="left">{order.purchasedProducts.map((product)=>(
    <div key={product.productId}>
      {product.quantity}x {product.productId},Total:{product.total}<br/>

    </div>
))}</TableCell>
<TableCell align="left">{order.purchasePayments.map((paiement)=>(
    <div key={paiement.index}>
      <span className="blueText">Montant :</span>{paiement.amount} DH, <span className="blueText">Type :</span>{paiement.type}, {(paiement.image!=null && paiement.image!=="" )&& <a href={getMarketPaymentImageURL(paiement.image)}  target="_blank" rel="noreferrer" >Image</a> }<br/>

    </div>
))}</TableCell>
{/* getEndPoint("images")+"/"+paiement.image */}
<TableCell align="left">
    {order.status==="En Cours" ? 
    <Button disabled={true} className="myProcessingButton">{order.status}</Button>
    :
    order.status==="Complet" ?
    <Button disabled={true} className="myCompletedButton">{order.status}</Button>
    :
    <Button disabled={true} className="myFailedButton" >{order.status}</Button>
    }
    </TableCell>
<TableCell align="left">{order.paidAmount} DH</TableCell>
<TableCell align="left">{order.total} DH</TableCell>
<TableCell align="left">{order.factured? <span>Facturé</span> :<span>Non Facturé</span> }</TableCell>
<TableCell align="left"><EditIcon  className="editIcon" onClick={() => openOrderModal(order)}/></TableCell>


</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={ordersCount}
          rowsPerPage={tableSize}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>
</TableContainer>
}
       </div>);
};
export default Market;