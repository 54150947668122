import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel,CircularProgress } from "@mui/material";

import React, { useState,useEffect } from "react";
import SmallInput from "../../../../globalComponents/SmallInput";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import "./supplierordermodal.css";
import Checkbox from '@mui/material/Checkbox';
import { getCompressedImage } from "../../../../helpers/constants";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,supplierOrder,handleUpdate}) => {


  const [file,setFile] = useState(null);
  const [total,setTotal]=useState(0);
  const [id,setId]=useState("")
  const [isFactured,setIsFactured]=useState(false);
  const [status,setStatus]=useState("");
  const [notPaid,setNotPaid]=useState(false);

  const [updateClicked,setUpdateClicked]=useState(false);

 
  const formatMoney= (val)=> {
    var parts = (val.toFixed(2)).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".")+" DH";
  
}

 useEffect(
   () => {

    setFile(old => null);
    setId(old => supplierOrder.id);
    setTotal(old => supplierOrder.total);
    setIsFactured(old => supplierOrder.factured);
    setStatus(old => supplierOrder.status);
    setNotPaid(old => false);
    setUpdateClicked(old => false);
   },
   [supplierOrder]
 );

 
  
    const updatedData=async() => {
      var compressedImage=null;
      if(file!=null)
      {
         compressedImage=await getCompressedImage(file);
      }
      return {
        file:compressedImage,
        notPaid:notPaid,
      }
    }
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Commande Fournisseur : {id} </DialogTitle>
        <DialogContent id="dialogContent" >
          <Grid style={{padding:10}} container spacing={1}>

            
              {status=="En Cours" ?
                 <Grid item xs={12} md={12}>
                 <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                 montant à payer  : {formatMoney(total)}
               </Typography>
               <Button
                        variant="contained"
                        component="label" 
                         className={file!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setFile(old => event.target.files[0])}
                        />
                      </Button>
          
                 </Grid>
                 :
                 <Grid item xs={12} md={12}>
                 <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                 Non Payée  : 
               </Typography>
               <Checkbox
                      checked={notPaid}
                      
                      onChange={(event)=>{
                        setNotPaid(
                          old => !notPaid
                        );
                      }}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />


                
          
                 </Grid>
   }
           
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          {
            updateClicked?
            <CircularProgress/>
            :
            <Button id="modifierButton" onClick={
              async() => {
               if(!updateClicked)
               {
                 setUpdateClicked(old => true);
                 var myData=await updatedData();
                 await handleUpdate({
                   idOrder:id,
                   data:myData,
                 });
                 setUpdateClicked(old => false);
   
               }
   
              
              }
               }>Modifier</Button>

          }
       
        </DialogActions>
      </Dialog>
    )
};
export default UpdateModal;
