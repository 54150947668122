import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container
   } from "@mui/material";

//Css
import "./local.css";

//Axios Calls
import axios from "axios";
import { logout } from "../../redux/auth/authactions";

//Date Methods Imports + other ..
import { generateArrayOfMonths,
    generateArrayOfYears, generateDays, 
    getEndPoint, getPurchasePaymentImageURL, 
    saveLogoutInLocalStorage } from "../../helpers/constants";


//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";

//Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate } from "react-router-dom";

//Modals
import UpdateModal from "./components/UpdatedModal";

const View= () => {
    //New Local 
    const [idCreate,setIdCreate]=useState("");
    const [addressCreate,setAddressCreate]=useState("");
    const [sizeCreate,setSizeCreate]=useState("");
    const [floorsCreate,setFloorsCreate]=useState("");
    const [rentCreate,setRentCreate]=useState("");
    const [contractCreate,setContractCreate]=useState("");
    const [contract_InfoCreate,setContract_InfoCreate]=useState("");
    const [startDate,setStartDate] = useState({
        day:0,
        month:0,
        year:0,
      });
    //Locals Table
    const [locals,setLocals]=useState([]);
    const [modalIsOpen,setmodalIsOpen]=useState(false);
    const [page, setPage] = useState(0);
    const [selectedLocal,setSelectedLocal]=useState({});
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };
    const openUpdateModal=(supplier) =>{
      setSelectedLocal(supplier);
      setmodalIsOpen(true);
    }
    const closeUpdateModal=()=>{
      setmodalIsOpen(olde => false);
      
    }
    const handleUpdate= async ({idlocal,data})=>{
      const endpoint=getEndPoint("/Local");
      const response = await axios.put(endpoint+"?id="+idlocal,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
        console.log("Update Error Response Status :");
        console.log(error.response);
   
      });
      console.log("Update Data Response :");
      console.log(response.data);
      await loadLocals();
       

      closeUpdateModal();
      
        
    }

   
    //Methods

    //Creation
    const createLocal= async ()=>{
        const data={
            id:idCreate,
            address:addressCreate,
            size:sizeCreate,
            floors:floorsCreate,
            rent:rentCreate,
            contract:contractCreate,
            contract_info:contract_InfoCreate,
            start_date:new Date(mydateToString()),
        }
      const endpoint=getEndPoint("/Local");
      const response = await axios.post(endpoint,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      if(response!=null)
      {
        emptyInputs();
        await loadLocals();
      }


    }
    const emptyInputs=()=>{
      setIdCreate("");
      setAddressCreate("");
      setSizeCreate("");
      setFloorsCreate("");
      setRentCreate("");
      setContractCreate("");
      setContract_InfoCreate("");
      setStartDate({
        day:0,
        month:0,
        year:0,
      });
    }
  


    //Date Methods
    const setYear=(value) =>
    {
        setStartDate((old) => {
            return {
              ...old,
              year:value,
            }
      });

    };
    const setMonth=(value) =>
    {
        setStartDate((old) => {
            return {
              ...old,
              month:value,
            }
      });

    };
    const setDay=(value) =>
    {
        setStartDate((old) => {
            return {
              ...old,
              day:value,
            }
      });

    };
    const mydateToString=()=>{
      return startDate.year+"-"+startDate.month+"-"+startDate.day;

    }
    
    //Loads 
    const loadLocals= async() => {
      const endpoint=getEndPoint("/Local");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      setLocals(old => data.data);
    }
    useEffect(()=>{
      loadLocals();
    },[]);

    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
    } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
    };


    return ( <div>
 <UpdateModal  open={modalIsOpen} local={selectedLocal} handleClose={closeUpdateModal} handleUpdate={handleUpdate}  />
<Card className="creationCard" variant="outlined">
<CardContent>
          <p  className="cornerText">Création Local </p>
         
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID : 
            </Typography>
            <MyInput value={idCreate} onChange={(event) => setIdCreate(event.target.value)} 
            placeholder="ID" 
        />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Adresse :
            </Typography>
            <MyInput value={addressCreate} onChange={(event) => setAddressCreate(event.target.value)} 
            placeholder="Adresse" 
          />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Superficie :
            </Typography>
            <MyInput value={sizeCreate} onChange={(event) =>{setSizeCreate(event.target.value)}} 
            placeholder="Superficie " 
         />
              </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Étage :
            </Typography>
            <MyInput value={floorsCreate} onChange={(event) =>{setFloorsCreate(event.target.value)}} 
            placeholder="Étage" 
           />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Loyer :
            </Typography>
            <MyInput value={rentCreate} onChange={(event) =>{setRentCreate(event.target.value)}} 
            placeholder="Loyer" 
         />
              </Grid>
              <Grid item >
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Date de Début :
            </Typography>
            <Grid style={{width:"16.5rem"}} container spacing={0.5}>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Année</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Année"
                  className="birthdayselectStyle"
                  onChange={(event)=> setYear(event.target.value)}
                >
                  {generateArrayOfYears().map(year => (
            
                    <MenuItem key={year} value={year}>{year}</MenuItem>

                  ))}
                 
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Mois</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Mois"
                  className="birthdayselectStyle"
                
                  onChange={(event)=> setMonth(event.target.value)}
                >
                  {generateArrayOfMonths().map((month) => (
        
                    <MenuItem key={month.number} value={month.number}>{month.name}</MenuItem>

                  ))}
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Jour</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Jour"
                  className="birthdayselectStyle"
                  onChange={(event)=> setDay(event.target.value)}
                >
                  {generateDays().map((daynNumber) => (
        
        <MenuItem key={daynNumber} value={daynNumber}>{daynNumber}</MenuItem>

      ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
       
              </Grid>
             </Grid>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Bail :
            </Typography>
            <MyInput value={contractCreate} onChange={(event) =>{setContractCreate(event.target.value)}} 
            placeholder="Bail" 
          />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Infos Bail:
            </Typography>
            <MyInput value={contract_InfoCreate} onChange={(event) =>{setContract_InfoCreate(event.target.value)}} 
            placeholder="Infos Bail" 
     />
              </Grid>
              <Grid item>
                  <div className="hidden"/>
              </Grid>
    
              </Grid>
           <br/>
           <br/>
              <Button onClick={createLocal} className={"normalButton"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button>

    
          
          
        </CardContent>
        </Card>
        <br/>
        <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID</TableCell>
      <TableCell className="filtertitle" align="left">Adresse</TableCell>
      <TableCell className="filtertitle" align="left">Superficie</TableCell>
      <TableCell className="filtertitle" align="left">Étage</TableCell>
      <TableCell className="filtertitle" align="left">Loyer</TableCell>
      <TableCell className="filtertitle" align="left">Bail</TableCell>
      <TableCell className="filtertitle" align="left">Infos Bail </TableCell>
      <TableCell className="filtertitle" align="left">Date de Début</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {locals
.slice(page * 10, page * 10 + 10)
.map(local => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{local.id}</TableCell>
<TableCell align="left">{local.address}</TableCell>
<TableCell  align="left">{local.size}</TableCell>
<TableCell align="left">{local.floors}</TableCell>
<TableCell align="left">{local.rent}</TableCell>
<TableCell align="left">{local.contract}</TableCell>
<TableCell align="left">{local.contract_info}</TableCell>
<TableCell align="left">{local.start_date}</TableCell>
<TableCell align="left"><EditIcon onClick={() => {openUpdateModal(local)}}  className="editIcon"/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            locals.length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
    </div>);
 
 };
 export default View;