import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel } from "@mui/material";

import React, { useState,useEffect } from "react";
import SmallInput from "../../../../globalComponents/SmallInput";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import "./supplierordermodal.css";

//My Costum inputs
import MyInput from "../../../../globalComponents/MyInput";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const EditStockModal=({open,handleClose,stock,handleUpdate}) => {

  const [stockLocalVariable,setStockLocalVariable]=useState("");
  const [prixAchat,setPrixAchat] = useState(0.0);
  const [prixVente,setPrixVente] = useState(0.0);

 

 useEffect(
   () => {
    setStockLocalVariable(old => stock);
    setPrixAchat(old => stock.buy_price);
    setPrixVente(old => stock.sell_price);
   },
   [stock]
 );

 

    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Stock  </DialogTitle>
        <DialogContent id="dialogContent" >
          <Grid style={{padding:10}} container spacing={1}>

          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Prix Achat:
            </Typography>
            <MyInput value={prixAchat} onChange={(event) =>{setPrixAchat(event.target.value)}} 
            placeholder=" Prix Achat" 
     />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Prix Vente:
            </Typography>
            <MyInput value={prixVente} onChange={(event) =>{setPrixVente(event.target.value)}} 
            placeholder=" Prix Vente" 
     />
              </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {
            handleUpdate({
              stockId:stockLocalVariable.id,
              prixAchat:prixAchat,
              prixVente:prixVente
            });
           }
            }>Modifier</Button>
        </DialogActions>
      </Dialog>
    )
};
export default EditStockModal;
